// API
// ----------------------------------------------------------------------

// LAYOUT
// ----------------------------------------------------------------------

/** Don't change the unity ! vh must stay vh, number must stay number */
export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const IS_DEV = process.env.NODE_ENV === 'development';

export const BUCKET_SIGNED_URL_TTL_MS =
    parseInt(process.env.REACT_APP_BUCKET_SIGNED_URL_TTL_MIN ?? '1') *
    60 *
    1000;

console.log(
    'process.env.REACT_APP_AUTOMATON_DATA_POLLING_INTERVAL_SEC',
    process.env.REACT_APP_AUTOMATON_DATA_POLLING_INTERVAL_SEC
);

export const AUTOMATON_DATA_POLLING_INTERVAL_MS =
    parseInt(
        process.env.REACT_APP_AUTOMATON_DATA_POLLING_INTERVAL_SEC ?? '20'
    ) * 1000;

export const API_ENDPOINT = process.env.REACT_APP_SERVER;
console.log('API endpoint:', API_ENDPOINT);
export const GQL_ENDPOINT = `${process.env.REACT_APP_SERVER}/graphql`;
console.log('GQL endpoint:', GQL_ENDPOINT);
