import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import { Grid, TextField } from '@mui/material';
import {
    HospitalCenterCreateSchema,
    RegionsQuery,
} from '__generated__/graphql';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/react-hoc';
import { WithTranslation, withTranslation } from 'react-i18next';

import CreateSomethingWrapper from '../../../components/createSomethingWrapper';
import { GraphQLError } from 'graphql';
import { HospitalCenterRequests } from '../../../apollo';
import { HospitalCentersRouter } from '../../../router/dashboard/hospitalCenters/hospitalCenters.router';
import React from 'react';
import { RegionRequests } from 'apollo/RegionRequests';
import RegionSelector from 'components/regions/regionSelector';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = () => createStyles({});

type Regions = RegionsQuery['regions'];
type Region = Regions[0];

interface IState {
    hospital: HospitalCenterCreateSchema;
    regions?: Region[];
    selectedRegionId?: number;
    fieldErrors?: { [key: string]: string };
    error?: IErrorInformation;
    information?: IInformative;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class CreateHospitalCenterPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hospital: {
                name: '',
            },
        };
    }

    componentDidMount = () => {
        this.__loadRegions();
    };

    render = () => {
        return (
            <CreateSomethingWrapper
                title={this.props.t('hospital.label.create')}
                onPressCancel={this.__onPressCancel}
                onPressCreate={this.__onPressCreate}
            >
                {this.__renderForm()}
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
            </CreateSomethingWrapper>
        );
    };

    __renderForm = () => {
        return (
            <form>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={this.state.fieldErrors?.name !== undefined}
                            helperText={this.state.fieldErrors?.name}
                            id="name"
                            label={this.props.t('hospital.label.name')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            value={this.state.hospital.name}
                            onChange={(e) =>
                                this.__onChangeName(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={1} sm={6} />

                    <Grid item xs={12}>
                        <TextField
                            error={
                                this.state.fieldErrors?.address !== undefined
                            }
                            helperText={this.state.fieldErrors?.address}
                            id="address"
                            multiline
                            rows={3}
                            label={this.props.t('hospital.label.address')}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.hospital.address}
                            onChange={(e) =>
                                this.__onChangeAddress(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RegionSelector
                            availableRegions={this.state.regions ?? []}
                            onRegionChange={this.__onRegionChange}
                            onRegionRemove={this.__onRegionRemove}
                        />
                    </Grid>
                    <Grid item xs={1} sm={6} />
                </Grid>
            </form>
        );
    };

    __onRegionChange = async (regionId: number) => {
        this.setState((state) => ({ ...state, selectedRegionId: regionId }));
    };

    __onRegionRemove = async () => {
        this.setState((state) => ({ ...state, selectedRegionId: undefined }));
    };

    __onChangeName = (name: string) => {
        if (this.state.hospital) {
            this.setState({
                hospital: {
                    ...this.state.hospital,
                    name,
                },
            });
        }
    };

    __onChangeAddress = (address: string) => {
        if (this.state.hospital) {
            this.setState({
                hospital: {
                    ...this.state.hospital,
                    address,
                },
            });
        }
    };

    __onPressCancel = () => {
        // this.props.history.goBack()
        this.props.history.push(HospitalCentersRouter.getRoute());
    };
    __onPressCreate = () => {
        const hospital = this.state.hospital;
        const errors: { [key: string]: string } = {};

        const createData = {
            ...hospital,
            regionId: this.state.selectedRegionId,
        };

        if (!createData.name || createData.name.length <= 0)
            errors.name = this.props.t('hospital.msg.checkName');
        if (!createData.address || createData.address.length <= 0)
            errors.address = this.props.t('hospital.msg.checkAddress');
        if (Object.keys(errors).length > 0)
            return this.setState({ fieldErrors: errors });

        this.setState({
            fieldErrors: undefined,
            information: {
                title: this.props.t('generic.msg.creating'),
                showProgress: true,
                message: `${this.props.t('generic.msg.updating')}...`,
            },
        });

        HospitalCenterRequests.createHospitalCenter(createData)
            .then(() => {
                this.setState({
                    information: {
                        title: `${this.props.t('generic.msg.success')} !`,
                        message: `${this.props.t('hospital.msg.created')}.`,
                        onClose: () =>
                            this.props.history.push(
                                HospitalCentersRouter.getRoute()
                            ),
                    },
                });
                setTimeout(
                    () =>
                        this.props.history.push(
                            HospitalCentersRouter.getRoute()
                        ),
                    1500
                );
            })
            .catch((error: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: error.message,
                    },
                });
            });
    };

    __loadRegions = async () => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                showProgress: true,
            },
        });
        try {
            const { data: regions } =
                await RegionRequests.regions('cache-first');
            if (regions.regions) {
                this.setState((prevState) => ({
                    ...prevState,
                    regions: regions.regions,
                }));
            }
        } catch (err: unknown) {
            if (err instanceof GraphQLError) {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            } else {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: `Unknown Error: ${err}`,
                    },
                });
            }
        } finally {
            this.setState({ information: undefined });
        }
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(CreateHospitalCenterPage)))
);
