import { TableCell, TableRow, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormattedDate } from 'react-intl';
import FormatToolbox from '../../../toolbox/FormatToolbox';
import { MeQuery, PatientRecordsQuery, Roles } from '__generated__/graphql';
import { Unpacked } from 'types/helpers';

type User = MeQuery['me'];
type PatientRecord = Unpacked<PatientRecordsQuery['patientRecords']>;

const styles = () =>
    createStyles({
        table: {},
    });

interface IState {
    anchorMenu?: unknown;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
    record: PatientRecord;

    user: User;

    onClickUpdatePatientRecord?: (record: PatientRecord) => void;
    onClickRemoveRecord?: (record: PatientRecord) => void;
    onClickRow?: (record: PatientRecord) => void;
}

class PatientRecordRow extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render = () => {
        const record = this.props.record;

        return (
            <TableRow
                hover
                onClick={() =>
                    this.props.onClickRow && this.props.onClickRow(record)
                }
            >
                <TableCell align="left">
                    <Typography>
                        {FormatToolbox.patientRecordEnumToString(record.status)}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>{record.patientNumber}</Typography>
                </TableCell>
                {this.props.user?.role === Roles.Clinician && (
                    <TableCell align="left">
                        <Typography>
                            {record.firstname} {record.lastname}
                        </Typography>
                    </TableCell>
                )}

                {this.props.user?.role !== Roles.Clinician && (
                    <TableCell align="left">
                        <Typography>
                            {record.clinician?.firstname}{' '}
                            {record.clinician?.lastname}{' '}
                            {record.clinician && record.clinician.hospitalCenter
                                ? `(${record.clinician.hospitalCenter.name})`
                                : ''}
                        </Typography>
                    </TableCell>
                )}
                <TableCell align="left">
                    <Typography>
                        {record.pathologie && this.props.t(record.pathologie)}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {record.target && this.props.t(record.target)}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {record.side && this.props.t(record.side)}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {record.typeOfSurgery &&
                            this.props.t(record.typeOfSurgery)}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {record.creationDate && (
                            <FormattedDate
                                day="2-digit"
                                month="short"
                                year="numeric"
                                value={record.creationDate}
                            />
                        )}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography>
                        {record.treatmentDate && (
                            <FormattedDate
                                day="2-digit"
                                month="short"
                                year="numeric"
                                value={record.treatmentDate}
                            />
                        )}
                    </Typography>
                </TableCell>
                {[Roles.Operator, Roles.Admin].includes(
                    this.props.user?.role as Roles
                ) && (
                    <TableCell align="left">
                        <Typography>
                            {record.clinicianDowloadedDate && (
                                <FormattedDate
                                    day="2-digit"
                                    month="short"
                                    year="numeric"
                                    value={record.clinicianDowloadedDate}
                                />
                            )}
                        </Typography>
                    </TableCell>
                )}
            </TableRow>
        );
    };
}

export default withTranslation()(withStyles(styles)(PatientRecordRow));
