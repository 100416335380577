/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: { input: string; output: string };
    /** The `Upload` scalar type represents a file upload. */
    Upload: { input: any; output: any };
};

export type ActionLogFiltersInput = {
    _ids?: InputMaybe<Array<Scalars['Float']['input']>>;
    actions?: InputMaybe<Array<Scalars['String']['input']>>;
    idUsers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ActionLogSchema = {
    __typename?: 'ActionLogSchema';
    _id: Scalars['Float']['output'];
    action?: Maybe<ActionLogType>;
    creationDate: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    idUser?: Maybe<Scalars['String']['output']>;
    user?: Maybe<UserSchema>;
};

export enum ActionLogType {
    AutoTargetingCompleted = 'AUTO_TARGETING_COMPLETED',
    AutoTargetingFailed = 'AUTO_TARGETING_FAILED',
    AutoTargetingInitiated = 'AUTO_TARGETING_INITIATED',
    AutoTargetingInitiatedAck = 'AUTO_TARGETING_INITIATED_ACK',
    CreateCommercial = 'CREATE_COMMERCIAL',
    CreateHospital = 'CREATE_HOSPITAL',
    CreateRecord = 'CREATE_RECORD',
    CreateRegion = 'CREATE_REGION',
    CreateUser = 'CREATE_USER',
    DeleteCommercial = 'DELETE_COMMERCIAL',
    DeleteHospital = 'DELETE_HOSPITAL',
    DeleteRecord = 'DELETE_RECORD',
    DeleteRegion = 'DELETE_REGION',
    DeleteUser = 'DELETE_USER',
    DisableUser = 'DISABLE_USER',
    DownloadOriginalIrm = 'DOWNLOAD_ORIGINAL_IRM',
    DownloadPostopCheck = 'DOWNLOAD_POSTOP_CHECK',
    DownloadPostopImage = 'DOWNLOAD_POSTOP_IMAGE',
    DownloadTargetedIrm = 'DOWNLOAD_TARGETED_IRM',
    DownloadTargetedReport = 'DOWNLOAD_TARGETED_REPORT',
    EnableUser = 'ENABLE_USER',
    LinkCommercialToRegion = 'LINK_COMMERCIAL_TO_REGION',
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    RejectRecord = 'REJECT_RECORD',
    UpdateCommercial = 'UPDATE_COMMERCIAL',
    UpdateHospital = 'UPDATE_HOSPITAL',
    UpdateRecord = 'UPDATE_RECORD',
    UpdateRegion = 'UPDATE_REGION',
    UpdateUser = 'UPDATE_USER',
    UploadOriginalIrm = 'UPLOAD_ORIGINAL_IRM',
    UploadPostopCheck = 'UPLOAD_POSTOP_CHECK',
    UploadPostopImage = 'UPLOAD_POSTOP_IMAGE',
    UploadTargetedIrm = 'UPLOAD_TARGETED_IRM',
}

export type CommercialCreateSchema = {
    email: Scalars['String']['input'];
    firstname: Scalars['String']['input'];
    lastname: Scalars['String']['input'];
    regionId?: InputMaybe<Scalars['Float']['input']>;
};

export type CommercialRemoveFromRegionSchema = {
    commercialEmail: Scalars['String']['input'];
    regionId: Scalars['Float']['input'];
};

export type CommercialSchema = {
    __typename?: 'CommercialSchema';
    email: Scalars['String']['output'];
    firstname: Scalars['String']['output'];
    lastname: Scalars['String']['output'];
};

export type CommercialSchemaWithAssociationDate = {
    __typename?: 'CommercialSchemaWithAssociationDate';
    associationDate: Scalars['DateTime']['output'];
    email: Scalars['String']['output'];
    firstname: Scalars['String']['output'];
    lastname: Scalars['String']['output'];
};

export type CommercialUpdateSchema = {
    email: Scalars['String']['input'];
    firstname?: InputMaybe<Scalars['String']['input']>;
    lastname?: InputMaybe<Scalars['String']['input']>;
};

export type FetchingActionLogsOptionInput = {
    order?: InputMaybe<FetchingActionLogsOrderInputClass>;
    paging?: InputMaybe<FetchingPageInput>;
};

export type FetchingActionLogsOrderInputClass = {
    key: Scalars['String']['input'];
    order: Scalars['String']['input'];
};

export type FetchingPageInput = {
    count: Scalars['Float']['input'];
    start: Scalars['Float']['input'];
};

export type HospitalCenterCreateSchema = {
    address?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type HospitalCenterSchema = {
    __typename?: 'HospitalCenterSchema';
    _id: Scalars['Float']['output'];
    address?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    region?: Maybe<RegionSchema>;
    regionId?: Maybe<Scalars['Float']['output']>;
};

export type HospitalCenterUpdateSchema = {
    _id: Scalars['Float']['input'];
    address?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    regionId?: InputMaybe<Scalars['Float']['input']>;
};

export type MailSettingsSchema = {
    __typename?: 'MailSettingsSchema';
    mail?: Maybe<Scalars['String']['output']>;
    password?: Maybe<Scalars['String']['output']>;
    port?: Maybe<Scalars['String']['output']>;
    service?: Maybe<Scalars['String']['output']>;
    smtp?: Maybe<Scalars['String']['output']>;
};

export type MailSettingsUpdateSchema = {
    mail?: InputMaybe<Scalars['String']['input']>;
    password?: InputMaybe<Scalars['String']['input']>;
    port?: InputMaybe<Scalars['String']['input']>;
    service?: InputMaybe<Scalars['String']['input']>;
    smtp?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    approveAutomatedTargeting: PatientRecordTargetingStatus;
    contactUs: Scalars['Boolean']['output'];
    createCommercial: Scalars['Boolean']['output'];
    createHospitalCenter?: Maybe<HospitalCenterSchema>;
    createPatientRecord: PatientRecordSchema;
    createRegion?: Maybe<RegionSchema>;
    createUser?: Maybe<UserSchema>;
    deleteUser: Scalars['Boolean']['output'];
    forceAutomatedTargeting: PatientRecordTargetingStatus;
    forgottenPassword: Scalars['Boolean']['output'];
    login: UserSchema;
    /** Logout user */
    logout: Scalars['Boolean']['output'];
    rejectAutomatedTargeting: PatientRecordTargetingStatus;
    rejectPatientRecord: PatientRecordSchema;
    removeCommercialFromRegion: Scalars['Boolean']['output'];
    removeHospitalCenter: HospitalCenterSchema;
    removeHospitalCenterRegion?: Maybe<HospitalCenterSchema>;
    removePatientRecord: PatientRecordSchema;
    removeRegion: Scalars['Boolean']['output'];
    resetPassword: Scalars['Boolean']['output'];
    sendEndOfPostOpProcessingMail: Scalars['Boolean']['output'];
    sendEndOfProcessingMail: Scalars['Boolean']['output'];
    setMailSettings: MailSettingsSchema;
    setOriginalIrm: PatientRecordSchema;
    setPostOpIrm: PatientRecordSchema;
    setTargetedIrm: PatientRecordSchema;
    setTargetedPostOpIrm: PatientRecordSchema;
    updateCommercial?: Maybe<CommercialSchema>;
    updateHospitalCenter?: Maybe<HospitalCenterSchema>;
    updatePatientRecord?: Maybe<PatientRecordSchema>;
    updateRegion?: Maybe<RegionSchema>;
    updateUser?: Maybe<UserSchema>;
};

export type MutationApproveAutomatedTargetingArgs = {
    idRecord: Scalars['String']['input'];
};

export type MutationContactUsArgs = {
    content: Scalars['String']['input'];
    subject: Scalars['String']['input'];
};

export type MutationCreateCommercialArgs = {
    commercialData: CommercialCreateSchema;
};

export type MutationCreateHospitalCenterArgs = {
    hospitalCenter: HospitalCenterCreateSchema;
};

export type MutationCreatePatientRecordArgs = {
    originalIrm?: InputMaybe<Scalars['Upload']['input']>;
    patientRecord: PatientRecordCreateSchema;
};

export type MutationCreateRegionArgs = {
    regionData: RegionCreateSchema;
};

export type MutationCreateUserArgs = {
    user: UserCreateSchema;
};

export type MutationDeleteUserArgs = {
    idUser: Scalars['String']['input'];
};

export type MutationForceAutomatedTargetingArgs = {
    idRecord: Scalars['String']['input'];
};

export type MutationForgottenPasswordArgs = {
    email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
    authentication: UserLoginSchema;
};

export type MutationRejectAutomatedTargetingArgs = {
    idRecord: Scalars['String']['input'];
};

export type MutationRejectPatientRecordArgs = {
    idPatientRecord: Scalars['String']['input'];
    reason: Scalars['String']['input'];
};

export type MutationRemoveCommercialFromRegionArgs = {
    associationData: CommercialRemoveFromRegionSchema;
};

export type MutationRemoveHospitalCenterArgs = {
    idHospitalCenter: Scalars['Float']['input'];
};

export type MutationRemoveHospitalCenterRegionArgs = {
    idHospitalCenter: Scalars['Float']['input'];
};

export type MutationRemovePatientRecordArgs = {
    idPatientRecord: Scalars['String']['input'];
};

export type MutationRemoveRegionArgs = {
    regionId: Scalars['Float']['input'];
};

export type MutationResetPasswordArgs = {
    password: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type MutationSendEndOfPostOpProcessingMailArgs = {
    idRecord: Scalars['String']['input'];
};

export type MutationSendEndOfProcessingMailArgs = {
    idRecord: Scalars['String']['input'];
};

export type MutationSetMailSettingsArgs = {
    settings: MailSettingsUpdateSchema;
};

export type MutationSetOriginalIrmArgs = {
    idRecord: Scalars['String']['input'];
    irm?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationSetPostOpIrmArgs = {
    idRecord: Scalars['String']['input'];
    irm?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationSetTargetedIrmArgs = {
    idRecord: Scalars['String']['input'];
    irm?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationSetTargetedPostOpIrmArgs = {
    idRecord: Scalars['String']['input'];
    irm?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationUpdateCommercialArgs = {
    commercialData: CommercialUpdateSchema;
};

export type MutationUpdateHospitalCenterArgs = {
    hospitalCenter: HospitalCenterUpdateSchema;
};

export type MutationUpdatePatientRecordArgs = {
    originalIrm?: InputMaybe<Scalars['Upload']['input']>;
    patientRecord: PatientRecordUpdateSchema;
    postOpIrm?: InputMaybe<Scalars['Upload']['input']>;
};

export type MutationUpdateRegionArgs = {
    regionData: RegionUpdateSchema;
};

export type MutationUpdateUserArgs = {
    user: UserUpdateSchema;
};

export type OperatorFeedback = {
    __typename?: 'OperatorFeedback';
    body: Scalars['String']['output'];
    color?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
};

export type PaginatedActionLogsResponse = {
    __typename?: 'PaginatedActionLogsResponse';
    hasMore: Scalars['Boolean']['output'];
    items: Array<ActionLogSchema>;
    totalCount: Scalars['Float']['output'];
};

export type PatientRecordCreateSchema = {
    firstname?: InputMaybe<Scalars['String']['input']>;
    lastname?: InputMaybe<Scalars['String']['input']>;
    pathologie?: InputMaybe<PatientRecordPathology>;
    side?: InputMaybe<PatientRecordSide>;
    target?: InputMaybe<PatientRecordTarget>;
    typeOfSurgery?: InputMaybe<PatientRecordTypeOfSurgery>;
};

export enum PatientRecordPathology {
    EssentialTremor = 'ESSENTIAL_TREMOR',
    Parkinson = 'PARKINSON',
}

export type PatientRecordSchema = {
    __typename?: 'PatientRecordSchema';
    _id: Scalars['String']['output'];
    automatonReportChecksum?: Maybe<Scalars['String']['output']>;
    automatonReportFileName?: Maybe<Scalars['String']['output']>;
    automatonReportUrl?: Maybe<Scalars['String']['output']>;
    clinician?: Maybe<UserSchema>;
    clinicianDowloadedDate?: Maybe<Scalars['DateTime']['output']>;
    creationDate: Scalars['DateTime']['output'];
    firstname?: Maybe<Scalars['String']['output']>;
    giveUpReason?: Maybe<Scalars['String']['output']>;
    hasOriginalIrm: Scalars['Boolean']['output'];
    hasPostOpIrm: Scalars['Boolean']['output'];
    hasTargetedIrm: Scalars['Boolean']['output'];
    hasTargetedPostOpIrm: Scalars['Boolean']['output'];
    idClinician: Scalars['String']['output'];
    lastname?: Maybe<Scalars['String']['output']>;
    originalMRIChecksum?: Maybe<Scalars['String']['output']>;
    originalMRIFileName?: Maybe<Scalars['String']['output']>;
    originalMRITargetedChecksum?: Maybe<Scalars['String']['output']>;
    originalMRITargetedFileName?: Maybe<Scalars['String']['output']>;
    originalMriTargetedUrl?: Maybe<Scalars['String']['output']>;
    originalMriUrl?: Maybe<Scalars['String']['output']>;
    pathologie?: Maybe<PatientRecordPathology>;
    patientNumber: Scalars['Float']['output'];
    postOpMRIChecksum?: Maybe<Scalars['String']['output']>;
    postOpMRIFileName?: Maybe<Scalars['String']['output']>;
    postOpMRITargetedChecksum?: Maybe<Scalars['String']['output']>;
    postOpMRITargetedFileName?: Maybe<Scalars['String']['output']>;
    postOpMriTargetedUrl?: Maybe<Scalars['String']['output']>;
    postOpMriUrl?: Maybe<Scalars['String']['output']>;
    side?: Maybe<PatientRecordSide>;
    status: PatientRecordStatus;
    target?: Maybe<PatientRecordTarget>;
    targetingLastUpdate?: Maybe<Scalars['DateTime']['output']>;
    targetingOperatorFeedback: Array<OperatorFeedback>;
    targetingReportFinalized: Scalars['Boolean']['output'];
    targetingStatus: PatientRecordTargetingStatus;
    targetingWasApproved: Scalars['Boolean']['output'];
    targetingWasRejected: Scalars['Boolean']['output'];
    treatmentDate?: Maybe<Scalars['DateTime']['output']>;
    typeOfSurgery?: Maybe<PatientRecordTypeOfSurgery>;
};

export enum PatientRecordSide {
    Bilateral = 'BILATERAL',
    Left = 'LEFT',
    Right = 'RIGHT',
}

export enum PatientRecordStatus {
    Done = 'DONE',
    Downloaded = 'DOWNLOADED',
    GiveUp = 'GIVE_UP',
    InProgress = 'IN_PROGRESS',
    PostopToProcess = 'POSTOP_TO_PROCESS',
    PreopMriMarked = 'PREOP_MRI_MARKED',
    ToDo = 'TO_DO',
}

export enum PatientRecordTarget {
    Stn = 'STN',
    Vim = 'VIM',
}

export enum PatientRecordTargetingStatus {
    ApprovedByOperator = 'APPROVED_BY_OPERATOR',
    Enqueued = 'ENQUEUED',
    Expired = 'EXPIRED',
    ImageCreationError = 'IMAGE_CREATION_ERROR',
    ImageGenerated = 'IMAGE_GENERATED',
    MaxNbRetriesReached = 'MAX_NB_RETRIES_REACHED',
    OperatedManually = 'OPERATED_MANUALLY',
    OriginalMriRemoved = 'ORIGINAL_MRI_REMOVED',
    OriginalMriReuploaded = 'ORIGINAL_MRI_REUPLOADED',
    RejectedByOperator = 'REJECTED_BY_OPERATOR',
    ReportError = 'REPORT_ERROR',
    ReportGenerated = 'REPORT_GENERATED',
    Submitted = 'SUBMITTED',
    Unknown = 'UNKNOWN',
    UnknownError = 'UNKNOWN_ERROR',
    ValidFormat = 'VALID_FORMAT',
    WrongFormat = 'WRONG_FORMAT',
}

export enum PatientRecordTypeOfSurgery {
    Dbs = 'DBS',
    Hifu = 'HIFU',
    Radiosurgery = 'RADIOSURGERY',
}

export type PatientRecordUpdateSchema = {
    _id: Scalars['String']['input'];
    clinicianDowloadedDate?: InputMaybe<Scalars['DateTime']['input']>;
    firstname?: InputMaybe<Scalars['String']['input']>;
    lastname?: InputMaybe<Scalars['String']['input']>;
    pathologie?: InputMaybe<PatientRecordPathology>;
    side?: InputMaybe<PatientRecordSide>;
    status?: InputMaybe<Scalars['String']['input']>;
    target?: InputMaybe<PatientRecordTarget>;
    typeOfSurgery?: InputMaybe<PatientRecordTypeOfSurgery>;
};

export type Query = {
    __typename?: 'Query';
    actionLogs: PaginatedActionLogsResponse;
    commercials: Array<CommercialSchema>;
    findCommercialsInRegion: Array<CommercialSchemaWithAssociationDate>;
    findRegion: RegionSchema;
    hospitalCenter: HospitalCenterSchema;
    hospitalCenters: Array<HospitalCenterSchema>;
    mailSettings: MailSettingsSchema;
    me?: Maybe<UserSchema>;
    passwordIsResetable?: Maybe<Scalars['Boolean']['output']>;
    patientRecord: PatientRecordSchema;
    patientRecords: Array<PatientRecordSchema>;
    regions: Array<RegionSchema>;
    user: UserSchema;
    users: Array<UserSchema>;
    version: Scalars['String']['output'];
};

export type QueryActionLogsArgs = {
    conditions?: InputMaybe<ActionLogFiltersInput>;
    options?: InputMaybe<FetchingActionLogsOptionInput>;
};

export type QueryFindCommercialsInRegionArgs = {
    regionId: Scalars['Float']['input'];
};

export type QueryFindRegionArgs = {
    regionId: Scalars['Float']['input'];
};

export type QueryHospitalCenterArgs = {
    idHospitalCenter: Scalars['Float']['input'];
};

export type QueryPasswordIsResetableArgs = {
    token: Scalars['String']['input'];
};

export type QueryPatientRecordArgs = {
    idPatientRecord: Scalars['String']['input'];
};

export type QueryUserArgs = {
    idUser: Scalars['String']['input'];
};

export type RegionCreateSchema = {
    name: Scalars['String']['input'];
};

export type RegionSchema = {
    __typename?: 'RegionSchema';
    _id: Scalars['Float']['output'];
    commercials: Array<CommercialSchema>;
    creationDate: Scalars['DateTime']['output'];
    hospitals: Array<HospitalCenterSchema>;
    lastUpdate: Scalars['DateTime']['output'];
    name: Scalars['String']['output'];
    nbCommercials: Scalars['Int']['output'];
    nbHospitals: Scalars['Int']['output'];
};

export type RegionUpdateSchema = {
    _id: Scalars['Float']['input'];
    name: Scalars['String']['input'];
};

export enum Roles {
    Admin = 'ADMIN',
    Clinician = 'CLINICIAN',
    Operator = 'OPERATOR',
}

export type UserCreateSchema = {
    email: Scalars['String']['input'];
    firstname?: InputMaybe<Scalars['String']['input']>;
    idHospitalCenter?: InputMaybe<Scalars['Float']['input']>;
    lastname?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    role: Scalars['String']['input'];
    status?: InputMaybe<UserStatus>;
};

export type UserLoginSchema = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type UserSchema = {
    __typename?: 'UserSchema';
    _id: Scalars['String']['output'];
    email: Scalars['String']['output'];
    firstname?: Maybe<Scalars['String']['output']>;
    hospitalCenter?: Maybe<HospitalCenterSchema>;
    lastname?: Maybe<Scalars['String']['output']>;
    patientRecords: Array<PatientRecordSchema>;
    role: Roles;
    status: UserStatus;
};

export enum UserStatus {
    Disable = 'DISABLE',
    Enable = 'ENABLE',
}

export type UserUpdateSchema = {
    _id: Scalars['String']['input'];
    firstname?: InputMaybe<Scalars['String']['input']>;
    idHospitalCenter?: InputMaybe<Scalars['Float']['input']>;
    lastname?: InputMaybe<Scalars['String']['input']>;
    /** Only administrator can use this field */
    role?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<UserStatus>;
};

export type ActionLogsQueryVariables = Exact<{
    options?: InputMaybe<FetchingActionLogsOptionInput>;
    conditions?: InputMaybe<ActionLogFiltersInput>;
}>;

export type ActionLogsQuery = {
    __typename?: 'Query';
    actionLogs: {
        __typename?: 'PaginatedActionLogsResponse';
        totalCount: number;
        hasMore: boolean;
        items: Array<{
            __typename?: 'ActionLogSchema';
            _id: number;
            action?: ActionLogType | null;
            description?: string | null;
            creationDate: string;
            user?: {
                __typename?: 'UserSchema';
                _id: string;
                firstname?: string | null;
                lastname?: string | null;
                email: string;
            } | null;
        }>;
    };
};

export type CreateCommercialMutationVariables = Exact<{
    commercialData: CommercialCreateSchema;
}>;

export type CreateCommercialMutation = {
    __typename?: 'Mutation';
    createCommercial: boolean;
};

export type RemoveCommercialFromRegionMutationVariables = Exact<{
    associationData: CommercialRemoveFromRegionSchema;
}>;

export type RemoveCommercialFromRegionMutation = {
    __typename?: 'Mutation';
    removeCommercialFromRegion: boolean;
};

export type CommercialsQueryVariables = Exact<{ [key: string]: never }>;

export type CommercialsQuery = {
    __typename?: 'Query';
    commercials: Array<{
        __typename?: 'CommercialSchema';
        email: string;
        firstname: string;
        lastname: string;
    }>;
};

export type FindCommercialsInRegionQueryVariables = Exact<{
    regionId: Scalars['Float']['input'];
}>;

export type FindCommercialsInRegionQuery = {
    __typename?: 'Query';
    findCommercialsInRegion: Array<{
        __typename?: 'CommercialSchemaWithAssociationDate';
        email: string;
        firstname: string;
        lastname: string;
        associationDate: string;
    }>;
};

export type CreateHospitalCenterMutationVariables = Exact<{
    createHospital: HospitalCenterCreateSchema;
}>;

export type CreateHospitalCenterMutation = {
    __typename?: 'Mutation';
    createHospitalCenter?: {
        __typename?: 'HospitalCenterSchema';
        _id: number;
    } | null;
};

export type HospitalCentersQueryVariables = Exact<{ [key: string]: never }>;

export type HospitalCentersQuery = {
    __typename?: 'Query';
    hospitalCenters: Array<{
        __typename?: 'HospitalCenterSchema';
        _id: number;
        name: string;
        address?: string | null;
        regionId?: number | null;
    }>;
};

export type HospitalCenterQueryVariables = Exact<{
    idHospitalCenter: Scalars['Float']['input'];
}>;

export type HospitalCenterQuery = {
    __typename?: 'Query';
    hospitalCenter: {
        __typename?: 'HospitalCenterSchema';
        _id: number;
        name: string;
        address?: string | null;
        regionId?: number | null;
    };
};

export type RemoveHospitalCenterMutationVariables = Exact<{
    idHospitalCenter: Scalars['Float']['input'];
}>;

export type RemoveHospitalCenterMutation = {
    __typename?: 'Mutation';
    removeHospitalCenter: { __typename?: 'HospitalCenterSchema'; name: string };
};

export type UpdateHospitalCenterMutationVariables = Exact<{
    updateHospital: HospitalCenterUpdateSchema;
}>;

export type UpdateHospitalCenterMutation = {
    __typename?: 'Mutation';
    updateHospitalCenter?: {
        __typename?: 'HospitalCenterSchema';
        _id: number;
        name: string;
        address?: string | null;
        regionId?: number | null;
    } | null;
};

export type RemoveHospitalCenterRegionMutationVariables = Exact<{
    idHospitalCenter: Scalars['Float']['input'];
}>;

export type RemoveHospitalCenterRegionMutation = {
    __typename?: 'Mutation';
    removeHospitalCenterRegion?: {
        __typename?: 'HospitalCenterSchema';
        _id: number;
        name: string;
        regionId?: number | null;
    } | null;
};

export type CreatePatientRecordMutationVariables = Exact<{
    createHospital: PatientRecordCreateSchema;
    originalIrm?: InputMaybe<Scalars['Upload']['input']>;
}>;

export type CreatePatientRecordMutation = {
    __typename?: 'Mutation';
    createPatientRecord: { __typename?: 'PatientRecordSchema'; _id: string };
};

export type PatientRecordsQueryVariables = Exact<{ [key: string]: never }>;

export type PatientRecordsQuery = {
    __typename?: 'Query';
    patientRecords: Array<{
        __typename?: 'PatientRecordSchema';
        _id: string;
        firstname?: string | null;
        lastname?: string | null;
        patientNumber: number;
        pathologie?: PatientRecordPathology | null;
        target?: PatientRecordTarget | null;
        side?: PatientRecordSide | null;
        typeOfSurgery?: PatientRecordTypeOfSurgery | null;
        status: PatientRecordStatus;
        clinicianDowloadedDate?: string | null;
        creationDate: string;
        treatmentDate?: string | null;
        clinician?: {
            __typename?: 'UserSchema';
            firstname?: string | null;
            lastname?: string | null;
            hospitalCenter?: {
                __typename?: 'HospitalCenterSchema';
                name: string;
            } | null;
        } | null;
    }>;
};

export type PatientRecordQueryVariables = Exact<{
    idPatientRecord: Scalars['String']['input'];
}>;

export type PatientRecordQuery = {
    __typename?: 'Query';
    patientRecord: {
        __typename?: 'PatientRecordSchema';
        _id: string;
        firstname?: string | null;
        lastname?: string | null;
        patientNumber: number;
        pathologie?: PatientRecordPathology | null;
        target?: PatientRecordTarget | null;
        side?: PatientRecordSide | null;
        typeOfSurgery?: PatientRecordTypeOfSurgery | null;
        status: PatientRecordStatus;
        hasOriginalIrm: boolean;
        hasTargetedIrm: boolean;
        hasTargetedPostOpIrm: boolean;
        hasPostOpIrm: boolean;
        clinicianDowloadedDate?: string | null;
        treatmentDate?: string | null;
        creationDate: string;
        giveUpReason?: string | null;
        targetingStatus: PatientRecordTargetingStatus;
        targetingLastUpdate?: string | null;
        clinician?: {
            __typename?: 'UserSchema';
            firstname?: string | null;
            lastname?: string | null;
            hospitalCenter?: {
                __typename?: 'HospitalCenterSchema';
                name: string;
            } | null;
        } | null;
        targetingOperatorFeedback: Array<{
            __typename?: 'OperatorFeedback';
            title: string;
            body: string;
            color?: string | null;
        }>;
    };
};

export type PatientRecordUpdateFieldsQueryVariables = Exact<{
    idPatientRecord: Scalars['String']['input'];
}>;

export type PatientRecordUpdateFieldsQuery = {
    __typename?: 'Query';
    patientRecord: {
        __typename?: 'PatientRecordSchema';
        _id: string;
        firstname?: string | null;
        lastname?: string | null;
        pathologie?: PatientRecordPathology | null;
        target?: PatientRecordTarget | null;
        side?: PatientRecordSide | null;
        typeOfSurgery?: PatientRecordTypeOfSurgery | null;
        status: PatientRecordStatus;
        hasPostOpIrm: boolean;
    };
};

export type RemovePatientRecordMutationVariables = Exact<{
    idPatientRecord: Scalars['String']['input'];
}>;

export type RemovePatientRecordMutation = {
    __typename?: 'Mutation';
    removePatientRecord: {
        __typename?: 'PatientRecordSchema';
        patientNumber: number;
    };
};

export type RejectPatientRecordMutationVariables = Exact<{
    idPatientRecord: Scalars['String']['input'];
    reason: Scalars['String']['input'];
}>;

export type RejectPatientRecordMutation = {
    __typename?: 'Mutation';
    rejectPatientRecord: {
        __typename?: 'PatientRecordSchema';
        patientNumber: number;
    };
};

export type UpdatePatientRecordMutationVariables = Exact<{
    updateHospital: PatientRecordUpdateSchema;
    originalIrm?: InputMaybe<Scalars['Upload']['input']>;
    postOpIrm?: InputMaybe<Scalars['Upload']['input']>;
}>;

export type UpdatePatientRecordMutation = {
    __typename?: 'Mutation';
    updatePatientRecord?: {
        __typename?: 'PatientRecordSchema';
        _id: string;
    } | null;
};

export type SetOriginalIrmMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
    irm: Scalars['Upload']['input'];
}>;

export type SetOriginalIrmMutation = {
    __typename?: 'Mutation';
    setOriginalIrm: { __typename?: 'PatientRecordSchema'; _id: string };
};

export type SetTargetedIrmMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
    irm: Scalars['Upload']['input'];
}>;

export type SetTargetedIrmMutation = {
    __typename?: 'Mutation';
    setTargetedIrm: { __typename?: 'PatientRecordSchema'; _id: string };
};

export type SetPostOpIrmMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
    irm: Scalars['Upload']['input'];
}>;

export type SetPostOpIrmMutation = {
    __typename?: 'Mutation';
    setPostOpIrm: { __typename?: 'PatientRecordSchema'; _id: string };
};

export type SetTargetedPostOpIrmMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
    irm: Scalars['Upload']['input'];
}>;

export type SetTargetedPostOpIrmMutation = {
    __typename?: 'Mutation';
    setTargetedPostOpIrm: { __typename?: 'PatientRecordSchema'; _id: string };
};

export type SendEnOfProcessingMailMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
}>;

export type SendEnOfProcessingMailMutation = {
    __typename?: 'Mutation';
    sendEndOfProcessingMail: boolean;
};

export type SendEnOfPostOpProcessingMailMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
}>;

export type SendEnOfPostOpProcessingMailMutation = {
    __typename?: 'Mutation';
    sendEndOfPostOpProcessingMail: boolean;
};

export type RegionsQueryVariables = Exact<{ [key: string]: never }>;

export type RegionsQuery = {
    __typename?: 'Query';
    regions: Array<{
        __typename?: 'RegionSchema';
        _id: number;
        name: string;
        nbHospitals: number;
        nbCommercials: number;
        creationDate: string;
        lastUpdate: string;
        hospitals: Array<{ __typename?: 'HospitalCenterSchema'; name: string }>;
    }>;
};

export type FindRegionQueryVariables = Exact<{
    regionId: Scalars['Float']['input'];
}>;

export type FindRegionQuery = {
    __typename?: 'Query';
    findRegion: { __typename?: 'RegionSchema'; _id: number; name: string };
};

export type CreateRegionMutationVariables = Exact<{
    regionData: RegionCreateSchema;
}>;

export type CreateRegionMutation = {
    __typename?: 'Mutation';
    createRegion?: {
        __typename?: 'RegionSchema';
        _id: number;
        name: string;
    } | null;
};

export type RemoveRegionMutationVariables = Exact<{
    regionId: Scalars['Float']['input'];
}>;

export type RemoveRegionMutation = {
    __typename?: 'Mutation';
    removeRegion: boolean;
};

export type MailSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type MailSettingsQuery = {
    __typename?: 'Query';
    mailSettings: {
        __typename?: 'MailSettingsSchema';
        mail?: string | null;
        password?: string | null;
        service?: string | null;
        smtp?: string | null;
        port?: string | null;
    };
};

export type SetMailSettingsMutationVariables = Exact<{
    settings: MailSettingsUpdateSchema;
}>;

export type SetMailSettingsMutation = {
    __typename?: 'Mutation';
    setMailSettings: {
        __typename?: 'MailSettingsSchema';
        mail?: string | null;
        password?: string | null;
        service?: string | null;
        smtp?: string | null;
        port?: string | null;
    };
};

export type ContactUsMutationVariables = Exact<{
    content: Scalars['String']['input'];
    subject: Scalars['String']['input'];
}>;

export type ContactUsMutation = { __typename?: 'Mutation'; contactUs: boolean };

export type LoginMutationVariables = Exact<{
    authentication: UserLoginSchema;
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    login: { __typename?: 'UserSchema'; _id: string; email: string };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
    __typename?: 'Query';
    me?: {
        __typename?: 'UserSchema';
        lastname?: string | null;
        email: string;
        firstname?: string | null;
        role: Roles;
    } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
    __typename?: 'Query';
    users: Array<{
        __typename?: 'UserSchema';
        _id: string;
        lastname?: string | null;
        firstname?: string | null;
        email: string;
        role: Roles;
        status: UserStatus;
        hospitalCenter?: {
            __typename?: 'HospitalCenterSchema';
            name: string;
        } | null;
    }>;
};

export type UpdateUserMutationVariables = Exact<{
    updateUser: UserUpdateSchema;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateUser?: {
        __typename?: 'UserSchema';
        _id: string;
        firstname?: string | null;
        lastname?: string | null;
    } | null;
};

export type CreateUserMutationVariables = Exact<{
    createUser: UserCreateSchema;
}>;

export type CreateUserMutation = {
    __typename?: 'Mutation';
    createUser?: {
        __typename?: 'UserSchema';
        _id: string;
        email: string;
    } | null;
};

export type DeleteUserMutationVariables = Exact<{
    idUser: Scalars['String']['input'];
}>;

export type DeleteUserMutation = {
    __typename?: 'Mutation';
    deleteUser: boolean;
};

export type UserQueryVariables = Exact<{
    idUser: Scalars['String']['input'];
}>;

export type UserQuery = {
    __typename?: 'Query';
    user: {
        __typename?: 'UserSchema';
        _id: string;
        lastname?: string | null;
        firstname?: string | null;
        email: string;
        role: Roles;
        hospitalCenter?: {
            __typename?: 'HospitalCenterSchema';
            _id: number;
        } | null;
    };
};

export type ResetPasswordMutationVariables = Exact<{
    token: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
    __typename?: 'Mutation';
    resetPassword: boolean;
};

export type ForgottenPasswordMutationVariables = Exact<{
    email: Scalars['String']['input'];
}>;

export type ForgottenPasswordMutation = {
    __typename?: 'Mutation';
    forgottenPassword: boolean;
};

export type OperatorFeedbackFragmentFragment = {
    __typename?: 'PatientRecordSchema';
    targetingOperatorFeedback: Array<{
        __typename?: 'OperatorFeedback';
        title: string;
        body: string;
        color?: string | null;
    }>;
} & { ' $fragmentName'?: 'OperatorFeedbackFragmentFragment' };

export type TargetedMriFragmentFragment = {
    __typename?: 'PatientRecordSchema';
    patientNumber: number;
    targetingStatus: PatientRecordTargetingStatus;
    originalMriTargetedUrl?: string | null;
    originalMRITargetedFileName?: string | null;
} & { ' $fragmentName'?: 'TargetedMriFragmentFragment' };

export type TargetingCommandsFragmentFragment = {
    __typename?: 'PatientRecordSchema';
    _id: string;
    targetingWasApproved: boolean;
    targetingWasRejected: boolean;
    targetingReportFinalized: boolean;
    hasPostOpIrm: boolean;
    hasTargetedPostOpIrm: boolean;
} & { ' $fragmentName'?: 'TargetingCommandsFragmentFragment' };

export type ApproveAutomatedTargetingMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
}>;

export type ApproveAutomatedTargetingMutation = {
    __typename?: 'Mutation';
    approveAutomatedTargeting: PatientRecordTargetingStatus;
};

export type RejectAutomatedTargetingMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
}>;

export type RejectAutomatedTargetingMutation = {
    __typename?: 'Mutation';
    rejectAutomatedTargeting: PatientRecordTargetingStatus;
};

export type ForceAutomatedTargetingMutationVariables = Exact<{
    idRecord: Scalars['String']['input'];
}>;

export type ForceAutomatedTargetingMutation = {
    __typename?: 'Mutation';
    forceAutomatedTargeting: PatientRecordTargetingStatus;
};

export type TargetingUpdateFragmentFragment = {
    __typename?: 'PatientRecordSchema';
    targetingLastUpdate?: string | null;
} & { ' $fragmentName'?: 'TargetingUpdateFragmentFragment' };

export type TargetingReportFragmentFragment = {
    __typename?: 'PatientRecordSchema';
    _id: string;
    patientNumber: number;
    automatonReportUrl?: string | null;
    automatonReportFileName?: string | null;
} & { ' $fragmentName'?: 'TargetingReportFragmentFragment' };

export type TargetingStatusFragmentFragment = {
    __typename?: 'PatientRecordSchema';
    targetingStatus: PatientRecordTargetingStatus;
} & { ' $fragmentName'?: 'TargetingStatusFragmentFragment' };

export type PatientRecordAutomatonQueryVariables = Exact<{
    idPatientRecord: Scalars['String']['input'];
}>;

export type PatientRecordAutomatonQuery = {
    __typename?: 'Query';
    patientRecord: { __typename?: 'PatientRecordSchema'; _id: string } & {
        ' $fragmentRefs'?: {
            TargetingReportFragmentFragment: TargetingReportFragmentFragment;
            TargetedMriFragmentFragment: TargetedMriFragmentFragment;
            TargetingStatusFragmentFragment: TargetingStatusFragmentFragment;
            TargetingUpdateFragmentFragment: TargetingUpdateFragmentFragment;
            OperatorFeedbackFragmentFragment: OperatorFeedbackFragmentFragment;
            TargetingCommandsFragmentFragment: TargetingCommandsFragmentFragment;
        };
    };
};

export const OperatorFeedbackFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperatorFeedbackFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'targetingOperatorFeedback',
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'color' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OperatorFeedbackFragmentFragment, unknown>;
export const TargetedMriFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetedMriFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientNumber' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingStatus' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalMriTargetedUrl' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'originalMRITargetedFileName',
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TargetedMriFragmentFragment, unknown>;
export const TargetingCommandsFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingCommandsFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingWasApproved' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingWasRejected' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'targetingReportFinalized',
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPostOpIrm' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasTargetedPostOpIrm' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TargetingCommandsFragmentFragment, unknown>;
export const TargetingUpdateFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingUpdateFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingLastUpdate' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TargetingUpdateFragmentFragment, unknown>;
export const TargetingReportFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingReportFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientNumber' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'automatonReportUrl' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'automatonReportFileName',
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TargetingReportFragmentFragment, unknown>;
export const TargetingStatusFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingStatusFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingStatus' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TargetingStatusFragmentFragment, unknown>;
export const ActionLogsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'actionLogs' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'options' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'FetchingActionLogsOptionInput',
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'conditions' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'ActionLogFiltersInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actionLogs' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'options' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'options' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'conditions' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'conditions' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasMore' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: '_id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'action',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: '_id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'firstname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ActionLogsQuery, ActionLogsQueryVariables>;
export const CreateCommercialDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createCommercial' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'commercialData' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CommercialCreateSchema',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createCommercial' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'commercialData' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'commercialData',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateCommercialMutation,
    CreateCommercialMutationVariables
>;
export const RemoveCommercialFromRegionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeCommercialFromRegion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'associationData' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CommercialRemoveFromRegionSchema',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'removeCommercialFromRegion',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'associationData',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'associationData',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveCommercialFromRegionMutation,
    RemoveCommercialFromRegionMutationVariables
>;
export const CommercialsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'commercials' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commercials' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CommercialsQuery, CommercialsQueryVariables>;
export const FindCommercialsInRegionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'findCommercialsInRegion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'regionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'findCommercialsInRegion',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'regionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'regionId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'associationDate',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FindCommercialsInRegionQuery,
    FindCommercialsInRegionQueryVariables
>;
export const CreateHospitalCenterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createHospitalCenter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createHospital' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'HospitalCenterCreateSchema',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createHospitalCenter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hospitalCenter' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'createHospital',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateHospitalCenterMutation,
    CreateHospitalCenterMutationVariables
>;
export const HospitalCentersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'hospitalCenters' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hospitalCenters' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regionId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    HospitalCentersQuery,
    HospitalCentersQueryVariables
>;
export const HospitalCenterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'hospitalCenter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idHospitalCenter' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hospitalCenter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idHospitalCenter',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idHospitalCenter',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regionId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HospitalCenterQuery, HospitalCenterQueryVariables>;
export const RemoveHospitalCenterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeHospitalCenter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idHospitalCenter' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeHospitalCenter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idHospitalCenter',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idHospitalCenter',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveHospitalCenterMutation,
    RemoveHospitalCenterMutationVariables
>;
export const UpdateHospitalCenterDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateHospitalCenter' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'updateHospital' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'HospitalCenterUpdateSchema',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateHospitalCenter' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hospitalCenter' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'updateHospital',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regionId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateHospitalCenterMutation,
    UpdateHospitalCenterMutationVariables
>;
export const RemoveHospitalCenterRegionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeHospitalCenterRegion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idHospitalCenter' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'removeHospitalCenterRegion',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idHospitalCenter',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idHospitalCenter',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regionId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveHospitalCenterRegionMutation,
    RemoveHospitalCenterRegionMutationVariables
>;
export const CreatePatientRecordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createPatientRecord' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createHospital' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'PatientRecordCreateSchema',
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'originalIrm' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Upload' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createPatientRecord' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'patientRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'createHospital',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'originalIrm' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'originalIrm',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreatePatientRecordMutation,
    CreatePatientRecordMutationVariables
>;
export const PatientRecordsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'patientRecords' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientRecords' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'patientNumber',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathologie' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'target' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'side' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'typeOfSurgery',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'clinicianDowloadedDate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'creationDate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'treatmentDate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clinician' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'firstname',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lastname',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hospitalCenter',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PatientRecordsQuery, PatientRecordsQueryVariables>;
export const PatientRecordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'patientRecord' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idPatientRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientRecord' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idPatientRecord',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idPatientRecord',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'patientNumber',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathologie' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'target' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'side' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'typeOfSurgery',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'hasOriginalIrm',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'hasTargetedIrm',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'hasTargetedPostOpIrm',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'hasPostOpIrm',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'clinicianDowloadedDate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'treatmentDate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'creationDate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clinician' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'firstname',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lastname',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hospitalCenter',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'giveUpReason',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetingStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetingLastUpdate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetingOperatorFeedback',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'body',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'color',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PatientRecordQuery, PatientRecordQueryVariables>;
export const PatientRecordUpdateFieldsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'patientRecordUpdateFields' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idPatientRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientRecord' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idPatientRecord',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idPatientRecord',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pathologie' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'target' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'side' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'typeOfSurgery',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'hasPostOpIrm',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PatientRecordUpdateFieldsQuery,
    PatientRecordUpdateFieldsQueryVariables
>;
export const RemovePatientRecordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removePatientRecord' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idPatientRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removePatientRecord' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idPatientRecord',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idPatientRecord',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'patientNumber',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemovePatientRecordMutation,
    RemovePatientRecordMutationVariables
>;
export const RejectPatientRecordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'rejectPatientRecord' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idPatientRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reason' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rejectPatientRecord' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idPatientRecord',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idPatientRecord',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reason' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reason' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'patientNumber',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RejectPatientRecordMutation,
    RejectPatientRecordMutationVariables
>;
export const UpdatePatientRecordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updatePatientRecord' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'updateHospital' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'PatientRecordUpdateSchema',
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'originalIrm' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Upload' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'postOpIrm' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Upload' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatePatientRecord' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'patientRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'updateHospital',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'originalIrm' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'originalIrm',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'postOpIrm' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'postOpIrm' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdatePatientRecordMutation,
    UpdatePatientRecordMutationVariables
>;
export const SetOriginalIrmDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setOriginalIrm' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'irm' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Upload' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setOriginalIrm' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'irm' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'irm' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetOriginalIrmMutation,
    SetOriginalIrmMutationVariables
>;
export const SetTargetedIrmDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setTargetedIrm' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'irm' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Upload' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setTargetedIrm' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'irm' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'irm' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetTargetedIrmMutation,
    SetTargetedIrmMutationVariables
>;
export const SetPostOpIrmDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setPostOpIrm' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'irm' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Upload' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setPostOpIrm' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'irm' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'irm' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetPostOpIrmMutation,
    SetPostOpIrmMutationVariables
>;
export const SetTargetedPostOpIrmDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setTargetedPostOpIrm' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'irm' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Upload' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setTargetedPostOpIrm' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'irm' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'irm' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetTargetedPostOpIrmMutation,
    SetTargetedPostOpIrmMutationVariables
>;
export const SendEnOfProcessingMailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'sendEnOfProcessingMail' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'sendEndOfProcessingMail',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SendEnOfProcessingMailMutation,
    SendEnOfProcessingMailMutationVariables
>;
export const SendEnOfPostOpProcessingMailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'sendEnOfPostOpProcessingMail' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'sendEndOfPostOpProcessingMail',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SendEnOfPostOpProcessingMailMutation,
    SendEnOfPostOpProcessingMailMutationVariables
>;
export const RegionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'regions' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'regions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nbHospitals',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hospitals' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'nbCommercials',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'creationDate',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUpdate' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RegionsQuery, RegionsQueryVariables>;
export const FindRegionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'findRegion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'regionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'findRegion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'regionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'regionId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FindRegionQuery, FindRegionQueryVariables>;
export const CreateRegionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createRegion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'regionData' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RegionCreateSchema' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createRegion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'regionData' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'regionData' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateRegionMutation,
    CreateRegionMutationVariables
>;
export const RemoveRegionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeRegion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'regionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeRegion' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'regionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'regionId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveRegionMutation,
    RemoveRegionMutationVariables
>;
export const MailSettingsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'mailSettings' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mailSettings' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'password' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'service' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'smtp' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'port' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MailSettingsQuery, MailSettingsQueryVariables>;
export const SetMailSettingsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setMailSettings' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'settings' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'MailSettingsUpdateSchema',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setMailSettings' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'settings' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'settings' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'password' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'service' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'smtp' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'port' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetMailSettingsMutation,
    SetMailSettingsMutationVariables
>;
export const ContactUsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'contactUs' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'content' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'subject' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactUs' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'content' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'subject' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subject' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ContactUsMutation, ContactUsMutationVariables>;
export const LoginDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'login' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'authentication' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UserLoginSchema' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'login' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'authentication' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'authentication',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const MeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'me' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const LogoutDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'logout' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'logout' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const UsersDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'hospitalCenter',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const UpdateUserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'updateUser' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UserUpdateSchema' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'updateUser' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateUserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'createUser' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UserCreateSchema' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'createUser' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idUser' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idUser' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idUser' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'user' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idUser' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idUser' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idUser' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'hospitalCenter',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: '_id',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const ResetPasswordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'resetPassword' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'token' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'password' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resetPassword' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'password' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'password' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'token' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'token' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const ForgottenPasswordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'forgottenPassword' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forgottenPassword' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
>;
export const ApproveAutomatedTargetingDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'approveAutomatedTargeting' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'approveAutomatedTargeting',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ApproveAutomatedTargetingMutation,
    ApproveAutomatedTargetingMutationVariables
>;
export const RejectAutomatedTargetingDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'rejectAutomatedTargeting' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'rejectAutomatedTargeting',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RejectAutomatedTargetingMutation,
    RejectAutomatedTargetingMutationVariables
>;
export const ForceAutomatedTargetingDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'forceAutomatedTargeting' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'forceAutomatedTargeting',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'idRecord' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'idRecord' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ForceAutomatedTargetingMutation,
    ForceAutomatedTargetingMutationVariables
>;
export const PatientRecordAutomatonDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'patientRecordAutomaton' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'idPatientRecord' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientRecord' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'idPatientRecord',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'idPatientRecord',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_id' },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'TargetingReportFragment',
                                    },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'TargetedMriFragment',
                                    },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'TargetingStatusFragment',
                                    },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'TargetingUpdateFragment',
                                    },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'OperatorFeedbackFragment',
                                    },
                                },
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'TargetingCommandsFragment',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingReportFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientNumber' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'automatonReportUrl' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'automatonReportFileName',
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetedMriFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'patientNumber' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingStatus' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalMriTargetedUrl' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'originalMRITargetedFileName',
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingStatusFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingStatus' },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingUpdateFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingLastUpdate' },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'OperatorFeedbackFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'targetingOperatorFeedback',
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'body' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'color' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TargetingCommandsFragment' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PatientRecordSchema' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingWasApproved' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetingWasRejected' },
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'targetingReportFinalized',
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPostOpIrm' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasTargetedPostOpIrm' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PatientRecordAutomatonQuery,
    PatientRecordAutomatonQueryVariables
>;
