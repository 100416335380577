import RegionSelector, {
    RegionSelectorProps,
} from 'components/regions/regionSelector';

import { HospitalCenterRequests } from 'apollo';
import { useCallback } from 'react';

type ChildrenProps = Omit<
    RegionSelectorProps,
    'onRegionChange' | 'onRegionRemove'
>;

export type HospitalRegionSelectorProps = ChildrenProps & {
    hospitalId: number;
};

export default function HospitalRegionSelector(
    props: HospitalRegionSelectorProps
) {
    const onRegionChange = useCallback(async (regionId: number) => {
        await HospitalCenterRequests.updateHospitalCenter({
            _id: props.hospitalId,
            regionId,
        });
    }, []);

    const onRegionRemove = useCallback(async () => {
        await HospitalCenterRequests.removeHospitalRegion(props.hospitalId);
    }, []);

    return (
        <RegionSelector
            availableRegions={props.availableRegions}
            initialRegionId={props.initialRegionId}
            onRegionChange={onRegionChange}
            onRegionRemove={onRegionRemove}
        />
    );
}
