import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { GQL_ENDPOINT } from 'config';

const httpLink = createUploadLink({
    uri: GQL_ENDPOINT,
    credentials: 'include',
    headers: {
        'Apollo-Require-Preflight': 'true',
    },
});

export const Client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({
        addTypename: false,
    }),
    defaultOptions: {
        query: { fetchPolicy: 'no-cache' },
        watchQuery: { fetchPolicy: 'no-cache' },
    },
});

/** Reset the cache to make sure sensitive data are not persisted after logout */
export async function clearDataCache() {
    // Either clear or reset store can be used here:
    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
    return await Client.clearStore();
}

export * from './HospitalCenterRequests';
export * from './PatientRecordRequests';
export * from './UserRequests';
export * from './ServerRequests';
