import {
    IconButton,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';

import { HospitalCentersQuery } from '__generated__/graphql';
import HospitalRegionSelector from './hospitalRegionSelector';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { Region as SelectorRegion } from 'components/regions/regionSelector';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = () =>
    createStyles({
        table: {},
    });

type HospitalCenters = HospitalCentersQuery['hospitalCenters'];
type HospitalCenter = HospitalCenters[0];

interface IState {
    anchorMenu?: HTMLButtonElement;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
    hospital: HospitalCenter;
    availableRegions?: SelectorRegion[];
    onClickUpdateHospital?: (hospital: HospitalCenter) => void;
    onClickRemoveHospital?: (hospital: HospitalCenter) => void;
}

class HospitalRow extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidUpdate = (prevProps: IProps) => {
        if (this.props !== prevProps) this.setState({ anchorMenu: undefined });
    };
    render = () => {
        // const classes = this.props.classes;
        const hospital = this.props.hospital;

        return (
            <TableRow>
                <TableCell align="left">
                    <Typography>{hospital.name}</Typography>
                </TableCell>
                <TableCell>
                    <pre>
                        <Typography variant="body2">
                            {hospital.address}
                        </Typography>
                    </pre>
                </TableCell>
                <TableCell>
                    <HospitalRegionSelector
                        hospitalId={hospital._id}
                        initialRegionId={hospital.regionId}
                        availableRegions={this.props.availableRegions ?? []}
                    />
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        onClick={(e) =>
                            this.setState({ anchorMenu: e.currentTarget })
                        }
                        size="large"
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorMenu}
                        keepMounted
                        open={this.state.anchorMenu !== undefined}
                        onClose={() => this.setState({ anchorMenu: undefined })}
                        onClick={() => this.setState({ anchorMenu: undefined })}
                    >
                        {this.props.onClickUpdateHospital && (
                            <MenuItem
                                onClick={() =>
                                    this.props.onClickUpdateHospital &&
                                    this.props.onClickUpdateHospital(
                                        this.props.hospital
                                    )
                                }
                            >
                                {this.props.t('generic.button.update')}
                            </MenuItem>
                        )}
                        {this.props.onClickRemoveHospital && (
                            <MenuItem
                                onClick={() =>
                                    this.props.onClickRemoveHospital &&
                                    this.props.onClickRemoveHospital(
                                        this.props.hospital
                                    )
                                }
                            >
                                {this.props.t('generic.button.delete')}
                            </MenuItem>
                        )}
                    </Menu>
                </TableCell>
            </TableRow>
        );
    };
}

export default withTranslation()(withStyles(styles)(HospitalRow));
