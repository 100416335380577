// IMPORTANT: If you define new error codes in `createCommercial` you will have to manually update the
// corresponding translation keys in order to display a user-friendly message.
import * as yup from 'yup';

import { Box, Button, InputLabel, OutlinedInput } from '@mui/material';
import {
    ErrorMessage,
    Form,
    Formik,
    FormikHelpers,
    useFormikContext,
} from 'formik';
import { useCallback, useMemo } from 'react';

import ErrorText from 'components/errorText';
import RegionIcon from './regionIcon';
import { TFunction } from 'i18next';
import { isApolloError } from '@apollo/client';
import { useCreateRegion } from 'apollo/RegionRequests';
import useSnackbar from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

const MIN_REGION_NAME_LEN = 3;
const MAX_REGION_NAME_LEN = 255;

const INITIAL_VALUES = {
    name: '',
};

type FormType = typeof INITIAL_VALUES;

function CreateValidation(t: TFunction) {
    return yup.object({
        name: yup
            .string()
            .min(
                MIN_REGION_NAME_LEN,
                t('regions.form.name.minLength', {
                    count: MIN_REGION_NAME_LEN,
                })
            )
            .max(
                MAX_REGION_NAME_LEN,
                t('regions.form.name.maxLength', {
                    count: MAX_REGION_NAME_LEN,
                })
            )
            .required(t('regions.form.name.required')),
    });
}

type CreateRegionFormProps = {
    onRegionAdded?: (region: { _id: number; name: string }) => void;
};

/** This form that allows to create commercials. It does two things:
 *
 * 1. It sends a request to the server to create a new commercial using the form data, associating
 * it to the input region (using the `props.regionId`).
 * 2. It also displays errors:
 *  - client form validation errors (basic field validation)
 *  - server validation errors (more complex errors, like duplicate commercial email for example) */
export default function CreateRegionForm(props: CreateRegionFormProps) {
    const { t } = useTranslation();
    const { openInformativeSnackbar } = useSnackbar();
    const validationSchema = useMemo(() => CreateValidation(t), [t]);
    const [createRegion] = useCreateRegion();

    const onSubmit = useCallback(
        async (values: FormType, formik: FormikHelpers<FormType>) => {
            try {
                const { data: createdData } = await createRegion({
                    variables: { regionData: values },
                });
                const newRegion = createdData?.createRegion;
                if (newRegion) {
                    await openInformativeSnackbar({
                        message: t('regions.success', {
                            regionName: values.name,
                        }),
                        icon: <RegionIcon />,
                    });
                    props.onRegionAdded?.(newRegion);
                    formik.resetForm();
                } else {
                    formik.setErrors({
                        name: t('regions.form.serverError.unknown'),
                    });
                }
            } catch (error) {
                if (error instanceof Error && isApolloError(error)) {
                    formik.setErrors({
                        name: t(`regions.form.serverError.${error.message}`),
                    });
                }
            }
        },
        []
    );

    return (
        <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            <FormContent />
        </Formik>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const formik = useFormikContext<FormType>();
    return (
        <Form>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <Box>
                    <InputLabel htmlFor="name">
                        {t('regions.form.name.label')}
                    </InputLabel>
                    <OutlinedInput
                        name="name"
                        id="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{ ml: 4, px: 4, py: 1 }}
                    >
                        {t('regions.form.submit')}
                    </Button>
                    <Box sx={{ minHeight: 50, pt: 1 }}>
                        <ErrorMessage name="name" component={ErrorText} />
                    </Box>
                </Box>
            </Box>
        </Form>
    );
}
