export const PalettePrimaryDark = '#4D2536';
export const PalettePrimary = '#6e364e';
export const PalettePrimaryLight = '#eedde4';
export const PalettePrimaryLightLight = '#f7eef2';
export const PaletteGreyLight = '#A3A3A3';
export const PaletteErrorBackground = '#900C3F';
export const PaletteErrorText = '#B58C86';
export const PaletteErrorMain = '#900C3F';

export const PaletteGreen = '#196573';
export const PaletteGreenDark = '#154D58';
export const PaletteGreenLight = '#6BC0C8';
export const PaletteGreenLightLight = '#EEF6F8';

export const TestColor = '#E3A300';
