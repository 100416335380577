import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Dialog,
    Grid,
    Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

const styles = () =>
    createStyles({
        underline: {
            textDecorationLine: 'underline',
        },
    });

export interface IErrorInformation {
    title?: string /** Title of the modal */;
    message?: string /** Message of the modal */;

    extendedMessage?: string /** More detailed message */;
    onClose?: () => void /** Function called when user whant close the dialog */;
}

interface IProps extends WithStyles<typeof styles> {
    error?: IErrorInformation;

    onClose: () => void /** Function called when user whant close the dialog */;
}

type IState = Record<string, unknown>;

/**
 * Component used to render informative data
 */
class ErrorDialog extends React.Component<IProps, IState> {
    render = () => {
        const classes = this.props.classes;
        return (
            <Dialog
                open={this.props.error !== undefined}
                onClose={this.__onClose}
            >
                <Box margin={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="h5">
                                {this.props.error?.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center">
                                {this.props.error?.message}
                            </Typography>
                        </Grid>
                        {this.props.error?.extendedMessage && (
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary>
                                        <Typography
                                            className={classes.underline}
                                            variant="subtitle2"
                                        >
                                            Plus
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body2">
                                            {this.props.error.extendedMessage}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Dialog>
        );
    };

    __onClose = () => {
        if (this.props.error?.onClose) this.props.error.onClose();

        if (this.props.onClose) this.props.onClose();
    };
}

export default withStyles(styles)(ErrorDialog);
