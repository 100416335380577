import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { useMemo, useState } from 'react';

import ModificationDate from 'components/modificationDate';
import { UnlinkButton } from 'components/buttons';
import { useTranslation } from 'react-i18next';

type Commercial = {
    email: string;
    firstname: string;
    lastname: string;
    associationDate?: string;
};

type CommercialTableProps<C> = {
    commercials: C[];
    onRowClicked?: (commercial: C) => void;
    onUnlinkClicked?: (commercial: C) => void;
    showPagination?: boolean;
};

const SHOW_ALL_ROWS = -1;
const COMMERCIALS_PER_PAGE = [5, 10, 20, 50];

/** List of commercials that offers a row clicked and an unlink button clicked callbacks.
 *
 * @param props.commercials a list of commercials to be displayed, each commercial should have
 * a least an `email`, `firstname` and `lastname` properties.
 * @param props.onRowClicked A callback that is called when a commercial ROW is clicked.
 * The commercial is passed as argument.
 * @param props.onUnlinkClicked A callback that is called when the unlink button is clicked.
 * The commercial is passed as argument.
 *
 * Notice that if the input commercials contain additional properties, they will be passed down to
 * the callback functions.
 */
export default function CommercialsTable<C extends Commercial>(
    props: CommercialTableProps<C>
) {
    const { t } = useTranslation();
    const hasClickAction = props.onRowClicked !== undefined;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        props.showPagination ? COMMERCIALS_PER_PAGE[0] : SHOW_ALL_ROWS
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const visibleCommercials = useMemo(() => {
        if (rowsPerPage === SHOW_ALL_ROWS) {
            return props.commercials;
        } else {
            return props.commercials.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            );
        }
    }, [page, rowsPerPage, props.commercials]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 30 }} />
                            <TableCell>
                                {t('commercials.table.email')}
                            </TableCell>
                            <TableCell>
                                {t('commercials.table.firstname')}
                            </TableCell>
                            <TableCell>
                                {t('commercials.table.lastname')}
                            </TableCell>
                            <TableCell>
                                {t('commercials.table.associationDate')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleCommercials.map((commercial) => (
                            <TableRow
                                key={commercial.email}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    cursor: hasClickAction
                                        ? 'pointer'
                                        : 'default',
                                }}
                                hover={hasClickAction}
                            >
                                <TableCell>
                                    <UnlinkButton
                                        onClick={() =>
                                            props.onUnlinkClicked?.(commercial)
                                        }
                                    />
                                </TableCell>
                                <TableCell
                                    onClick={() =>
                                        props.onRowClicked?.(commercial)
                                    }
                                    component="th"
                                    scope="row"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {commercial.email}
                                </TableCell>
                                <TableCell
                                    onClick={() =>
                                        props.onRowClicked?.(commercial)
                                    }
                                >
                                    {commercial.firstname}
                                </TableCell>
                                <TableCell
                                    onClick={() =>
                                        props.onRowClicked?.(commercial)
                                    }
                                >
                                    {commercial.lastname}
                                </TableCell>
                                <TableCell
                                    onClick={() =>
                                        props.onRowClicked?.(commercial)
                                    }
                                >
                                    <ModificationDate
                                        value={commercial.associationDate}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.showPagination && (
                <TablePagination
                    showFirstButton
                    showLastButton
                    component="div"
                    rowsPerPageOptions={[
                        ...COMMERCIALS_PER_PAGE,
                        { value: -1, label: 'All' },
                    ]}
                    count={props.commercials.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    // sx={{ justifyContent: 'space-between' }}
                />
            )}
        </>
    );
}
