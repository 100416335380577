/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    '\n    query actionLogs(\n        $options: FetchingActionLogsOptionInput\n        $conditions: ActionLogFiltersInput\n    ) {\n        actionLogs(options: $options, conditions: $conditions) {\n            totalCount\n            hasMore\n            items {\n                _id\n                action\n                description\n                user {\n                    _id\n                    firstname\n                    lastname\n                    email\n                }\n                creationDate\n            }\n        }\n    }\n':
        types.ActionLogsDocument,
    '\n    mutation createCommercial(\n        $commercialData: CommercialCreateSchema!\n    ) {\n        createCommercial(commercialData: $commercialData)\n    }\n':
        types.CreateCommercialDocument,
    '\n    mutation removeCommercialFromRegion(\n        $associationData: CommercialRemoveFromRegionSchema!\n    ) {\n        removeCommercialFromRegion(associationData: $associationData)\n    }\n':
        types.RemoveCommercialFromRegionDocument,
    '\n    query commercials {\n        commercials {\n            email\n            firstname\n            lastname\n        }\n    }\n':
        types.CommercialsDocument,
    '\n    query findCommercialsInRegion($regionId: Float!) {\n        findCommercialsInRegion(regionId: $regionId) {\n            email\n            firstname\n            lastname\n            associationDate\n        }\n    }\n':
        types.FindCommercialsInRegionDocument,
    '\n    mutation createHospitalCenter(\n        $createHospital: HospitalCenterCreateSchema!\n    ) {\n        createHospitalCenter(hospitalCenter: $createHospital) {\n            _id\n        }\n    }\n':
        types.CreateHospitalCenterDocument,
    '\n    query hospitalCenters {\n        hospitalCenters {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n':
        types.HospitalCentersDocument,
    '\n    query hospitalCenter($idHospitalCenter: Float!) {\n        hospitalCenter(idHospitalCenter: $idHospitalCenter) {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n':
        types.HospitalCenterDocument,
    '\n    mutation removeHospitalCenter($idHospitalCenter: Float!) {\n        removeHospitalCenter(idHospitalCenter: $idHospitalCenter) {\n            name\n        }\n    }\n':
        types.RemoveHospitalCenterDocument,
    '\n    mutation updateHospitalCenter(\n        $updateHospital: HospitalCenterUpdateSchema!\n    ) {\n        updateHospitalCenter(hospitalCenter: $updateHospital) {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n':
        types.UpdateHospitalCenterDocument,
    '\n    mutation removeHospitalCenterRegion(\n        $idHospitalCenter: Float!\n    ) {\n        removeHospitalCenterRegion(idHospitalCenter: $idHospitalCenter) {\n            _id\n            name\n            regionId\n        }\n    }\n':
        types.RemoveHospitalCenterRegionDocument,
    '\n    mutation createPatientRecord(\n        $createHospital: PatientRecordCreateSchema!\n        $originalIrm: Upload\n    ) {\n        createPatientRecord(\n            patientRecord: $createHospital\n            originalIrm: $originalIrm\n        ) {\n            _id\n        }\n    }\n':
        types.CreatePatientRecordDocument,
    '\n    query patientRecords {\n        patientRecords {\n            _id\n            firstname\n            lastname\n            patientNumber\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            clinicianDowloadedDate\n            creationDate\n            treatmentDate\n            clinician {\n                firstname\n                lastname\n                hospitalCenter {\n                    name\n                }\n            }\n        }\n    }\n':
        types.PatientRecordsDocument,
    '\n    query patientRecord($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            firstname\n            lastname\n            patientNumber\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            hasOriginalIrm\n            hasTargetedIrm\n            hasTargetedPostOpIrm\n            hasPostOpIrm\n            clinicianDowloadedDate\n            treatmentDate\n            creationDate\n            clinician {\n                firstname\n                lastname\n                hospitalCenter {\n                    name\n                }\n            }\n            giveUpReason\n            targetingStatus\n            targetingLastUpdate\n            targetingOperatorFeedback {\n                title\n                body\n                color\n            }\n        }\n    }\n':
        types.PatientRecordDocument,
    '\n    query patientRecordUpdateFields($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            firstname\n            lastname\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            hasPostOpIrm\n        }\n    }\n':
        types.PatientRecordUpdateFieldsDocument,
    '\n    mutation removePatientRecord($idPatientRecord: String!) {\n        removePatientRecord(idPatientRecord: $idPatientRecord) {\n            patientNumber\n        }\n    }\n':
        types.RemovePatientRecordDocument,
    '\n    mutation rejectPatientRecord($idPatientRecord: String!, $reason: String!) {\n        rejectPatientRecord(\n            idPatientRecord: $idPatientRecord\n            reason: $reason\n        ) {\n            patientNumber\n        }\n    }\n':
        types.RejectPatientRecordDocument,
    '\n    mutation updatePatientRecord(\n        $updateHospital: PatientRecordUpdateSchema!\n        $originalIrm: Upload\n        $postOpIrm: Upload\n    ) {\n        updatePatientRecord(\n            patientRecord: $updateHospital\n            originalIrm: $originalIrm\n            postOpIrm: $postOpIrm\n        ) {\n            _id\n        }\n    }\n':
        types.UpdatePatientRecordDocument,
    '\n    mutation setOriginalIrm($idRecord: String!, $irm: Upload!) {\n        setOriginalIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n':
        types.SetOriginalIrmDocument,
    '\n    mutation setTargetedIrm($idRecord: String!, $irm: Upload!) {\n        setTargetedIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n':
        types.SetTargetedIrmDocument,
    '\n    mutation setPostOpIrm($idRecord: String!, $irm: Upload!) {\n        setPostOpIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n':
        types.SetPostOpIrmDocument,
    '\n    mutation setTargetedPostOpIrm($idRecord: String!, $irm: Upload!) {\n        setTargetedPostOpIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n':
        types.SetTargetedPostOpIrmDocument,
    '\n    mutation sendEnOfProcessingMail($idRecord: String!) {\n        sendEndOfProcessingMail(idRecord: $idRecord)\n    }\n':
        types.SendEnOfProcessingMailDocument,
    '\n    mutation sendEnOfPostOpProcessingMail($idRecord: String!) {\n        sendEndOfPostOpProcessingMail(idRecord: $idRecord)\n    }\n':
        types.SendEnOfPostOpProcessingMailDocument,
    '\n    query regions {\n        regions {\n            _id\n            name\n            nbHospitals\n            hospitals {\n                name\n            }\n            nbCommercials\n            creationDate\n            lastUpdate\n        }\n    }\n':
        types.RegionsDocument,
    '\n    query findRegion($regionId: Float!) {\n        findRegion(regionId: $regionId) {\n            _id\n            name\n        }\n    }\n':
        types.FindRegionDocument,
    '\n    mutation createRegion(\n        $regionData: RegionCreateSchema!\n    ) {\n        createRegion(regionData: $regionData) {\n            _id\n            name\n        }\n    }\n':
        types.CreateRegionDocument,
    '\n    mutation removeRegion($regionId: Float!) {\n        removeRegion(regionId: $regionId)\n    }\n':
        types.RemoveRegionDocument,
    '\n    query mailSettings {\n        mailSettings {\n            mail\n            password\n            service\n            smtp\n            port\n        }\n    }\n':
        types.MailSettingsDocument,
    '\n    mutation setMailSettings($settings: MailSettingsUpdateSchema!) {\n        setMailSettings(settings: $settings) {\n            mail\n            password\n            service\n            smtp\n            port\n        }\n    }\n':
        types.SetMailSettingsDocument,
    '\n                    mutation contactUs($content: String!, $subject: String!) {\n                        contactUs(content: $content, subject: $subject)\n                    }\n                ':
        types.ContactUsDocument,
    '\n    mutation login($authentication: UserLoginSchema!) {\n        login(authentication: $authentication) {\n            _id\n            email\n        }\n    }\n':
        types.LoginDocument,
    '\n    query me {\n        me {\n            lastname\n            email\n            firstname\n            role\n        }\n    }\n':
        types.MeDocument,
    '\n    mutation logout {\n        logout\n    }\n': types.LogoutDocument,
    '\n    query users {\n        users {\n            _id\n            lastname\n            firstname\n            email\n            role\n            hospitalCenter {\n                name\n            }\n            status\n        }\n    }\n':
        types.UsersDocument,
    '\n    mutation updateUser($updateUser: UserUpdateSchema!) {\n        updateUser(user: $updateUser) {\n            _id\n            firstname\n            lastname\n        }\n    }\n':
        types.UpdateUserDocument,
    '\n    mutation createUser($createUser: UserCreateSchema!) {\n        createUser(user: $createUser) {\n            _id\n            email\n        }\n    }\n':
        types.CreateUserDocument,
    '\n    mutation deleteUser($idUser: String!) {\n        deleteUser(idUser: $idUser)\n    }\n':
        types.DeleteUserDocument,
    '\n    query user($idUser: String!) {\n        user(idUser: $idUser) {\n            _id\n            lastname\n            firstname\n            email\n            role\n            hospitalCenter {\n                _id\n            }\n        }\n    }\n':
        types.UserDocument,
    '\n    mutation resetPassword($token: String!, $password: String!) {\n        resetPassword(password: $password, token: $token)\n    }\n':
        types.ResetPasswordDocument,
    '\n    mutation forgottenPassword($email: String!) {\n        forgottenPassword(email: $email)\n    }\n':
        types.ForgottenPasswordDocument,
    '\n    fragment OperatorFeedbackFragment on PatientRecordSchema {\n        targetingOperatorFeedback {\n            title\n            body\n            color\n        }\n    }\n':
        types.OperatorFeedbackFragmentFragmentDoc,
    '\n    fragment TargetedMriFragment on PatientRecordSchema {\n        patientNumber\n        targetingStatus\n        originalMriTargetedUrl\n        originalMRITargetedFileName\n    }\n':
        types.TargetedMriFragmentFragmentDoc,
    '\n    fragment TargetingCommandsFragment on PatientRecordSchema {\n        _id\n        targetingWasApproved\n        targetingWasRejected\n        targetingReportFinalized\n        hasPostOpIrm\n        hasTargetedPostOpIrm\n    }\n':
        types.TargetingCommandsFragmentFragmentDoc,
    '\n    mutation approveAutomatedTargeting($idRecord: String!) {\n        approveAutomatedTargeting(idRecord: $idRecord)\n    }\n':
        types.ApproveAutomatedTargetingDocument,
    '\n    mutation rejectAutomatedTargeting($idRecord: String!) {\n        rejectAutomatedTargeting(idRecord: $idRecord)\n    }\n':
        types.RejectAutomatedTargetingDocument,
    '\n    mutation forceAutomatedTargeting($idRecord: String!) {\n        forceAutomatedTargeting(idRecord: $idRecord)\n    }\n':
        types.ForceAutomatedTargetingDocument,
    '\n    fragment TargetingUpdateFragment on PatientRecordSchema {\n        targetingLastUpdate\n    }\n':
        types.TargetingUpdateFragmentFragmentDoc,
    '\n    fragment TargetingReportFragment on PatientRecordSchema {\n        _id\n        patientNumber\n        automatonReportUrl\n        automatonReportFileName\n    }\n':
        types.TargetingReportFragmentFragmentDoc,
    '\n    fragment TargetingStatusFragment on PatientRecordSchema {\n        targetingStatus\n    }\n':
        types.TargetingStatusFragmentFragmentDoc,
    '\n    query patientRecordAutomaton($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            ...TargetingReportFragment\n            ...TargetedMriFragment\n            ...TargetingStatusFragment\n            ...TargetingUpdateFragment\n            ...OperatorFeedbackFragment\n            ...TargetingCommandsFragment\n        }\n    }\n':
        types.PatientRecordAutomatonDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query actionLogs(\n        $options: FetchingActionLogsOptionInput\n        $conditions: ActionLogFiltersInput\n    ) {\n        actionLogs(options: $options, conditions: $conditions) {\n            totalCount\n            hasMore\n            items {\n                _id\n                action\n                description\n                user {\n                    _id\n                    firstname\n                    lastname\n                    email\n                }\n                creationDate\n            }\n        }\n    }\n'
): (typeof documents)['\n    query actionLogs(\n        $options: FetchingActionLogsOptionInput\n        $conditions: ActionLogFiltersInput\n    ) {\n        actionLogs(options: $options, conditions: $conditions) {\n            totalCount\n            hasMore\n            items {\n                _id\n                action\n                description\n                user {\n                    _id\n                    firstname\n                    lastname\n                    email\n                }\n                creationDate\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation createCommercial(\n        $commercialData: CommercialCreateSchema!\n    ) {\n        createCommercial(commercialData: $commercialData)\n    }\n'
): (typeof documents)['\n    mutation createCommercial(\n        $commercialData: CommercialCreateSchema!\n    ) {\n        createCommercial(commercialData: $commercialData)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation removeCommercialFromRegion(\n        $associationData: CommercialRemoveFromRegionSchema!\n    ) {\n        removeCommercialFromRegion(associationData: $associationData)\n    }\n'
): (typeof documents)['\n    mutation removeCommercialFromRegion(\n        $associationData: CommercialRemoveFromRegionSchema!\n    ) {\n        removeCommercialFromRegion(associationData: $associationData)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query commercials {\n        commercials {\n            email\n            firstname\n            lastname\n        }\n    }\n'
): (typeof documents)['\n    query commercials {\n        commercials {\n            email\n            firstname\n            lastname\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query findCommercialsInRegion($regionId: Float!) {\n        findCommercialsInRegion(regionId: $regionId) {\n            email\n            firstname\n            lastname\n            associationDate\n        }\n    }\n'
): (typeof documents)['\n    query findCommercialsInRegion($regionId: Float!) {\n        findCommercialsInRegion(regionId: $regionId) {\n            email\n            firstname\n            lastname\n            associationDate\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation createHospitalCenter(\n        $createHospital: HospitalCenterCreateSchema!\n    ) {\n        createHospitalCenter(hospitalCenter: $createHospital) {\n            _id\n        }\n    }\n'
): (typeof documents)['\n    mutation createHospitalCenter(\n        $createHospital: HospitalCenterCreateSchema!\n    ) {\n        createHospitalCenter(hospitalCenter: $createHospital) {\n            _id\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query hospitalCenters {\n        hospitalCenters {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n'
): (typeof documents)['\n    query hospitalCenters {\n        hospitalCenters {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query hospitalCenter($idHospitalCenter: Float!) {\n        hospitalCenter(idHospitalCenter: $idHospitalCenter) {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n'
): (typeof documents)['\n    query hospitalCenter($idHospitalCenter: Float!) {\n        hospitalCenter(idHospitalCenter: $idHospitalCenter) {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation removeHospitalCenter($idHospitalCenter: Float!) {\n        removeHospitalCenter(idHospitalCenter: $idHospitalCenter) {\n            name\n        }\n    }\n'
): (typeof documents)['\n    mutation removeHospitalCenter($idHospitalCenter: Float!) {\n        removeHospitalCenter(idHospitalCenter: $idHospitalCenter) {\n            name\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation updateHospitalCenter(\n        $updateHospital: HospitalCenterUpdateSchema!\n    ) {\n        updateHospitalCenter(hospitalCenter: $updateHospital) {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n'
): (typeof documents)['\n    mutation updateHospitalCenter(\n        $updateHospital: HospitalCenterUpdateSchema!\n    ) {\n        updateHospitalCenter(hospitalCenter: $updateHospital) {\n            _id\n            name\n            address\n            regionId\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation removeHospitalCenterRegion(\n        $idHospitalCenter: Float!\n    ) {\n        removeHospitalCenterRegion(idHospitalCenter: $idHospitalCenter) {\n            _id\n            name\n            regionId\n        }\n    }\n'
): (typeof documents)['\n    mutation removeHospitalCenterRegion(\n        $idHospitalCenter: Float!\n    ) {\n        removeHospitalCenterRegion(idHospitalCenter: $idHospitalCenter) {\n            _id\n            name\n            regionId\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation createPatientRecord(\n        $createHospital: PatientRecordCreateSchema!\n        $originalIrm: Upload\n    ) {\n        createPatientRecord(\n            patientRecord: $createHospital\n            originalIrm: $originalIrm\n        ) {\n            _id\n        }\n    }\n'
): (typeof documents)['\n    mutation createPatientRecord(\n        $createHospital: PatientRecordCreateSchema!\n        $originalIrm: Upload\n    ) {\n        createPatientRecord(\n            patientRecord: $createHospital\n            originalIrm: $originalIrm\n        ) {\n            _id\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query patientRecords {\n        patientRecords {\n            _id\n            firstname\n            lastname\n            patientNumber\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            clinicianDowloadedDate\n            creationDate\n            treatmentDate\n            clinician {\n                firstname\n                lastname\n                hospitalCenter {\n                    name\n                }\n            }\n        }\n    }\n'
): (typeof documents)['\n    query patientRecords {\n        patientRecords {\n            _id\n            firstname\n            lastname\n            patientNumber\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            clinicianDowloadedDate\n            creationDate\n            treatmentDate\n            clinician {\n                firstname\n                lastname\n                hospitalCenter {\n                    name\n                }\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query patientRecord($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            firstname\n            lastname\n            patientNumber\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            hasOriginalIrm\n            hasTargetedIrm\n            hasTargetedPostOpIrm\n            hasPostOpIrm\n            clinicianDowloadedDate\n            treatmentDate\n            creationDate\n            clinician {\n                firstname\n                lastname\n                hospitalCenter {\n                    name\n                }\n            }\n            giveUpReason\n            targetingStatus\n            targetingLastUpdate\n            targetingOperatorFeedback {\n                title\n                body\n                color\n            }\n        }\n    }\n'
): (typeof documents)['\n    query patientRecord($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            firstname\n            lastname\n            patientNumber\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            hasOriginalIrm\n            hasTargetedIrm\n            hasTargetedPostOpIrm\n            hasPostOpIrm\n            clinicianDowloadedDate\n            treatmentDate\n            creationDate\n            clinician {\n                firstname\n                lastname\n                hospitalCenter {\n                    name\n                }\n            }\n            giveUpReason\n            targetingStatus\n            targetingLastUpdate\n            targetingOperatorFeedback {\n                title\n                body\n                color\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query patientRecordUpdateFields($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            firstname\n            lastname\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            hasPostOpIrm\n        }\n    }\n'
): (typeof documents)['\n    query patientRecordUpdateFields($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            firstname\n            lastname\n            pathologie\n            target\n            side\n            typeOfSurgery\n            status\n            hasPostOpIrm\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation removePatientRecord($idPatientRecord: String!) {\n        removePatientRecord(idPatientRecord: $idPatientRecord) {\n            patientNumber\n        }\n    }\n'
): (typeof documents)['\n    mutation removePatientRecord($idPatientRecord: String!) {\n        removePatientRecord(idPatientRecord: $idPatientRecord) {\n            patientNumber\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation rejectPatientRecord($idPatientRecord: String!, $reason: String!) {\n        rejectPatientRecord(\n            idPatientRecord: $idPatientRecord\n            reason: $reason\n        ) {\n            patientNumber\n        }\n    }\n'
): (typeof documents)['\n    mutation rejectPatientRecord($idPatientRecord: String!, $reason: String!) {\n        rejectPatientRecord(\n            idPatientRecord: $idPatientRecord\n            reason: $reason\n        ) {\n            patientNumber\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation updatePatientRecord(\n        $updateHospital: PatientRecordUpdateSchema!\n        $originalIrm: Upload\n        $postOpIrm: Upload\n    ) {\n        updatePatientRecord(\n            patientRecord: $updateHospital\n            originalIrm: $originalIrm\n            postOpIrm: $postOpIrm\n        ) {\n            _id\n        }\n    }\n'
): (typeof documents)['\n    mutation updatePatientRecord(\n        $updateHospital: PatientRecordUpdateSchema!\n        $originalIrm: Upload\n        $postOpIrm: Upload\n    ) {\n        updatePatientRecord(\n            patientRecord: $updateHospital\n            originalIrm: $originalIrm\n            postOpIrm: $postOpIrm\n        ) {\n            _id\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation setOriginalIrm($idRecord: String!, $irm: Upload!) {\n        setOriginalIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'
): (typeof documents)['\n    mutation setOriginalIrm($idRecord: String!, $irm: Upload!) {\n        setOriginalIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation setTargetedIrm($idRecord: String!, $irm: Upload!) {\n        setTargetedIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'
): (typeof documents)['\n    mutation setTargetedIrm($idRecord: String!, $irm: Upload!) {\n        setTargetedIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation setPostOpIrm($idRecord: String!, $irm: Upload!) {\n        setPostOpIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'
): (typeof documents)['\n    mutation setPostOpIrm($idRecord: String!, $irm: Upload!) {\n        setPostOpIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation setTargetedPostOpIrm($idRecord: String!, $irm: Upload!) {\n        setTargetedPostOpIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'
): (typeof documents)['\n    mutation setTargetedPostOpIrm($idRecord: String!, $irm: Upload!) {\n        setTargetedPostOpIrm(idRecord: $idRecord, irm: $irm) {\n            _id\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation sendEnOfProcessingMail($idRecord: String!) {\n        sendEndOfProcessingMail(idRecord: $idRecord)\n    }\n'
): (typeof documents)['\n    mutation sendEnOfProcessingMail($idRecord: String!) {\n        sendEndOfProcessingMail(idRecord: $idRecord)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation sendEnOfPostOpProcessingMail($idRecord: String!) {\n        sendEndOfPostOpProcessingMail(idRecord: $idRecord)\n    }\n'
): (typeof documents)['\n    mutation sendEnOfPostOpProcessingMail($idRecord: String!) {\n        sendEndOfPostOpProcessingMail(idRecord: $idRecord)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query regions {\n        regions {\n            _id\n            name\n            nbHospitals\n            hospitals {\n                name\n            }\n            nbCommercials\n            creationDate\n            lastUpdate\n        }\n    }\n'
): (typeof documents)['\n    query regions {\n        regions {\n            _id\n            name\n            nbHospitals\n            hospitals {\n                name\n            }\n            nbCommercials\n            creationDate\n            lastUpdate\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query findRegion($regionId: Float!) {\n        findRegion(regionId: $regionId) {\n            _id\n            name\n        }\n    }\n'
): (typeof documents)['\n    query findRegion($regionId: Float!) {\n        findRegion(regionId: $regionId) {\n            _id\n            name\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation createRegion(\n        $regionData: RegionCreateSchema!\n    ) {\n        createRegion(regionData: $regionData) {\n            _id\n            name\n        }\n    }\n'
): (typeof documents)['\n    mutation createRegion(\n        $regionData: RegionCreateSchema!\n    ) {\n        createRegion(regionData: $regionData) {\n            _id\n            name\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation removeRegion($regionId: Float!) {\n        removeRegion(regionId: $regionId)\n    }\n'
): (typeof documents)['\n    mutation removeRegion($regionId: Float!) {\n        removeRegion(regionId: $regionId)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query mailSettings {\n        mailSettings {\n            mail\n            password\n            service\n            smtp\n            port\n        }\n    }\n'
): (typeof documents)['\n    query mailSettings {\n        mailSettings {\n            mail\n            password\n            service\n            smtp\n            port\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation setMailSettings($settings: MailSettingsUpdateSchema!) {\n        setMailSettings(settings: $settings) {\n            mail\n            password\n            service\n            smtp\n            port\n        }\n    }\n'
): (typeof documents)['\n    mutation setMailSettings($settings: MailSettingsUpdateSchema!) {\n        setMailSettings(settings: $settings) {\n            mail\n            password\n            service\n            smtp\n            port\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n                    mutation contactUs($content: String!, $subject: String!) {\n                        contactUs(content: $content, subject: $subject)\n                    }\n                '
): (typeof documents)['\n                    mutation contactUs($content: String!, $subject: String!) {\n                        contactUs(content: $content, subject: $subject)\n                    }\n                '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation login($authentication: UserLoginSchema!) {\n        login(authentication: $authentication) {\n            _id\n            email\n        }\n    }\n'
): (typeof documents)['\n    mutation login($authentication: UserLoginSchema!) {\n        login(authentication: $authentication) {\n            _id\n            email\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query me {\n        me {\n            lastname\n            email\n            firstname\n            role\n        }\n    }\n'
): (typeof documents)['\n    query me {\n        me {\n            lastname\n            email\n            firstname\n            role\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation logout {\n        logout\n    }\n'
): (typeof documents)['\n    mutation logout {\n        logout\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query users {\n        users {\n            _id\n            lastname\n            firstname\n            email\n            role\n            hospitalCenter {\n                name\n            }\n            status\n        }\n    }\n'
): (typeof documents)['\n    query users {\n        users {\n            _id\n            lastname\n            firstname\n            email\n            role\n            hospitalCenter {\n                name\n            }\n            status\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation updateUser($updateUser: UserUpdateSchema!) {\n        updateUser(user: $updateUser) {\n            _id\n            firstname\n            lastname\n        }\n    }\n'
): (typeof documents)['\n    mutation updateUser($updateUser: UserUpdateSchema!) {\n        updateUser(user: $updateUser) {\n            _id\n            firstname\n            lastname\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation createUser($createUser: UserCreateSchema!) {\n        createUser(user: $createUser) {\n            _id\n            email\n        }\n    }\n'
): (typeof documents)['\n    mutation createUser($createUser: UserCreateSchema!) {\n        createUser(user: $createUser) {\n            _id\n            email\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation deleteUser($idUser: String!) {\n        deleteUser(idUser: $idUser)\n    }\n'
): (typeof documents)['\n    mutation deleteUser($idUser: String!) {\n        deleteUser(idUser: $idUser)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query user($idUser: String!) {\n        user(idUser: $idUser) {\n            _id\n            lastname\n            firstname\n            email\n            role\n            hospitalCenter {\n                _id\n            }\n        }\n    }\n'
): (typeof documents)['\n    query user($idUser: String!) {\n        user(idUser: $idUser) {\n            _id\n            lastname\n            firstname\n            email\n            role\n            hospitalCenter {\n                _id\n            }\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation resetPassword($token: String!, $password: String!) {\n        resetPassword(password: $password, token: $token)\n    }\n'
): (typeof documents)['\n    mutation resetPassword($token: String!, $password: String!) {\n        resetPassword(password: $password, token: $token)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation forgottenPassword($email: String!) {\n        forgottenPassword(email: $email)\n    }\n'
): (typeof documents)['\n    mutation forgottenPassword($email: String!) {\n        forgottenPassword(email: $email)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    fragment OperatorFeedbackFragment on PatientRecordSchema {\n        targetingOperatorFeedback {\n            title\n            body\n            color\n        }\n    }\n'
): (typeof documents)['\n    fragment OperatorFeedbackFragment on PatientRecordSchema {\n        targetingOperatorFeedback {\n            title\n            body\n            color\n        }\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    fragment TargetedMriFragment on PatientRecordSchema {\n        patientNumber\n        targetingStatus\n        originalMriTargetedUrl\n        originalMRITargetedFileName\n    }\n'
): (typeof documents)['\n    fragment TargetedMriFragment on PatientRecordSchema {\n        patientNumber\n        targetingStatus\n        originalMriTargetedUrl\n        originalMRITargetedFileName\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    fragment TargetingCommandsFragment on PatientRecordSchema {\n        _id\n        targetingWasApproved\n        targetingWasRejected\n        targetingReportFinalized\n        hasPostOpIrm\n        hasTargetedPostOpIrm\n    }\n'
): (typeof documents)['\n    fragment TargetingCommandsFragment on PatientRecordSchema {\n        _id\n        targetingWasApproved\n        targetingWasRejected\n        targetingReportFinalized\n        hasPostOpIrm\n        hasTargetedPostOpIrm\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation approveAutomatedTargeting($idRecord: String!) {\n        approveAutomatedTargeting(idRecord: $idRecord)\n    }\n'
): (typeof documents)['\n    mutation approveAutomatedTargeting($idRecord: String!) {\n        approveAutomatedTargeting(idRecord: $idRecord)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation rejectAutomatedTargeting($idRecord: String!) {\n        rejectAutomatedTargeting(idRecord: $idRecord)\n    }\n'
): (typeof documents)['\n    mutation rejectAutomatedTargeting($idRecord: String!) {\n        rejectAutomatedTargeting(idRecord: $idRecord)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    mutation forceAutomatedTargeting($idRecord: String!) {\n        forceAutomatedTargeting(idRecord: $idRecord)\n    }\n'
): (typeof documents)['\n    mutation forceAutomatedTargeting($idRecord: String!) {\n        forceAutomatedTargeting(idRecord: $idRecord)\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    fragment TargetingUpdateFragment on PatientRecordSchema {\n        targetingLastUpdate\n    }\n'
): (typeof documents)['\n    fragment TargetingUpdateFragment on PatientRecordSchema {\n        targetingLastUpdate\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    fragment TargetingReportFragment on PatientRecordSchema {\n        _id\n        patientNumber\n        automatonReportUrl\n        automatonReportFileName\n    }\n'
): (typeof documents)['\n    fragment TargetingReportFragment on PatientRecordSchema {\n        _id\n        patientNumber\n        automatonReportUrl\n        automatonReportFileName\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    fragment TargetingStatusFragment on PatientRecordSchema {\n        targetingStatus\n    }\n'
): (typeof documents)['\n    fragment TargetingStatusFragment on PatientRecordSchema {\n        targetingStatus\n    }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
    source: '\n    query patientRecordAutomaton($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            ...TargetingReportFragment\n            ...TargetedMriFragment\n            ...TargetingStatusFragment\n            ...TargetingUpdateFragment\n            ...OperatorFeedbackFragment\n            ...TargetingCommandsFragment\n        }\n    }\n'
): (typeof documents)['\n    query patientRecordAutomaton($idPatientRecord: String!) {\n        patientRecord(idPatientRecord: $idPatientRecord) {\n            _id\n            ...TargetingReportFragment\n            ...TargetedMriFragment\n            ...TargetingStatusFragment\n            ...TargetingUpdateFragment\n            ...OperatorFeedbackFragment\n            ...TargetingCommandsFragment\n        }\n    }\n'];

export function gql(source: string) {
    return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
    TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
