import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Select,
    TextField,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import FormatToolbox from '../../../toolbox/FormatToolbox';
import {
    isValidPathologie,
    isValidPatientRecordStatus,
    isValidSide,
    isValidSurgeryType,
    isValidTarget,
} from 'types/check';
import {
    PatientRecordPathology,
    PatientRecordSide,
    PatientRecordStatus,
    PatientRecordTarget,
    PatientRecordTypeOfSurgery,
    PatientRecordUpdateSchema,
    PatientRecordsQuery,
} from '__generated__/graphql';
import { Unpacked } from 'types/helpers';

type PatientRecord = Unpacked<PatientRecordsQuery['patientRecords']>;
const styles = () => createStyles({});

type PatientRecordFilters = Partial<PatientRecordUpdateSchema>;
interface Filters extends PatientRecordFilters {
    search?: string | undefined;
}

interface IState {
    filters: Filters;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
    records: Array<PatientRecord>;
    onApplyFilter: (records: Array<PatientRecord>) => void;
}

class PatientRecordFiltersForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            filters: {},
        };
    }

    render = () => {
        return (
            <Paper>
                <Grid container component={Box} spacing={1} p={1}>
                    {/* <Grid item xs={12}>
						<Typography variant="h5">Filters</Typography>
					</Grid> */}
                    <Grid item xs={12} sm={6} lg={2}>
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={(e) =>
                                this.__onChangeSearch(e.target.value)
                            }
                            label={`${this.props.t(
                                'generic.button.search'
                            )} ...`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel
                                shrink={true}
                                htmlFor="status-native-simple"
                            >
                                {this.props.t('pr.label.status')}
                            </InputLabel>
                            <Select
                                native
                                variant="outlined"
                                value={this.state.filters.status}
                                onChange={(e) =>
                                    this.__onChangeStatus(e.target.value)
                                }
                                inputProps={{ id: 'status-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('pr.label.status')}
                                    />
                                }
                            >
                                <option value={undefined}>{undefined}</option>
                                {Object.values(PatientRecordStatus).map(
                                    (value) => (
                                        <option key={value} value={value}>
                                            {FormatToolbox.patientRecordEnumToString(
                                                value
                                            )}
                                        </option>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel
                                shrink={true}
                                htmlFor="pathologie-native-simple"
                            >
                                {this.props.t('pr.label.pathology')}
                            </InputLabel>
                            <Select
                                native
                                variant="outlined"
                                value={this.state.filters.pathologie}
                                onChange={(e) =>
                                    this.__onChangePathologie(e.target.value)
                                }
                                inputProps={{ id: 'pathologie-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'pr.label.pathology'
                                        )}
                                    />
                                }
                            >
                                <option value={undefined} />
                                <option
                                    value={PatientRecordPathology.Parkinson}
                                >
                                    {this.props.t(
                                        PatientRecordPathology.Parkinson
                                    )}
                                </option>
                                <option
                                    value={
                                        PatientRecordPathology.EssentialTremor
                                    }
                                >
                                    {this.props.t(
                                        PatientRecordPathology.EssentialTremor
                                    )}
                                </option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel
                                shrink={true}
                                htmlFor="target-native-simple"
                            >
                                {this.props.t('pr.label.target')}
                            </InputLabel>
                            <Select
                                native
                                variant="outlined"
                                value={this.state.filters.target}
                                onChange={(e) =>
                                    this.__onChangeTarget(e.target.value)
                                }
                                inputProps={{ id: 'target-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('pr.label.target')}
                                    />
                                }
                            >
                                <option value={undefined}>{undefined}</option>
                                <option value={PatientRecordTarget.Stn}>
                                    {this.props.t(PatientRecordTarget.Stn)}
                                </option>
                                <option value={PatientRecordTarget.Vim}>
                                    {this.props.t(PatientRecordTarget.Vim)}
                                </option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel
                                shrink={true}
                                htmlFor="side-native-simple"
                            >
                                {this.props.t('pr.label.side')}
                            </InputLabel>
                            <Select
                                native
                                variant="outlined"
                                value={this.state.filters.side}
                                onChange={(e) =>
                                    this.__onChangeSide(e.target.value)
                                }
                                inputProps={{ id: 'side-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('pr.label.side')}
                                    />
                                }
                            >
                                <option value={undefined}>{undefined}</option>
                                <option value={PatientRecordSide.Bilateral}>
                                    {this.props.t(PatientRecordSide.Bilateral)}
                                </option>
                                <option value={PatientRecordSide.Left}>
                                    {this.props.t(PatientRecordSide.Left)}
                                </option>
                                <option value={PatientRecordSide.Right}>
                                    {this.props.t(PatientRecordSide.Right)}
                                </option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2}>
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel
                                shrink={true}
                                htmlFor="typeOfSurgery-native-simple"
                            >
                                {this.props.t('pr.label.surgeryType')}
                            </InputLabel>
                            <Select
                                native
                                variant="outlined"
                                value={this.state.filters.typeOfSurgery}
                                onChange={(e) =>
                                    this.__onChangeSurgeryType(e.target.value)
                                }
                                inputProps={{
                                    id: 'typeOfSurgery-native-simple',
                                }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'pr.label.surgeryType'
                                        )}
                                    />
                                }
                            >
                                <option value={undefined}>{undefined}</option>
                                <option value={PatientRecordTypeOfSurgery.Dbs}>
                                    {this.props.t(
                                        PatientRecordTypeOfSurgery.Dbs
                                    )}
                                </option>
                                <option value={PatientRecordTypeOfSurgery.Hifu}>
                                    {this.props.t(
                                        PatientRecordTypeOfSurgery.Hifu
                                    )}
                                </option>
                                <option
                                    value={
                                        PatientRecordTypeOfSurgery.Radiosurgery
                                    }
                                >
                                    {this.props.t(
                                        PatientRecordTypeOfSurgery.Radiosurgery
                                    )}
                                </option>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid container justifyContent="flex-end"
						 item xs={12} sm={6} lg={2}>
						<Grid item xs={8} sm={4} >
							<Divider />
						</Grid>
					</Grid> */}
                    <Grid
                        item
                        container
                        xs
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid item>
                            <Button
                                onClick={this.__onPressReset}
                                variant="contained"
                                color="error"
                            >
                                <CloseIcon />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={this.__onPressApply}
                                variant="contained"
                                color="secondary"
                            >
                                <ArrowForwardIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    __onPressApply = () => {
        const records = this.props.records.filter((record: PatientRecord) => {
            for (const [key, value] of Object.entries(
                this.state.filters as PatientRecordFilters
            )) {
                const k: keyof PatientRecordFilters =
                    key as keyof PatientRecordFilters;
                if (
                    value !== undefined &&
                    (value as string).length > 0 &&
                    Object.prototype.hasOwnProperty.call(record, key) &&
                    record[k] !== value
                )
                    return false;
            }
            const search = this.state.filters.search;
            if (search) {
                const words = search
                    .toLowerCase()
                    .split(' ')
                    .filter((word) => Boolean(word.length));
                const stringified = JSON.stringify(record).toLowerCase();

                console.debug(words.map((word) => stringified.includes(word)));
                if (
                    // Check all words exist
                    !words
                        .map((word) => stringified.includes(word))
                        .reduce((a, b) => a && b, true)
                )
                    return false;
            }

            return true;
        });
        this.props.onApplyFilter(records);
    };

    __onPressReset = () => {
        const filters: Filters = {
            target: '' as PatientRecordTarget,
            side: '' as PatientRecordSide,
            typeOfSurgery: '' as PatientRecordTypeOfSurgery,
            pathologie: '' as PatientRecordPathology,
            search: '',
            status: '' as PatientRecordStatus,
        };
        this.setState({ filters }, this.__onPressApply);
    };

    __onChangeSearch = (inputSearch: string) => {
        const search = inputSearch == '' ? undefined : inputSearch;
        if (this.state.filters) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    search,
                },
            });
        }
    };

    __onChangeStatus = (inputStatus: string | null) => {
        const status = inputStatus == '' ? undefined : inputStatus;
        if (this.state.filters && isValidPatientRecordStatus(status)) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    status,
                },
            });
        }
    };

    __onChangePathologie = (inputPathologie: string | null) => {
        const pathologie = inputPathologie == '' ? undefined : inputPathologie;
        if (this.state.filters && isValidPathologie(pathologie)) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    pathologie,
                },
            });
        }
    };

    __onChangeTarget = (inputTarget: string | null) => {
        const target = inputTarget == '' ? undefined : inputTarget;
        if (this.state.filters && isValidTarget(target)) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    target,
                },
            });
        }
    };

    __onChangeSide = (inputSide: string | null) => {
        const side = inputSide == '' ? undefined : inputSide;
        if (this.state.filters && isValidSide(side)) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    side,
                },
            });
        }
    };

    __onChangeSurgeryType = (inputTypeOfSurgery: string | null) => {
        const typeOfSurgery =
            inputTypeOfSurgery == '' ? undefined : inputTypeOfSurgery;
        if (this.state.filters && isValidSurgeryType(typeOfSurgery)) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    typeOfSurgery,
                },
            });
        }
    };
}

export default withTranslation()(withStyles(styles)(PatientRecordFiltersForm));
