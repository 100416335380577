import { FetchPolicy, useQuery } from '@apollo/react-hoc';
import JSZip from 'jszip';
import { Client } from '.';
import { gql } from '__generated__/gql';
import {
    PatientRecordCreateSchema,
    PatientRecordUpdateSchema,
} from '__generated__/graphql';
import { API_ENDPOINT } from 'config';

export const MCreatePatientRecord = gql(`
    mutation createPatientRecord(
        $createHospital: PatientRecordCreateSchema!
        $originalIrm: Upload
    ) {
        createPatientRecord(
            patientRecord: $createHospital
            originalIrm: $originalIrm
        ) {
            _id
        }
    }
`);

export const QPatientRecords = gql(`
    query patientRecords {
        patientRecords {
            _id
            firstname
            lastname
            patientNumber
            pathologie
            target
            side
            typeOfSurgery
            status
            clinicianDowloadedDate
            creationDate
            treatmentDate
            clinician {
                firstname
                lastname
                hospitalCenter {
                    name
                }
            }
        }
    }
`);

export const QPatientRecord = gql(`
    query patientRecord($idPatientRecord: String!) {
        patientRecord(idPatientRecord: $idPatientRecord) {
            _id
            firstname
            lastname
            patientNumber
            pathologie
            target
            side
            typeOfSurgery
            status
            hasOriginalIrm
            hasTargetedIrm
            hasTargetedPostOpIrm
            hasPostOpIrm
            clinicianDowloadedDate
            treatmentDate
            creationDate
            clinician {
                firstname
                lastname
                hospitalCenter {
                    name
                }
            }
            giveUpReason
            targetingStatus
            targetingLastUpdate
            targetingOperatorFeedback {
                title
                body
                color
            }
        }
    }
`);

export const QPatientRecordWithFieldForUpdate = gql(`
    query patientRecordUpdateFields($idPatientRecord: String!) {
        patientRecord(idPatientRecord: $idPatientRecord) {
            _id
            firstname
            lastname
            pathologie
            target
            side
            typeOfSurgery
            status
            hasPostOpIrm
        }
    }
`);
export const MRemovePatientRecord = gql(`
    mutation removePatientRecord($idPatientRecord: String!) {
        removePatientRecord(idPatientRecord: $idPatientRecord) {
            patientNumber
        }
    }
`);

export const MRejectPatientRecord = gql(`
    mutation rejectPatientRecord($idPatientRecord: String!, $reason: String!) {
        rejectPatientRecord(
            idPatientRecord: $idPatientRecord
            reason: $reason
        ) {
            patientNumber
        }
    }
`);

export const MUpdatePatientRecord = gql(`
    mutation updatePatientRecord(
        $updateHospital: PatientRecordUpdateSchema!
        $originalIrm: Upload
        $postOpIrm: Upload
    ) {
        updatePatientRecord(
            patientRecord: $updateHospital
            originalIrm: $originalIrm
            postOpIrm: $postOpIrm
        ) {
            _id
        }
    }
`);

export const MSetOriginalIrm = gql(`
    mutation setOriginalIrm($idRecord: String!, $irm: Upload!) {
        setOriginalIrm(idRecord: $idRecord, irm: $irm) {
            _id
        }
    }
`);

export const MSetTargetedIrm = gql(`
    mutation setTargetedIrm($idRecord: String!, $irm: Upload!) {
        setTargetedIrm(idRecord: $idRecord, irm: $irm) {
            _id
        }
    }
`);
export const MSetPostOpIrm = gql(`
    mutation setPostOpIrm($idRecord: String!, $irm: Upload!) {
        setPostOpIrm(idRecord: $idRecord, irm: $irm) {
            _id
        }
    }
`);

export const MSetTargetedPostOpIrm = gql(`
    mutation setTargetedPostOpIrm($idRecord: String!, $irm: Upload!) {
        setTargetedPostOpIrm(idRecord: $idRecord, irm: $irm) {
            _id
        }
    }
`);

export const MSendEndOfProcessingMail = gql(`
    mutation sendEnOfProcessingMail($idRecord: String!) {
        sendEndOfProcessingMail(idRecord: $idRecord)
    }
`);
export const MSendEndOfPostOpProcessingMail = gql(`
    mutation sendEnOfPostOpProcessingMail($idRecord: String!) {
        sendEndOfPostOpProcessingMail(idRecord: $idRecord)
    }
`);

export class PatientRecordRequests {
    public static async createPatientRecord(
        createHospital: PatientRecordCreateSchema,
        originalIrm: unknown
    ) {
        return (
            await Client.mutate({
                mutation: MCreatePatientRecord,
                variables: {
                    createHospital,
                    originalIrm,
                },
            })
        ).data!.createPatientRecord;
    }

    public static async patientRecords(fetchPolicy?: FetchPolicy) {
        return (
            await Client.query({
                query: QPatientRecords,
                fetchPolicy,
            })
        ).data.patientRecords;
    }

    public static async patientRecord(
        idPatientRecord: string,
        fetchPolicy?: FetchPolicy
    ) {
        return (
            await Client.query({
                query: QPatientRecord,
                fetchPolicy,
                variables: {
                    idPatientRecord,
                },
            })
        ).data.patientRecord;
    }

    public static usePatientRecord(
        idPatientRecord: string,
        fetchPolicy?: FetchPolicy
    ) {
        return useQuery(QPatientRecord, {
            fetchPolicy,
            variables: {
                idPatientRecord,
            },
        });
    }

    public static async patientRecordWithFieldForUpdate(
        idPatientRecord: string,
        fetchPolicy?: FetchPolicy
    ) {
        return (
            await Client.query({
                query: QPatientRecordWithFieldForUpdate,
                fetchPolicy,
                variables: {
                    idPatientRecord,
                },
            })
        ).data.patientRecord;
    }

    public static async removePatientRecord(idPatientRecord: string) {
        return (
            await Client.mutate({
                mutation: MRemovePatientRecord,
                variables: {
                    idPatientRecord,
                },
            })
        ).data!.removePatientRecord;
    }
    public static async rejectPatientRecord(
        idPatientRecord: string,
        reason: string
    ) {
        return (
            await Client.mutate({
                mutation: MRejectPatientRecord,
                variables: {
                    idPatientRecord,
                    reason,
                },
            })
        ).data!.rejectPatientRecord;
    }

    public static async updatePatientRecord(
        updateHospital: PatientRecordUpdateSchema,
        originalIrm?: unknown,
        postOpIrm?: unknown
    ) {
        return (
            await Client.mutate({
                mutation: MUpdatePatientRecord,
                variables: {
                    updateHospital,
                    originalIrm,
                    postOpIrm,
                },
            })
        ).data!.updatePatientRecord;
    }

    public static async setOriginalIrm(idRecord: string, irm: unknown) {
        return (
            await Client.mutate({
                mutation: MSetOriginalIrm,
                variables: {
                    idRecord: idRecord,
                    irm: irm,
                },
            })
        ).data!.setOriginalIrm;
    }

    public static async setTargetedIrm(idRecord: string, irm: unknown) {
        return (
            await Client.mutate({
                mutation: MSetTargetedIrm,
                variables: {
                    idRecord: idRecord,
                    irm: irm,
                },
            })
        ).data!.setTargetedIrm;
    }

    public static async setPostOpIrm(idRecord: string, irm: unknown) {
        return (
            await Client.mutate({
                mutation: MSetPostOpIrm,
                variables: {
                    idRecord: idRecord,
                    irm: irm,
                },
            })
        ).data!.setPostOpIrm;
    }

    public static async setTargetedPostOpIrm(idRecord: string, irm: unknown) {
        return (
            await Client.mutate({
                mutation: MSetTargetedPostOpIrm,
                variables: {
                    idRecord: idRecord,
                    irm: irm,
                },
            })
        ).data!.setTargetedPostOpIrm;
    }

    public static async downloadOriginalIrmFileNames(recordId: string) {
        const zip = new JSZip();

        const response = await fetch(
            `${API_ENDPOINT}/patient-record/original-irm/${recordId}`,
            { credentials: 'include' }
        );
        const blob = await response.blob();
        const content = await zip.loadAsync(blob);
        console.log(Object.keys(content.files));
        return Object.keys(content.files);
    }

    public static async downloadTargetedIrmFileNames(recordId: string) {
        const zip = new JSZip();

        const response = await fetch(
            `${API_ENDPOINT}/patient-record/targeted-irm/${recordId}`,
            { credentials: 'include' }
        );
        const blob = await response.blob();
        const content = await zip.loadAsync(blob);
        console.log(Object.keys(content.files));
        return Object.keys(content.files);
    }

    public static async downloadPostOpIrmFileNames(recordId: string) {
        const zip = new JSZip();

        const response = await fetch(
            `${API_ENDPOINT}/patient-record/postop-original-irm/${recordId}`,
            { credentials: 'include' }
        );
        const blob = await response.blob();
        const content = await zip.loadAsync(blob);
        console.log(Object.keys(content.files));
        return Object.keys(content.files);
    }

    public static async downloadTargetedPostOpIrmFileNames(recordId: string) {
        const zip = new JSZip();

        const response = await fetch(
            `${API_ENDPOINT}/patient-record/postop-targeted-irm/${recordId}`,
            { credentials: 'include' }
        );
        const blob = await response.blob();
        const content = await zip.loadAsync(blob);
        console.log(Object.keys(content.files));
        return Object.keys(content.files);
    }

    public static async sendEndOfProcessingMail(idRecord: string) {
        return (
            await Client.mutate({
                mutation: MSendEndOfProcessingMail,
                variables: {
                    idRecord,
                },
            })
        ).data!.sendEndOfProcessingMail;
    }

    public static async sendEndOfPostOpProcessingMail(idRecord: string) {
        return (
            await Client.mutate({
                mutation: MSendEndOfPostOpProcessingMail,
                variables: {
                    idRecord,
                },
            })
        ).data!.sendEndOfPostOpProcessingMail;
    }
}
