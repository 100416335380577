import ConfirmationDialog, {
    ConfirmationInformation,
} from 'components/confirmationDialog';
import { useCallback, useState } from 'react';
import {
    useCommercialsInRegion,
    useRemoveCommercialFromRegion,
} from 'apollo/CommercialRequests';

import CommercialsTable from './commercialTableUi';
import { FindCommercialsInRegionQuery } from '__generated__/graphql';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Commercial = FindCommercialsInRegionQuery['findCommercialsInRegion'][0];

type RegionCommercialsTableProps = {
    regionId: number;
    regionName: string;
    onCommercialClicked?: (commercial: Commercial) => void;
    onCommercialRemovedFromRegion?: (commercial: Commercial) => void;
};

/** This table fetches and displays the list of commercials associated to a region.
 *
 * It shows the commercial email, firstname and lastname and also an action button
 * that removes the commercial from the input region (using the `props.regionId`).
 *
 * @param props.regionId The region id to fetch the commercials from.
 * @param props.regionName The region name to display in messages displayed to the users.
 * @param props.onCommercialClicked A callback that is called when a commercial ROW is clicked.
 * @param props.onCommercialRemovedFromRegion A callback that is called after a commercial was
 * removed from the region.
 *
 */
export default function RegionsCommercialTable(
    props: RegionCommercialsTableProps
) {
    const { t } = useTranslation();
    const { data } = useCommercialsInRegion(props.regionId);
    const [removeCommercialFromRegion] = useRemoveCommercialFromRegion(
        props.regionId
    );

    const deleteCommercial = useCallback(
        async (commercial: Commercial) => {
            const { data: removeData } = await removeCommercialFromRegion({
                variables: {
                    associationData: {
                        regionId: props.regionId,
                        commercialEmail: commercial.email,
                    },
                },
            });
            if (removeData?.removeCommercialFromRegion) {
                props?.onCommercialRemovedFromRegion?.(commercial);
            }
        },
        [
            props.regionId,
            props.onCommercialRemovedFromRegion,
            removeCommercialFromRegion,
        ]
    );

    const [confirmation, setConfirmation] = useState<ConfirmationInformation>();
    const onUnlinkClicked = useCallback(
        (commercial: Commercial) => {
            setConfirmation({
                title: t('commercials.delete.confirmationTitle', {
                    commercialEmail: commercial.email,
                    regionName: props.regionName,
                }),
                message: t('commercials.delete.confirmationMessage', {
                    commercialEmail: commercial.email,
                    regionName: props.regionName,
                }),
                onClickAccept: () => {
                    deleteCommercial(commercial);
                    setConfirmation(undefined);
                },
                onClickCancel: () => setConfirmation(undefined),
            });
        },
        [deleteCommercial, props.regionName, t]
    );

    if (
        data?.findCommercialsInRegion == null ||
        data.findCommercialsInRegion.length === 0
    ) {
        return (
            <Typography variant="body1">
                {t('commercials.table.empty')}
            </Typography>
        );
    }

    return (
        <>
            <ConfirmationDialog
                confirmation={confirmation}
                onClose={() => setConfirmation(undefined)}
            />
            <CommercialsTable
                commercials={data.findCommercialsInRegion}
                onRowClicked={props.onCommercialClicked}
                onUnlinkClicked={onUnlinkClicked}
            />
        </>
    );
}
