import { createTheme } from '@mui/material/styles';

// assets

// project imports
import { componentStyleOverrides } from './compStyleOverride';
import { themePalette } from './palette';
import { themeTypography } from './typography';
// import { ColorProps, CustomizationStateProps } from '../types';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = () => {
    const t = createTheme({
        palette: themePalette(),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px',
                },
            },
        },
        typography: themeTypography(),
    });

    t.components = componentStyleOverrides(t);
    return t;
};

export default theme;
