import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import * as MU from '@mui/material';
import {
    Button,
    createStyles,
    IconButton,
    Theme,
    Toolbar,
} from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { HEADER } from 'config';
import { GraphQLError } from 'graphql';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { clearDataCache, UserRequests } from '../../apollo';
import banniere from '../../assets/LOGO BLANC-300.png';
import { IErrorInformation } from '../../components/errorDialog';
import { IInformative } from '../../components/informativeDialog';
import { CreatePatientRecordRouter } from '../../router/dashboard/createPatientRecord/createPatientRecord.router';
import { PatientRecordsRouter } from '../../router/dashboard/patientRecords/patientRecords.router';
import HelpSection from './HelpSection';
import { MeQuery } from '__generated__/graphql';

const styles = ({ breakpoints }: Theme) =>
    createStyles({
        flex: {
            flexGrow: 1,
        },
        logo: {
            maxWidth: 50,
        },
        clinitianButton: {
            color: '#ffffff',
            borderColor: '#ffffff',
            border: 'solid 0.2px',
            marginLeft: '5px',
            '&:hover': {
                background: '#ffffff',
                color: '#6e374f',
            },
        },
        toolbar: {
            height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
            [breakpoints.down('md')]: {
                height: HEADER.MAIN_DESKTOP_HEIGHT,
            },
        },
    });

interface IState {
    user?: MeQuery['me'];

    information?: IInformative;
    error?: IErrorInformation;
}

interface IProps
    extends RouteComponentProps,
        WithTranslation,
        WithStyles<typeof styles> {
    onClickMenu?: () => void;

    onClickBack?: () => void;

    initialcurrentRoute?: string;
}

class Header extends React.Component<WithApolloClient<IProps>, IState> {
    constructor(props: WithApolloClient<IProps>) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        this.__loadUserData();
    };

    render() {
        const classes = this.props.classes;

        let button: JSX.Element | undefined = undefined;
        let menu: JSX.Element | undefined = undefined;

        if (this.state.user?.role === 'CLINICIAN') {
            button = this.__renderClinicianButtons();
        }

        if (this.state.user?.role !== 'CLINICIAN') {
            menu = this.__renderMenu();
            button = undefined;
        }

        return (
            <Toolbar>
                {button}
                {menu}
                <div className={classes.flex}></div>
                <MU.Stack direction="row" spacing={2} alignItems="center">
                    {this.__renderHelp()}
                    <Box>
                        <img
                            src={banniere}
                            alt="logo"
                            className={classes.logo}
                        />
                    </Box>
                </MU.Stack>
                {/* <MU.Typography variant="body2">Rebrain</MU.Typography> */}
            </Toolbar>
        );
    }

    __renderHelp = () => {
        return <HelpSection />;
    };
    __onClickMenu = () => {
        if (this.props.onClickMenu) this.props.onClickMenu();
    };

    __onClickItem = (route: string, _: boolean | undefined = true) => {
        this.props.history.push(route);
    };

    __loadUserData = () => {
        UserRequests.meBasic()
            .then((user) => this.setState({ user }))
            .catch();
    };

    __renderButton = (
        name: string,
        onClick: () => void,
        Icon?: () => JSX.Element
    ) => {
        const classes = this.props.classes;
        return (
            <Button
                onClick={onClick}
                className={classes.clinitianButton}
                startIcon={Icon ? <Icon /> : undefined}
            >
                {name}
            </Button>
        );
    };

    __renderClinicianButtons = () => {
        return (
            <>
                {this.__renderButton(this.props.t('pr.edit.create'), () =>
                    this.__onClickItem(CreatePatientRecordRouter.getRoute())
                )}
                {this.__renderButton(this.props.t('menu.patientRecords'), () =>
                    this.__onClickItem(PatientRecordsRouter.getRoute())
                )}
                {this.__renderButton(
                    this.props.t('session.button.logout'),
                    () => this.__onClickLogout(),
                    () => (
                        <ExitToAppIcon />
                    )
                )}
            </>
        );
    };

    __renderMenu = () => {
        return (
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={this.__onClickMenu}
            >
                <MenuIcon />
            </IconButton>
        );
    };

    __onClickLogout = () => {
        this.setState({
            information: {
                title: this.props.t('session.label.logout'),
                showProgress: true,
            },
        });

        UserRequests.logout()
            .then(async () => {
                await clearDataCache();
                this.props.history.push('/');
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            });
    };
}

export default withTranslation()(
    withStyles(styles)(
        withRouter(withApollo<WithApolloClient<IProps>, IState>(Header))
    )
);
