import {
    PatientRecordPathology,
    PatientRecordSide,
    PatientRecordStatus,
    PatientRecordTarget,
    PatientRecordTypeOfSurgery,
    Roles,
} from '__generated__/graphql';

export function isValidPathologie(
    pathologie: string | null | undefined
): pathologie is PatientRecordPathology {
    if (pathologie == null) {
        return false;
    }
    return Object.values(PatientRecordPathology)
        .map((r) => r.valueOf())
        .includes(pathologie);
}

export function isValidTarget(
    target: string | null | undefined
): target is PatientRecordTarget {
    if (target == null) {
        return false;
    }
    return Object.values(PatientRecordTarget)
        .map((r) => r.valueOf())
        .includes(target);
}

export function isValidSide(
    side: string | null | undefined
): side is PatientRecordSide {
    if (side == null) {
        return false;
    }
    return Object.values(PatientRecordSide)
        .map((r) => r.valueOf())
        .includes(side);
}

export function isValidSurgeryType(
    typeOfSurgery: string | null | undefined
): typeOfSurgery is PatientRecordTypeOfSurgery {
    if (typeOfSurgery == null) {
        return false;
    }
    return Object.values(PatientRecordTypeOfSurgery)
        .map((r) => r.valueOf())
        .includes(typeOfSurgery);
}

export function isValidPatientRecordStatus(
    status: string | null | undefined
): status is PatientRecordStatus {
    if (status == null) {
        return false;
    }
    return Object.values(PatientRecordStatus)
        .map((r) => r.valueOf())
        .includes(status);
}

export function isValidRole(role: string | null | undefined): role is Roles {
    if (role == null) {
        return false;
    }
    return Object.values(Roles)
        .map((r) => r.valueOf())
        .includes(role);
}
