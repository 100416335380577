import { Grid, Typography } from '@mui/material';
import { FragmentType, gql, useFragment } from '__generated__';
import { useTranslation } from 'react-i18next';
import { FormattedDate } from 'react-intl';

const TargetingUpdateFragment = gql(`
    fragment TargetingUpdateFragment on PatientRecordSchema {
        targetingLastUpdate
    }
`);

export type TargetingLastUpdateProps = {
    record: FragmentType<typeof TargetingUpdateFragment> | undefined;
};

export default function TargetingLastUpdate(props: TargetingLastUpdateProps) {
    const record = useFragment(TargetingUpdateFragment, props.record);
    const { t } = useTranslation();
    if (record?.targetingLastUpdate == null) {
        return null;
    }
    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item sm={5}>
                <Typography variant="h6">
                    {t('automaton.status.date')}:
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    <FormattedDate
                        day="2-digit"
                        month="long"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                        value={record.targetingLastUpdate}
                    />
                </Typography>
            </Grid>
        </Grid>
    );
}
