import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import {
    Button,
    ButtonGroup,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import Alert from '@mui/material/Alert';
import { GraphQLError } from 'graphql';
import JSZip from 'jszip';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PatientRecordRequests } from '../../../apollo';
import CreateSomethingWrapper from '../../../components/createSomethingWrapper';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import FileListDialog, {
    FileListDialogProps,
} from '../../../components/FileListDialog';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import path from 'path';
import ConfirmationDialog, {
    ConfirmationInformation,
} from 'components/confirmationDialog';
import {
    isValidPathologie,
    isValidSide,
    isValidSurgeryType,
    isValidTarget,
} from 'types/check';
import {
    PatientRecordPathology,
    PatientRecordUpdateFieldsQuery,
    PatientRecordSide,
    PatientRecordStatus,
    PatientRecordTarget,
    PatientRecordTypeOfSurgery,
    PatientRecordUpdateSchema,
} from '__generated__/graphql';
import { omit } from 'lodash';
import { getEnumKeys } from 'types/helpers';

type PatientRecord = PatientRecordUpdateFieldsQuery['patientRecord'];
const styles = ({ palette }: Theme) =>
    createStyles({
        circle: {
            display: 'inline-block',
            backgroundColor: palette.primary.main,
            borderRadius: '50%',
            width: 5,
            height: 5,
        },
    });

interface IState {
    record?: PatientRecordUpdateSchema | undefined;
    original?: PatientRecord | undefined;
    newNoTargetedIrm?: Array<File> | undefined;
    newPostOpIrm?: Array<File> | undefined;

    fieldErrors?: { [key: string]: string };
    error?: IErrorInformation;
    information?: IInformative;
    fileList?: FileListDialogProps;

    confirmation?: ConfirmationInformation;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps<{ id: string }>,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class UpdatePatientRecordPage extends React.Component<IProps, IState> {
    _folderInput: HTMLInputElement | null = null;
    _fileInput: HTMLInputElement | null = null;

    _folderPostOpInput: HTMLInputElement | null = null;
    _filePostOpInput: HTMLInputElement | null = null;

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        this.__loadOriginRecord(this.props.match.params.id);
    };

    setFolderInput = (ref: HTMLInputElement | null) => {
        this._folderInput = ref;
        if (this._folderInput) {
            // Allow folder uploads
            this._folderInput.webkitdirectory = true;
            this._folderInput.type = 'file';
        }
    };
    setFileInput = (ref: HTMLInputElement | null) => {
        this._fileInput = ref;
        if (this._fileInput) {
            this._fileInput.type = 'file';
        }
    };
    setFolderPostOpInput = (ref: HTMLInputElement | null) => {
        this._folderPostOpInput = ref;
        if (this._folderPostOpInput) {
            // Allow folder uploads
            this._folderPostOpInput.webkitdirectory = true;
            this._folderPostOpInput.type = 'file';
        }
    };
    setFilePostOpInput = (ref: HTMLInputElement | null) => {
        this._filePostOpInput = ref;
        if (this._filePostOpInput) {
            this._filePostOpInput.type = 'file';
        }
    };

    render = () => {
        return (
            <CreateSomethingWrapper
                title={this.props.t('pr.edit.update')}
                onPressCancel={this.__onPressCancel}
                onPressCreate={this.__onPressCreate}
            >
                {this.__renderForm()}
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
                <FileListDialog
                    fileListProps={this.state.fileList}
                    onClose={() => this.setState({ fileList: undefined })}
                />

                <ConfirmationDialog
                    confirmation={this.state.confirmation}
                    onClose={() => this.setState({ confirmation: undefined })}
                />
            </CreateSomethingWrapper>
        );
    };

    __renderForm = () => {
        return (
            <form>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {/* <Grid item xs={12} sm={6}>
						<TextField
							id="patientNumber"
							label="Numéro de patient"
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
							variant="outlined"
							value={this.state.record?.patientNumber}
							onChange={(e) => this.__onChange("patientNumber", e.target.value)}
							/>
					</Grid>
					<Grid sm={6} xs={12}/> */}

                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={
                                this.state.fieldErrors?.firstname !== undefined
                            }
                            helperText={this.state.fieldErrors?.firstname}
                            id="firstname"
                            label={this.props.t('generic.label.firstname')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            value={this.state.record?.firstname}
                            onChange={(e) =>
                                this.__onChangeFirstName(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={
                                this.state.fieldErrors?.lastname !== undefined
                            }
                            helperText={this.state.fieldErrors?.lastname}
                            id="lastname"
                            label={this.props.t('generic.label.lastname')}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.record?.lastname}
                            onChange={(e) =>
                                this.__onChangeLastName(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={
                                this.state.fieldErrors?.pathologie !== undefined
                            }
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="pathologie-native-simple"
                            >
                                {this.props.t('pr.label.pathology')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.record?.pathologie ?? ''}
                                onChange={(e) =>
                                    this.__onChangePathologie(e.target.value)
                                }
                                inputProps={{ id: 'pathologie-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'pr.label.pathology'
                                        )}
                                    />
                                }
                            >
                                {getEnumKeys(PatientRecordPathology).map(
                                    (enumKey) => (
                                        <MenuItem
                                            key={enumKey}
                                            value={
                                                PatientRecordPathology[enumKey]
                                            }
                                        >
                                            {enumKey}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.pathologie}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={this.state.fieldErrors?.target !== undefined}
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="target-native-simple"
                            >
                                {this.props.t('pr.label.target')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.record?.target ?? ''}
                                onChange={(e) =>
                                    this.__onChangeTarget(e.target.value)
                                }
                                inputProps={{ id: 'target-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('pr.label.target')}
                                    />
                                }
                            >
                                {this.state.record?.pathologie ===
                                    PatientRecordPathology.Parkinson && (
                                    <MenuItem value={PatientRecordTarget.Stn}>
                                        {this.props.t(PatientRecordTarget.Stn)}
                                    </MenuItem>
                                )}
                                <MenuItem value={PatientRecordTarget.Vim}>
                                    {this.props.t(PatientRecordTarget.Vim)}
                                </MenuItem>
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.target}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={this.state.fieldErrors?.side !== undefined}
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="side-native-simple"
                            >
                                {this.props.t('pr.label.side')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.record?.side ?? ''}
                                onChange={(e) =>
                                    this.__onChangeSide(e.target.value)
                                }
                                inputProps={{ id: 'side-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('pr.label.side')}
                                    />
                                }
                            >
                                {getEnumKeys(PatientRecordSide).map(
                                    (enumKey) => (
                                        <MenuItem
                                            key={enumKey}
                                            value={PatientRecordSide[enumKey]}
                                        >
                                            {enumKey}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.side}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={
                                this.state.fieldErrors?.typeOfSurgery !==
                                undefined
                            }
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="typeOfSurgery-native-simple"
                            >
                                {this.props.t('pr.label.surgeryType')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.record?.typeOfSurgery ?? ''}
                                onChange={(e) =>
                                    this.__onChangeTypeOfSurgery(e.target.value)
                                }
                                inputProps={{
                                    id: 'typeOfSurgery-native-simple',
                                }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'pr.label.surgeryType'
                                        )}
                                    />
                                }
                            >
                                {getEnumKeys(PatientRecordTypeOfSurgery).map(
                                    (enumKey) => (
                                        <MenuItem
                                            key={enumKey}
                                            value={
                                                PatientRecordTypeOfSurgery[
                                                    enumKey
                                                ]
                                            }
                                        >
                                            {enumKey}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.typeOfSurgery}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {/* Pre-op MRI */}
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {this.props.t('pr.label.origMRI')}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonGroup variant="contained" color="secondary">
                                <Button
                                    startIcon={<AddCircleIcon />}
                                    // component="span"
                                    onClick={() =>
                                        this._fileInput &&
                                        this._fileInput.click()
                                    }
                                >
                                    <input
                                        ref={this.setFileInput}
                                        onChange={(e) =>
                                            this.__onUploadNoTargetedIrm(
                                                Array.prototype.slice.call(
                                                    e.target.files
                                                )
                                            )
                                        }
                                        multiple
                                        type="file"
                                        hidden
                                    />
                                    <Typography variant="caption">
                                        {this.props.t('pr.button.newFile')}
                                    </Typography>
                                </Button>
                                <Button
                                    onClick={() =>
                                        this._folderInput &&
                                        this._folderInput.click()
                                    }
                                >
                                    <input
                                        ref={this.setFolderInput}
                                        onChange={(e) =>
                                            this.__onUploadNoTargetedIrm(
                                                Array.prototype.slice.call(
                                                    e.target.files
                                                )
                                            )
                                        }
                                        multiple
                                        type="file"
                                        hidden
                                    />
                                    <Typography variant="caption">
                                        {this.props.t('pr.button.folder')}
                                    </Typography>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<DescriptionIcon />}
                                // href={`${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${record._id}`}
                                variant="contained"
                                color="secondary"
                                onClick={this.__onClickShowIrm}
                                disabled={!this.state.newNoTargetedIrm}
                            >
                                <Typography variant="caption">
                                    {this.props.t('pr.showFiles.button')}
                                </Typography>
                            </Button>
                        </Grid>
                        {this.state.newNoTargetedIrm &&
                            this.state.newNoTargetedIrm
                                .map((a) => a.size)
                                .reduce((a, b) => a + b, 0) >
                                Number(
                                    process.env.REACT_APP_MAX_UPLOAD_SIZE
                                ) && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        {this.props.t('pr.msg.sizeError')}.
                                    </Alert>
                                </Grid>
                            )}
                    </Grid>

                    {/* Post-op MRI */}
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {this.props.t('pr.label.postOpImg')}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonGroup variant="contained" color="secondary">
                                <Button
                                    startIcon={<AddCircleIcon />}
                                    // component="span"
                                    onClick={() =>
                                        this._filePostOpInput &&
                                        this._filePostOpInput.click()
                                    }
                                    disabled={
                                        !this.state.original?.hasPostOpIrm ||
                                        !this.state.original?.status ||
                                        ![
                                            PatientRecordStatus.GiveUp,
                                            PatientRecordStatus.PostopToProcess,
                                            PatientRecordStatus.PreopMriMarked,
                                        ].includes(this.state.original?.status)
                                    }
                                >
                                    <input
                                        ref={this.setFilePostOpInput}
                                        onChange={(e) =>
                                            this.__onUploadPostOpIrm(
                                                Array.prototype.slice.call(
                                                    e.target.files
                                                )
                                            )
                                        }
                                        multiple
                                        type="file"
                                        hidden
                                    />
                                    <Typography variant="caption">
                                        {this.props.t('pr.button.newFile')}
                                    </Typography>
                                </Button>
                                <Button
                                    onClick={() =>
                                        this._folderPostOpInput &&
                                        this._folderPostOpInput.click()
                                    }
                                    disabled={
                                        !this.state.original?.hasPostOpIrm ||
                                        !this.state.original?.status ||
                                        ![
                                            PatientRecordStatus.GiveUp,
                                            PatientRecordStatus.PostopToProcess,
                                            PatientRecordStatus.PreopMriMarked,
                                        ].includes(this.state.original?.status)
                                    }
                                >
                                    <input
                                        ref={this.setFolderPostOpInput}
                                        onChange={(e) =>
                                            this.__onUploadPostOpIrm(
                                                Array.prototype.slice.call(
                                                    e.target.files
                                                )
                                            )
                                        }
                                        multiple
                                        type="file"
                                        hidden
                                    />
                                    <Typography variant="caption">
                                        {this.props.t('pr.button.folder')}
                                    </Typography>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<DescriptionIcon />}
                                // href={`${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${record._id}`}
                                variant="contained"
                                color="secondary"
                                onClick={this.__onClickShowPostOpIrm}
                                disabled={!this.state.newPostOpIrm}
                            >
                                <Typography variant="caption">
                                    {this.props.t('pr.showFiles.button')}
                                </Typography>
                            </Button>
                        </Grid>
                        {this.state.newPostOpIrm &&
                        this.state.newPostOpIrm
                            .map((a) => a.size)
                            .reduce((a, b) => a + b, 0) >
                            Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) ? (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {this.props.t('pr.msg.sizeError')}.
                                </Alert>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    {this.props.t('pr.msg.sizeLimit')}.
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </form>
        );
    };

    __onChangeFirstName = (firstName: string) => {
        if (this.state.record && this.state.record._id) {
            this.setState({
                record: {
                    ...this.state.record,
                    firstname: firstName,
                },
            });
        }
    };

    __onChangeLastName = (lastName: string) => {
        if (this.state.record && this.state.record._id) {
            this.setState({
                record: {
                    ...this.state.record,
                    lastname: lastName,
                },
            });
        }
    };

    __onChangePathologie = (pathologie: string | null) => {
        if (!isValidPathologie(pathologie)) {
            throw TypeError(
                `Invalid pathologie ${pathologie}, accepted values ${Object.entries(
                    PatientRecordPathology
                )}`
            );
        }
        if (this.state.record) {
            const target = specialCaseTarget(
                this.state.record?.target,
                pathologie
            );
            this.setState({
                record: {
                    ...this.state.record,
                    pathologie,
                    target,
                },
            });
        }
    };

    __onChangeTarget = (target: string | null) => {
        if (!isValidTarget(target)) {
            throw TypeError(
                `Invalid target ${target}, accepted values ${Object.entries(
                    PatientRecordPathology
                )}`
            );
        }
        if (this.state.record) {
            const specialTarget = specialCaseTarget(
                target,
                this.state.record.pathologie
            );
            this.setState({
                record: {
                    ...this.state.record,
                    target: specialTarget,
                },
            });
        }
    };

    __onChangeSide = (side: string | null) => {
        if (!isValidSide(side)) {
            throw TypeError(
                `Invalid pathologie ${side}, accepted values ${Object.entries(
                    PatientRecordSide
                )}`
            );
        }
        if (this.state.record && this.state.record._id) {
            this.setState({
                record: {
                    ...this.state.record,
                    side,
                },
            });
        }
    };

    __onChangeTypeOfSurgery = (typeOfSurgery: string | null) => {
        if (!isValidSurgeryType(typeOfSurgery)) {
            throw TypeError(
                `Invalid surgery type ${typeOfSurgery}, accepted values ${Object.entries(
                    PatientRecordTypeOfSurgery
                )}`
            );
        }
        if (this.state.record && this.state.record._id) {
            this.setState({
                record: {
                    ...this.state.record,
                    typeOfSurgery,
                },
            });
        }
    };

    __onClickShowIrm = () => {
        this.setState({
            fileList: {
                title: this.props.t('pr.edit.filesToSave'),
                files: this.state.newNoTargetedIrm?.map((f) => f.name),
            },
        });
    };

    __onClickShowPostOpIrm = () => {
        this.setState({
            fileList: {
                title: this.props.t('pr.edit.filesToSave'),
                files: this.state.newPostOpIrm?.map((f) => f.name),
            },
        });
    };

    __onUploadNoTargetedIrm = (acceptedFiles: File[]) => {
        this.setState({
            confirmation: {
                // title: this.props.t('pr.edit.filesToSave')
                message: `${this.props.t('pr.edit.checkAnon')}.`,
                onClickAccept: () => {
                    this.setState({ confirmation: undefined });
                    if (acceptedFiles.length >= 1) {
                        this.setState({ newNoTargetedIrm: acceptedFiles });
                    }
                },
                onClickCancel: () => this.setState({ confirmation: undefined }),
            },
        });
    };

    __onUploadPostOpIrm = (acceptedFiles: File[]) => {
        this.setState({
            confirmation: {
                // title: this.props.t('pr.edit.filesToSave')
                message: `${this.props.t('pr.edit.checkAnon')}.`,
                onClickAccept: () => {
                    this.setState({ confirmation: undefined });
                    if (acceptedFiles.length >= 1) {
                        this.setState({ newPostOpIrm: acceptedFiles });
                    }
                },
                onClickCancel: () => this.setState({ confirmation: undefined }),
            },
        });
    };

    __onPressCancel = () => {
        this.props.history.goBack();
    };
    __onPressCreate = async () => {
        if (!this.state.record) return;
        const patientRecord = this.state.record;
        const files = this.state.newNoTargetedIrm;
        const postOpFiles = this.state.newPostOpIrm;
        const errors: { [key: string]: string } = {};

        //Check fields
        if (!patientRecord.firstname || patientRecord.firstname.length <= 0)
            errors.firstname = this.props.t('generic.msg.checkFirstname');
        if (!patientRecord.lastname || patientRecord.lastname.length <= 0)
            errors.lastname = this.props.t('generic.msg.checkLastname');

        if (!patientRecord.target || patientRecord.target.trim().length === 0)
            errors.target = this.props.t('pr.edit.checkTarget');
        if (
            !patientRecord.pathologie ||
            patientRecord.pathologie.trim().length === 0
        )
            errors.pathologie = this.props.t('pr.edit.checkPathology');
        if (!patientRecord.side || patientRecord.side.trim().length === 0)
            errors.side = this.props.t('pr.edit.checkSide');
        if (
            !patientRecord.typeOfSurgery ||
            patientRecord.typeOfSurgery.trim().length === 0
        )
            errors.typeOfSurgery = this.props.t('pr.edit.checkSurgery');

        if (Object.keys(errors).length > 0)
            return this.setState({ fieldErrors: errors });

        if (
            files &&
            files.map((a) => a.size).reduce((a, b) => a + b, 0) >
                Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)
        ) {
            return this.setState({
                fieldErrors: undefined,
                error: {
                    title: this.props.t('generic.msg.error'),
                    message: `${this.props.t('pr.msg.sizeError')} !`,
                },
            });
        }

        if (
            postOpFiles &&
            postOpFiles.map((a) => a.size).reduce((a, b) => a + b, 0) >
                Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)
        ) {
            return this.setState({
                fieldErrors: undefined,
                error: {
                    title: this.props.t('generic.msg.error'),
                    message: `${this.props.t('pr.msg.sizeError')} !`,
                },
            });
        }
        this.setState({
            fieldErrors: undefined,
            information: {
                title: this.props.t('generic.msg.updating'),
                showProgress: true,
                message: `${this.props.t('generic.msg.updating')}...`,
            },
        });

        const zipPreOp = async () => {
            const zip = new JSZip();
            if (!files) return undefined;

            const isZip =
                files.length === 1 &&
                files
                    .map((f) => path.extname(f.name) === '.zip')
                    .reduce((a, b) => a && b, true);

            if (isZip) {
                return files[0];
            } else {
                files.forEach((file) => {
                    zip.file(file.name, file);
                });
                return await zip.generateAsync({ type: 'blob' });
            }
        };

        const zipPostOp = async () => {
            const zip = new JSZip();
            if (!postOpFiles) return undefined;

            const isZip =
                postOpFiles.length === 1 &&
                postOpFiles
                    .map((f) => path.extname(f.name) === '.zip')
                    .reduce((a, b) => a && b, true);

            if (isZip) {
                return postOpFiles[0];
            } else {
                postOpFiles.forEach((file) => {
                    zip.file(file.name, file);
                });
                console.debug(postOpFiles);
                return await zip.generateAsync({ type: 'blob' });
            }
        };

        const push = async () => {
            this.__update(patientRecord, await zipPreOp(), await zipPostOp());
        };
        return push();
    };

    __update = (
        record: PatientRecordUpdateSchema,
        irm?: unknown,
        postOpIrm?: unknown
    ) => {
        PatientRecordRequests.updatePatientRecord(record, irm, postOpIrm)
            .then((_) => {
                this.setState({
                    information: {
                        title: `${this.props.t('generic.msg.success')} !`,
                        message: `${this.props.t('pr.edit.updated')}.`,
                        onClose: () => this.props.history.goBack(),
                    },
                });
                setTimeout(this.props.history.goBack, 1500);
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            });
    };

    __loadOriginRecord = async (idRecord: string) => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                message: this.props.t('pr.msg.downloading'),
                showProgress: true,
            },
        });

        PatientRecordRequests.patientRecordWithFieldForUpdate(
            idRecord,
            'no-cache'
        )
            .then((record) => {
                this.setState({
                    information: undefined,
                    record: omit(record, ['hasPostOpIrm']),
                    original: record,
                });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.loadingError'),
                        message: err.message,
                    },
                });
            });
    };
}

// TODO? We have no idea why this was in the code, but we kept it even though it looks suspicious
// TODO The exact same code lies in `createPatientRecordPage` it could be shared
function specialCaseTarget(
    target: PatientRecordTarget | undefined | null,
    pathologie: PatientRecordPathology | undefined | null
) {
    let specialTarget = target;
    if (
        pathologie === PatientRecordPathology.EssentialTremor &&
        target !== PatientRecordTarget.Vim
    ) {
        specialTarget = '' as PatientRecordTarget;
    }
    return specialTarget;
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(UpdatePatientRecordPage)))
);
