import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Select,
    Theme,
    Typography,
} from '@mui/material';
import ConfirmationDialog, {
    ConfirmationInformation,
} from '../../../components/confirmationDialog';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import FileListDialog, {
    FileListDialogProps,
} from '../../../components/FileListDialog';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import {
    MeQuery,
    PatientRecordQuery,
    PatientRecordStatus,
    PatientRecordTargetingStatus,
    Roles,
} from '__generated__/graphql';
import { PatientRecordRequests, UserRequests } from '../../../apollo';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { API_ENDPOINT } from 'config';
import Alert from '@mui/material/Alert';
import { AutomatonSection } from '../../../components/automaton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatToolbox from '../../../toolbox/FormatToolbox';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { GraphQLError } from 'graphql';
import JSZip from 'jszip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PatientRecordsRouter } from '../../../router/dashboard/patientRecords/patientRecords.router';
import { UpdatePatientRecordRouter } from '../../../router/dashboard/updatePatientRecord/updatePatientRecord.router';
import { UploadButtonGroup } from 'components/UploadButtonGroup';
import { makeStyles } from '@mui/styles';
import path from 'path';
import useSnackbar from '../../../hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette }: Theme) => ({
    circle: {
        display: 'inline-block',
        backgroundColor: palette.primary.main,
        borderRadius: '50%',
        width: 5,
        height: 5,
    },
}));

type Anchor = EventTarget & HTMLButtonElement;

const PatientRecordPage = () => {
    // Hooks
    const params = useParams<{ id: string }>();
    const classes = useStyles();
    const history = useHistory();
    const { openInformativeSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();
    const { t } = useTranslation();

    /** States */
    const [user, setUser] = useState<MeQuery['me']>();
    const { data, refetch } = PatientRecordRequests.usePatientRecord(params.id);
    const record = data?.patientRecord;
    const [error, setError] = useState<IErrorInformation>();
    const [information, setInformation] = useState<IInformative>();
    const [confirmation, setConfirmation] = useState<ConfirmationInformation>();
    const [newTargetedIrm, setNewTargetedIrm] = useState<Array<File>>();
    const [anchorMenu, setAnchorMenu] = useState<Anchor>();
    // post op
    const [newPostOpIrm, setNewPostOpIrm] = useState<Array<File>>();
    const [newTargetedPostOpIrm, setNewTargetedPostOpIrm] =
        useState<Array<File>>();

    // States to show files in dialog
    const [fileListProps, setFileListProps] =
        useState<FileListDialogProps>(); /** Use to render dialog with file list */
    const [fileListTargetedProps, setFileListTargetedProps] =
        useState<FileListDialogProps>(); /** Use to render dialog with file list */

    const isOperator =
        user != null &&
        [Roles.Admin, Roles.Operator].includes(user.role as Roles);

    const isClinician = user && [Roles.Clinician].includes(user.role);

    // Effects
    useEffect(() => {
        setInformation({
            title: formatMessage({ id: 'loading' }),
            showProgress: true,
        });
        __loadCurrentUser();
        __loadPatientRecord(params.id);
    }, [params]);
    // Methods

    const __renderContent = () => {
        if (!record) return null;
        return (
            <Grid container>
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.creationDate')}:
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography variant="body1">
                            {record.creationDate && (
                                <FormattedDate
                                    day="2-digit"
                                    month="long"
                                    year="numeric"
                                    value={record.creationDate}
                                />
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.status')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        {record && isOperator ? (
                            <Grid item>
                                <Select
                                    size="small"
                                    value={record?.status}
                                    onChange={(e) =>
                                        __updateStatus(
                                            e.target
                                                .value as PatientRecordStatus
                                        )
                                    }
                                >
                                    {Object.values(PatientRecordStatus).map(
                                        (value) => (
                                            <MenuItem key={value} value={value}>
                                                <FormattedMessage
                                                    id={FormatToolbox.patientRecordEnumToString(
                                                        value
                                                    )}
                                                />
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </Grid>
                        ) : (
                            <Typography variant="body1">
                                <FormattedMessage
                                    id={FormatToolbox.patientRecordEnumToString(
                                        record.status
                                    )}
                                />
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                {record.status === PatientRecordStatus.GiveUp && (
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={3}
                        alignItems="center"
                    >
                        <Grid item sm={5}>
                            <Typography variant="h6">
                                {t('pr.label.rejectReason')}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                {record.giveUpReason}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {user?.role === Roles.Clinician
                                ? t('generic.label.name')
                                : t('pr.label.ownerName')}
                            :
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {user?.role === Roles.Clinician
                                ? `${record.firstname} ${record.lastname}`
                                : `${record.clinician?.firstname} ${record
                                      .clinician?.lastname} ${
                                      record.clinician &&
                                      record.clinician.hospitalCenter
                                          ? `(${record.clinician.hospitalCenter?.name})`
                                          : ''
                                  }`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.pathology')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {record.pathologie && t(record.pathologie)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.target')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {record.target && t(record.target)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.side')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {record.side && t(record.side)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.surgeryType')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {record.typeOfSurgery && t(record.typeOfSurgery)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.processingDate')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {record.treatmentDate ? (
                                <FormattedDate
                                    value={record.treatmentDate}
                                    day="numeric"
                                    month="long"
                                    year="numeric"
                                />
                            ) : (
                                '-'
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3} alignItems="center">
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.downloadDate')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {record.clinicianDowloadedDate ? (
                                <FormattedDate
                                    value={record.clinicianDowloadedDate}
                                    day="numeric"
                                    month="long"
                                    year="numeric"
                                />
                            ) : (
                                '-'
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    function isMriUploadDisabled(record: PatientRecordQuery['patientRecord']) {
        if (
            ![
                PatientRecordStatus.InProgress,
                PatientRecordStatus.ToDo,
            ].includes(record?.status)
        ) {
            return true;
        }
        if (record.hasPostOpIrm || record.hasTargetedPostOpIrm) {
            return true;
        }
        if (
            [
                PatientRecordTargetingStatus.ReportGenerated,
                PatientRecordTargetingStatus.ApprovedByOperator,
            ].includes(record.targetingStatus)
        ) {
            return true;
        }
        return isAutomatonRunningOn(record);
    }

    function isAutomatonRunningOn(record: PatientRecordQuery['patientRecord']) {
        if (
            [
                PatientRecordTargetingStatus.Submitted,
                PatientRecordTargetingStatus.ValidFormat,
                PatientRecordTargetingStatus.ImageGenerated,
            ].includes(record.targetingStatus)
        ) {
            return true;
        }
        return false;
    }

    const __renderPreOperativeMRI = () => {
        if (!record) return null;
        return (
            <Grid item container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {t('pr.section.preop')}
                    </Typography>
                </Grid>
                {/* IRM Non ciblé */}
                <Grid item xs={12} container spacing={2}>
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.origMRI')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={__onClickShowOriginalIrmFiles}
                            variant="contained"
                            color="secondary"
                            startIcon={<DescriptionIcon />}
                            disabled={!record.hasOriginalIrm}
                        >
                            <Typography variant="caption">
                                {t('pr.showFiles.button')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>

                {isOperator && (
                    <>
                        <AutomatonSection recordId={record?._id} />
                        <Grid item xs={12}>
                            <Typography variant="h5" color="primary">
                                {t('pr.section.manual')}
                            </Typography>
                        </Grid>
                    </>
                )}

                {/* IRM ciblé */}
                <Grid item xs={12} container spacing={2}>
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.targMRI')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            startIcon={<DescriptionIcon />}
                            variant="contained"
                            color="secondary"
                            onClick={__onClickShowTargetedIrmFiles}
                            disabled={
                                !record.hasTargetedIrm ||
                                (isOperator &&
                                    record.targetingStatus !=
                                        PatientRecordTargetingStatus.OperatedManually) ||
                                (!isOperator &&
                                    record.status == PatientRecordStatus.ToDo)
                            }
                        >
                            <Typography variant="caption">
                                {t('pr.showFiles.button')}
                            </Typography>
                        </Button>
                    </Grid>
                    {isOperator && (
                        <>
                            <Grid item>
                                <UploadButtonGroup
                                    disabled={isMriUploadDisabled(record)}
                                    onUpload={__onUploadTargetedIrm}
                                />
                            </Grid>
                            <Grid item>
                                {isAutomatonRunningOn(record) && (
                                    <Typography>
                                        {t(
                                            'automaton.disabled MRI reasons.Automaton is running'
                                        )}
                                    </Typography>
                                )}
                            </Grid>
                        </>
                    )}
                    {newTargetedIrm &&
                        newTargetedIrm
                            .map((a) => a.size)
                            .reduce((a, b) => a + b, 0) >
                            Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {t('pr.msg.sizeLimit')}.
                                </Alert>
                            </Grid>
                        )}
                    <Grid item xs={12}>
                        {__renderFileList(
                            newTargetedIrm,
                            __onClickSaveNewTargetedFile
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const __renderPostOperativeMRI = () => {
        if (!record) return null;
        return (
            <Grid item container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {t('pr.section.postop')}
                    </Typography>
                </Grid>
                {/* IRM Non ciblé */}
                <Grid item xs={12} container spacing={2}>
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.postOpImg')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={__onClickShowPostOpIrmFiles}
                            variant="contained"
                            color="secondary"
                            startIcon={<DescriptionIcon />}
                            disabled={!record.hasPostOpIrm}
                        >
                            <Typography variant="caption">
                                {t('pr.showFiles.button')}
                            </Typography>
                        </Button>
                    </Grid>
                    {isClinician && (
                        <Grid item>
                            <UploadButtonGroup
                                onUpload={__onUploadPostOpIrm}
                                disabled={
                                    ![
                                        PatientRecordStatus.PreopMriMarked,
                                        PatientRecordStatus.Downloaded,
                                    ].includes(record.status)
                                }
                            />
                        </Grid>
                    )}
                    {newPostOpIrm &&
                        newPostOpIrm
                            .map((a) => a.size)
                            .reduce((a, b) => a + b, 0) >
                            Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {t('pr.msg.sizeLimit')}.
                                </Alert>
                            </Grid>
                        )}
                    <Grid item xs={12}>
                        {__renderFileList(newPostOpIrm, (files) =>
                            __onClickSaveNewFile(files, 'postop').then(() => {
                                setNewPostOpIrm(undefined);
                                __loadPatientRecord(record!._id);
                            })
                        )}
                    </Grid>
                </Grid>

                {/* IRM ciblé */}
                <Grid item xs={12} container spacing={2}>
                    <Grid item sm={5}>
                        <Typography variant="h6">
                            {t('pr.label.postOpChk')}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            startIcon={<DescriptionIcon />}
                            variant="contained"
                            color="secondary"
                            onClick={__onClickShowTargetedPostOpIrmFiles}
                            disabled={!record.hasTargetedPostOpIrm}
                        >
                            <Typography variant="caption">
                                {t('pr.showFiles.button')}
                            </Typography>
                        </Button>
                    </Grid>

                    {isOperator && (
                        <Grid item>
                            <UploadButtonGroup
                                onUpload={__onUploadTargetedPostOpIrm}
                            />
                        </Grid>
                    )}
                    {newTargetedPostOpIrm &&
                        newTargetedPostOpIrm
                            .map((a) => a.size)
                            .reduce((a, b) => a + b, 0) >
                            Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {t('pr.msg.sizeLimit')}.
                                </Alert>
                            </Grid>
                        )}
                    <Grid item xs={12}>
                        {__renderFileList(newTargetedPostOpIrm, (files) =>
                            __onClickSaveNewFile(files, 'targeted-postop').then(
                                () => {
                                    setNewTargetedPostOpIrm(undefined);
                                    __loadPatientRecord(record!._id);
                                }
                            )
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const __renderPreOpMRIProcess = () => {
        if (
            !record?.hasTargetedIrm ||
            user?.role === Roles.Clinician ||
            ![
                PatientRecordStatus.InProgress,
                PatientRecordStatus.ToDo,
            ].includes(record?.status) ||
            record.hasPostOpIrm ||
            newTargetedIrm
        )
            // if (!record || !record.hasTargetedIrm || !user || user.role === Roles.CLINICIAN)
            return null;

        return (
            <Grid item xs={12} container>
                <Button
                    startIcon={<CheckCircleOutlineIcon />}
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                        setConfirmation({
                            title: t('pr.button.preOpValidate'),
                            message: `${t('pr.msg.preOpConfirm')} ${t(
                                'pr.msg.clinicianNotified'
                            )}.`,
                            onClickAccept: () => {
                                setConfirmation(undefined);
                                __onClickSendEndOfPreOp();
                            },
                            onClickCancel: () => setConfirmation(undefined),
                        })
                    }
                    disabled={
                        record.targetingStatus !=
                        PatientRecordTargetingStatus.OperatedManually
                    }
                >
                    {t('pr.button.preOpValidate')}
                </Button>
            </Grid>
        );
    };

    const __renderPostOpMRIProcess = () => {
        if (
            !record?.hasTargetedPostOpIrm ||
            user?.role === Roles.Clinician ||
            ![
                PatientRecordStatus.PostopToProcess,
                PatientRecordStatus.InProgress,
            ].includes(record?.status) ||
            newTargetedPostOpIrm
        )
            return null;

        return (
            <Grid item xs={12} container>
                <Button
                    startIcon={<CheckCircleOutlineIcon />}
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                        setConfirmation({
                            title: t('pr.button.postOpValidate'),
                            message: `${t('pr.msg.postOpConfirm')} ${t(
                                'pr.msg.clinicianNotified'
                            )}.`,
                            onClickAccept: () => {
                                setConfirmation(undefined);
                                __onClickSendEndOfPostOp();
                            },
                            onClickCancel: () => setConfirmation(undefined),
                        })
                    }
                >
                    {t('pr.button.postOpValidate')}
                </Button>
            </Grid>
        );
    };
    const __renderFileList = (
        files?: Array<File>,
        onSave?: (files: Array<File>) => void
    ) => {
        if (!files) return null;
        const f = files?.map((file) => {
            return (
                <Grid item xs={12} container spacing={2} key={file.name}>
                    <Grid item>
                        <Box className={classes.circle} />
                    </Grid>
                    <Grid item>
                        <Typography>{file.name}</Typography>
                    </Grid>
                </Grid>
            );
        });

        return (
            <Grid container>
                {f}
                <Button
                    startIcon={<CloudUploadIcon />}
                    variant="contained"
                    color="secondary"
                    onClick={() => onSave && onSave(files)}
                >
                    <Typography>{t('generic.button.save')}</Typography>
                </Button>
            </Grid>
        );
    };

    const __renderTitle = () => {
        return (
            <Grid
                item
                xs={12}
                container
                component={Box}
                px={2}
                py={1}
                justifyContent="space-between"
            >
                <Grid item>
                    <Typography variant="h3" color="primary">
                        {t('pr.section.title')}{' '}
                        {record ? record.patientNumber : undefined}
                    </Typography>
                </Grid>
                {user && (
                    // user.role !== Roles.OPERATOR &&
                    <Grid item>
                        <IconButton
                            onClick={(e) => setAnchorMenu(e.currentTarget)}
                            size="large"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorMenu}
                            keepMounted
                            open={anchorMenu !== undefined}
                            onClose={() => setAnchorMenu(undefined)}
                            onClick={() => setAnchorMenu(undefined)}
                        >
                            {user.role === Roles.Clinician && (
                                <MenuItem
                                    onClick={() => __onClickUpdateRecord()}
                                    disabled={
                                        record?.hasPostOpIrm ||
                                        record?.hasTargetedPostOpIrm
                                    }
                                >
                                    {t('generic.button.update')}
                                </MenuItem>
                            )}
                            {[
                                Roles.Clinician,
                                Roles.Admin,
                                Roles.Operator,
                            ].includes(user.role) && (
                                <MenuItem
                                    onClick={() =>
                                        setConfirmation({
                                            title: t('generic.msg.deleting'),
                                            message: t('pr.msg.deleteConfirm'),
                                            onClickAccept: () => {
                                                setConfirmation(undefined);
                                                __onClickRemoveRecord();
                                            },
                                            onClickCancel: () =>
                                                setConfirmation(undefined),
                                        })
                                    }
                                >
                                    {t('generic.button.delete')}
                                </MenuItem>
                            )}

                            {user.role !== Roles.Clinician && (
                                <MenuItem onClick={__onClickRecordReject}>
                                    {t('pr.reject.title')}
                                </MenuItem>
                            )}
                        </Menu>
                    </Grid>
                )}
            </Grid>
        );
    };

    const __loadCurrentUser = () => {
        UserRequests.meBasic()
            .then((user) => {
                setUser(user);
            })
            .catch(() => undefined);
    };
    const __loadPatientRecord = async (id: string) => {
        setInformation({
            title: t('generic.msg.loading'),
            showProgress: true,
        });
        try {
            await refetch();
        } catch (err) {
            if (err instanceof GraphQLError) {
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            }
        } finally {
            setInformation(undefined);
        }
    };

    // __onUploadNoTargetedIrm = (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
    // 	if (acceptedFiles.length >= 1) {
    // 		setState({ newNoTargetedIrm: acceptedFiles })
    // 	}
    // }

    const __onUploadTargetedIrm = (acceptedFiles: File[]) => {
        setNewTargetedIrm(acceptedFiles);
    };

    const __onUploadTargetedPostOpIrm = (acceptedFiles: File[]) => {
        setNewTargetedPostOpIrm(acceptedFiles);
    };

    const __onUploadPostOpIrm = (acceptedFiles: File[]) => {
        setNewPostOpIrm(acceptedFiles);
    };

    const __onClickSaveNewTargetedFile = (files: Array<File>) => {
        if (
            files &&
            files.map((a) => a.size).reduce((a, b) => a + b, 0) >
                Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)
        )
            return setError({
                title: t('generic.msg.error'),
                message: `${t('pr.msg.sizeError')} !`,
            });

        const zip = new JSZip();

        setInformation({
            title: t('generic.msg.uploading'),
            message: t('pr.edit.savingFiles'),
            showProgress: true,
        });

        const push = async () => {
            const isZip =
                files.length === 1 &&
                files
                    .map((f) => path.extname(f.name) === '.zip')
                    .reduce((a, b) => a && b, true);
            let file;

            if (isZip) {
                file = files[0];
            } else {
                files.forEach((file) => {
                    zip.file(file.name, file);
                });
                file = await zip.generateAsync({ type: 'blob' });
            }

            await PatientRecordRequests.setTargetedIrm(record!._id, file);
        };

        push()
            .then(() => {
                setInformation(undefined);
                setNewTargetedIrm(undefined);
                __loadPatientRecord(record!._id);
            })
            .catch((err) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            })
            .finally(() => {
                setInformation(undefined);
            });
    };

    const methods = {
        targeted: PatientRecordRequests.setTargetedIrm,
        original: PatientRecordRequests.setOriginalIrm,
        postop: PatientRecordRequests.setPostOpIrm,
        'targeted-postop': PatientRecordRequests.setTargetedPostOpIrm,
    } as const;

    const __onClickSaveNewFile = async (
        files: Array<File>,
        method: keyof typeof methods
    ): Promise<void> => {
        if (!Object.keys(methods).includes(method)) {
            throw new Error('Unknown method to save files');
        }

        if (
            files &&
            files.map((a) => a.size).reduce((a, b) => a + b, 0) >
                Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)
        ) {
            return setError({
                title: t('generic.msg.error'),
                message: `${t('pr.msg.sizeError')} !`,
            });
        }

        const zip = new JSZip();

        setInformation({
            title: t('generic.msg.uploading'),
            message: t('pr.edit.savingFiles'),
            showProgress: true,
        });

        const push = async () => {
            const isZip =
                files.length === 1 &&
                files
                    .map((f) => path.extname(f.name) === '.zip')
                    .reduce((a, b) => a && b, true);
            let file;

            if (isZip) {
                file = files[0];
            } else {
                files.forEach((file) => {
                    zip.file(file.name, file);
                });
                file = await zip.generateAsync({ type: 'blob' });
            }

            await methods[method](record!._id, file);
        };

        return await push()
            .then(() => {
                setInformation(undefined);
                // setNewTargetedIrm(undefined)
                // __loadPatientRecord(record!._id)
            })
            .catch((err) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            })
            .finally(() => {
                setInformation(undefined);
            });
    };

    const __updateStatus = async (status: PatientRecordStatus) => {
        if (status === PatientRecordStatus.GiveUp)
            return __onClickRecordReject();

        if (record && user) {
            await PatientRecordRequests.updatePatientRecord({
                _id: record._id,
                status,
            });
            try {
                await refetch();
            } catch (err) {
                if (err instanceof GraphQLError) {
                    setInformation(undefined);
                    setError({
                        title: t('generic.msg.error'),
                        message: err.message,
                    });
                }
            }
        }
    };
    const __onClickRemoveRecord = () => {
        setInformation({
            title: t('generic.msg.deleting'),
            showProgress: true,
        });
        PatientRecordRequests.removePatientRecord(record!._id)
            .then(() => {
                history.push(PatientRecordsRouter.getRoute());
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };

    const __onClickDownloadTargeted = async (): Promise<void> => {
        if (
            user &&
            [Roles.Clinician].includes(user.role) &&
            record &&
            !record.clinicianDowloadedDate
        ) {
            try {
                const date = new Date();
                await PatientRecordRequests.updatePatientRecord({
                    _id: record._id,
                    clinicianDowloadedDate: date.toString(),
                    status: PatientRecordStatus.Downloaded,
                });
                await refetch();
            } catch (err) {
                console.error(err);
            }
        }
        openInformativeSnackbar({
            message: 'generic.msg.rgpd',
            icon: <GppGoodIcon />,
        });
    };

    const __onClickDownloadOriginal = async (): Promise<void> => {
        if (
            user &&
            [Roles.Admin, Roles.Operator].includes(user.role) &&
            record
        ) {
            try {
                await PatientRecordRequests.updatePatientRecord({
                    _id: record._id,
                    status: PatientRecordStatus.InProgress,
                });
                await refetch();
            } catch (err) {
                console.error(err);
            }
        }
        openInformativeSnackbar({
            message: 'generic.msg.rgpd',
            icon: <GppGoodIcon />,
        });
    };

    const __onClickRecordReject = () => {
        setConfirmation({
            title: t('pr.reject.title'),
            message: t('pr.reject.confirm'),
            placeholderReason: record?.giveUpReason,
            askReason: true,
            onClickAccept: (reason) => {
                setConfirmation(undefined);
                __onValidateRecordReject(reason);
            },
            onClickCancel: () => setConfirmation(undefined),
        });
    };
    const __onValidateRecordReject = (reasons?: string | undefined) => {
        if (!reasons || reasons.length === 0)
            return setError({
                title: t('generic.msg.error'),
                message: t('pr.reject.missingReason'),
                onClose: () => setError(undefined),
            });

        setInformation({
            title: t('pr.reject.inProgress'),
            showProgress: true,
        });
        PatientRecordRequests.rejectPatientRecord(record!._id, reasons)
            .then(() => {
                history.push(PatientRecordsRouter.getRoute());
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };

    const __onClickShowOriginalIrmFiles = () => {
        setInformation({
            title: t('generic.msg.loading'),
            showProgress: true,
        });
        PatientRecordRequests.downloadOriginalIrmFileNames(record!._id)
            .then((files: Array<string>) => {
                setFileListProps({
                    files: files,
                    title: t('pr.showFiles.origMRI'),
                    urlToDownload: `${API_ENDPOINT}/patient-record/original-irm/${
                        record!._id
                    }?log`,
                });
                setInformation(undefined);
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };

    const __onClickShowPostOpIrmFiles = () => {
        setInformation({
            title: t('generic.msg.loading'),
            showProgress: true,
        });
        PatientRecordRequests.downloadPostOpIrmFileNames(record!._id)
            .then((files: Array<string>) => {
                setFileListProps({
                    files: files,
                    title: t('pr.showFiles.postOpImg'),
                    urlToDownload: `${API_ENDPOINT}/patient-record/postop-original-irm/${
                        record!._id
                    }?log`,
                });
                setInformation(undefined);
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };

    const __onClickShowTargetedIrmFiles = () => {
        setInformation({
            title: t('generic.msg.loading'),
            showProgress: true,
        });
        PatientRecordRequests.downloadTargetedIrmFileNames(record!._id)
            .then((files: Array<string>) => {
                setFileListTargetedProps({
                    files: files,
                    title: t('pr.showFiles.targMRI'),
                    urlToDownload: `${API_ENDPOINT}/patient-record/targeted-irm/${
                        record!._id
                    }?log`,
                });
                setInformation(undefined);
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };

    const __onClickShowTargetedPostOpIrmFiles = () => {
        setInformation({
            title: t('generic.msg.loading'),
            showProgress: true,
        });
        PatientRecordRequests.downloadTargetedPostOpIrmFileNames(record!._id)
            .then((files: Array<string>) => {
                setFileListTargetedProps({
                    files: files,
                    title: t('pr.showFiles.postOpChk'),
                    urlToDownload: `${API_ENDPOINT}/patient-record/postop-targeted-irm/${
                        record!._id
                    }?log`,
                });
                setInformation(undefined);
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };
    const __onClickUpdateRecord = () => {
        history.push(UpdatePatientRecordRouter.getRoute(record?._id));
    };

    const __onClickSendEndOfPreOp = () => {
        setInformation({
            title: t('generic.msg.loading'),
            showProgress: true,
        });
        PatientRecordRequests.sendEndOfProcessingMail(record!._id)
            .then(() => {
                __loadPatientRecord(record!._id);
                setInformation({
                    title: t('pr.msg.mailSent'),
                    showProgress: false,
                    message: t('pr.msg.end'),
                    onClose: () => setInformation(undefined),
                });
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };

    const __onClickSendEndOfPostOp = () => {
        setInformation({
            title: t('generic.msg.loading'),
            showProgress: true,
        });
        PatientRecordRequests.sendEndOfPostOpProcessingMail(record!._id)
            .then(() => {
                __loadPatientRecord(record!._id);
                setInformation({
                    title: t('pr.msg.mailSent'),
                    showProgress: false,
                    message: t('pr.msg.end'),
                    onClose: () => setInformation(undefined),
                });
            })
            .catch((err: GraphQLError) => {
                setInformation(undefined);
                setError({
                    title: t('generic.msg.error'),
                    message: err.message,
                });
            });
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item sm={6} xs={11}>
                <Paper>
                    <Box m={3} p={1}>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                {__renderTitle()}
                            </Grid>
                            <Grid item xs={8} sm={4} component={Box}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                {__renderContent()}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                //justifyContent="flex-end"
                            >
                                <Grid item xs={8} sm={4}>
                                    <Divider />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                {__renderPreOperativeMRI()}
                            </Grid>
                            <Grid item xs={12}>
                                {__renderPreOpMRIProcess()}
                            </Grid>
                            <Grid item xs={8} sm={4} component={Box}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                {__renderPostOperativeMRI()}
                            </Grid>
                            <Grid item xs={12}>
                                {__renderPostOpMRIProcess()}
                            </Grid>
                            <Grid item xs={8} sm={4} component={Box}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <ErrorDialog error={error} onClose={() => setError(undefined)} />
            <InformativeDialog
                information={information}
                onClose={() => undefined}
            />
            <ConfirmationDialog
                confirmation={confirmation}
                onClose={() => setConfirmation(undefined)}
            />
            <FileListDialog
                onClickDownload={__onClickDownloadOriginal}
                fileListProps={fileListProps}
                onClose={() => setFileListProps(undefined)}
            />
            <FileListDialog
                onClickDownload={__onClickDownloadTargeted}
                fileListProps={fileListTargetedProps}
                onClose={() => setFileListTargetedProps(undefined)}
            />
        </Grid>
    );
};

export default PatientRecordPage;
