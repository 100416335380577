import { useMutation, useQuery } from '@apollo/client';

import { QUERY_REGIONS } from './RegionRequests';
import { gql } from '__generated__/gql';

export const MUTATION_CREATE_COMMERCIAL = gql(`
    mutation createCommercial(
        $commercialData: CommercialCreateSchema!
    ) {
        createCommercial(commercialData: $commercialData)
    }
`);

export const MUTATION_REMOVE_COMMERCIAL_FROM_REGION = gql(`
    mutation removeCommercialFromRegion(
        $associationData: CommercialRemoveFromRegionSchema!
    ) {
        removeCommercialFromRegion(associationData: $associationData)
    }
`);

export const QUERY_ALL_COMMERCIALS = gql(`
    query commercials {
        commercials {
            email
            firstname
            lastname
        }
    }
`);

export const QUERY_FIND_COMMERCIALS_IN_REGION = gql(`
    query findCommercialsInRegion($regionId: Float!) {
        findCommercialsInRegion(regionId: $regionId) {
            email
            firstname
            lastname
            associationDate
        }
    }
`);

export const useCommercials = () => {
    return useQuery(QUERY_ALL_COMMERCIALS, {
        fetchPolicy: 'cache-first',
    });
};

export const useCommercialsInRegion = (regionId: number) => {
    return useQuery(QUERY_FIND_COMMERCIALS_IN_REGION, {
        variables: { regionId },
        fetchPolicy: 'cache-first',
    });
};

export const useCreateCommercial = (regionId: number | null | undefined) =>
    useMutation(MUTATION_CREATE_COMMERCIAL, {
        refetchQueries: [
            {
                query: QUERY_REGIONS,
            },
            {
                query: QUERY_FIND_COMMERCIALS_IN_REGION,
                variables: { regionId },
            },
        ],
    });

export const useRemoveCommercialFromRegion = (
    regionId: number | null | undefined
) =>
    useMutation(MUTATION_REMOVE_COMMERCIAL_FROM_REGION, {
        refetchQueries: [
            {
                query: QUERY_REGIONS,
            },
            {
                query: QUERY_FIND_COMMERCIALS_IN_REGION,
                variables: { regionId },
            },
        ],
    });
