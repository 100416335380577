import { FetchPolicy } from 'apollo-boost';
import { Client } from '.';
import { gql } from '__generated__/gql';
import {
    UserCreateSchema,
    UserLoginSchema,
    UserUpdateSchema,
} from '__generated__/graphql';

const MLogin = gql(`
    mutation login($authentication: UserLoginSchema!) {
        login(authentication: $authentication) {
            _id
            email
        }
    }
`);

const QBasicMe = gql(`
    query me {
        me {
            lastname
            email
            firstname
            role
        }
    }
`);

const MLogout = gql(`
    mutation logout {
        logout
    }
`);

const QUsers = gql(`
    query users {
        users {
            _id
            lastname
            firstname
            email
            role
            hospitalCenter {
                name
            }
            status
        }
    }
`);

const MUpdateUser = gql(`
    mutation updateUser($updateUser: UserUpdateSchema!) {
        updateUser(user: $updateUser) {
            _id
            firstname
            lastname
        }
    }
`);

const MCreateUser = gql(`
    mutation createUser($createUser: UserCreateSchema!) {
        createUser(user: $createUser) {
            _id
            email
        }
    }
`);

const MDeleteUser = gql(`
    mutation deleteUser($idUser: String!) {
        deleteUser(idUser: $idUser)
    }
`);

const QUser = gql(`
    query user($idUser: String!) {
        user(idUser: $idUser) {
            _id
            lastname
            firstname
            email
            role
            hospitalCenter {
                _id
            }
        }
    }
`);

const MResetPassword = gql(`
    mutation resetPassword($token: String!, $password: String!) {
        resetPassword(password: $password, token: $token)
    }
`);

const MForgottenPassword = gql(`
    mutation forgottenPassword($email: String!) {
        forgottenPassword(email: $email)
    }
`);
export class UserRequests {
    public static async createUser(createUser: UserCreateSchema) {
        return (
            await Client.mutate({
                mutation: MCreateUser,
                variables: {
                    createUser,
                },
            })
        ).data!.createUser;
    }

    public static async deleteUser(idUser: string) {
        return (
            await Client.mutate({
                mutation: MDeleteUser,
                variables: {
                    idUser,
                },
            })
        ).data!.deleteUser;
    }

    public static async login(authentication: UserLoginSchema) {
        return (
            await Client.mutate({
                mutation: MLogin,
                variables: {
                    authentication,
                },
            })
        ).data!.login;
    }

    public static async logout() {
        const l = (
            await Client.mutate({
                mutation: MLogout,
            })
        ).data!.logout;
        return l;
    }

    public static async forgottenPassword(mail: string) {
        return (
            await Client.mutate({
                mutation: MForgottenPassword,
                variables: {
                    email: mail,
                },
            })
        ).data!.forgottenPassword;
    }

    public static async resetPassword(token: string, password: string) {
        return (
            await Client.mutate({
                mutation: MResetPassword,
                variables: {
                    token,
                    password,
                },
            })
        ).data!.resetPassword;
    }

    /**
     * Get my basic information
     */
    public static async meBasic(fetchPolicy?: FetchPolicy) {
        return (
            await Client.query({
                query: QBasicMe,
                fetchPolicy,
            })
        ).data.me;
    }

    /**
     * Get all users
     */
    public static async users(fetchPolicy?: FetchPolicy) {
        return (
            await Client.query({
                query: QUsers,
                fetchPolicy,
            })
        ).data.users;
    }

    public static async user(idUser: string, fetchPolicy?: FetchPolicy) {
        return (
            await Client.query({
                query: QUser,
                variables: {
                    idUser,
                },
                fetchPolicy,
            })
        ).data.user;
    }

    public static async updateUser(updateUser: UserUpdateSchema) {
        return (
            await Client.mutate({
                mutation: MUpdateUser,
                variables: {
                    updateUser,
                },
            })
        ).data!.updateUser;
    }
}
