import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { DeleteButton } from 'components/buttons';
import ModificationDate from 'components/modificationDate';
import { useTranslation } from 'react-i18next';

type Region = {
    _id: number;
    name: string;
    nbHospitals: number;
    nbCommercials: number;
    creationDate: string;
    lastUpdate: string;
};

type RegionTableUiProps<R> = {
    regions: R[];
    onDeleteClicked?: (region: R) => void;
    onRowClicked?: (region: R) => void;
};

/** List of regions that offers a row clicked and delete button clicked callbacks.
 *
 * @param props.regions a list of regions to be displayed, each region should have at least a
 * `name`, `nbHospitals`, `nbCommercials`, `creationDate` and `lastUpdate` properties.
 * @param props.onRowClicked A callback that is called when a region ROW is clicked. The region is
 * passed as argument.
 * @param props.onDeleteClicked A callback that is called when the delete button is clicked. The region is
 * passed as argument.
 *
 * Notice that if the input regions contain additional properties, they will be passed down to
 * the callbacks.
 */
export function RegionTableUi<R extends Region>(props: RegionTableUiProps<R>) {
    const { t } = useTranslation();
    const hasClickAction = props.onRowClicked !== undefined;
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{ width: 30, background: 'transparent' }}
                            />
                            <TableCell>{t('regions.table.name')}</TableCell>
                            <TableCell align="right">
                                {t('regions.table.hospitalCount')}
                            </TableCell>
                            <TableCell align="right">
                                {t('regions.table.commercialCount')}
                            </TableCell>
                            <TableCell align="center">
                                {t('regions.table.creationDate')}
                            </TableCell>
                            <TableCell align="center">
                                {t('regions.table.updateDate')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.regions.map((region) => (
                            <TableRow
                                key={region._id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    cursor: hasClickAction
                                        ? 'pointer'
                                        : 'default',
                                }}
                                hover={hasClickAction}
                            >
                                <TableCell>
                                    <DeleteButton
                                        onClick={() =>
                                            props.onDeleteClicked?.(region)
                                        }
                                    />
                                </TableCell>
                                <TableCell
                                    onClick={() => props.onRowClicked?.(region)}
                                    component="th"
                                    scope="row"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {region.name}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    onClick={() => props.onRowClicked?.(region)}
                                >
                                    {region.nbHospitals}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    onClick={() => props.onRowClicked?.(region)}
                                >
                                    {region.nbCommercials}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => props.onRowClicked?.(region)}
                                >
                                    {region.creationDate && (
                                        <ModificationDate
                                            value={region.creationDate}
                                        />
                                    )}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    onClick={() => props.onRowClicked?.(region)}
                                >
                                    <ModificationDate
                                        value={region.lastUpdate}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
