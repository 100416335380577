import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { gql } from '__generated__/gql';
import { useTranslation } from 'react-i18next';
import TargetingCommands from './TargetingCommands';
import TargetedMri from './TargetedMri';
import TargetingReport from './TargetingReport';
import TargetingStatus from './TargetingStatus';
import TargetingLastUpdate from './TargetingLastUpdate';
import OperatorFeedback from './OperatorFeedback';
import {
    AUTOMATON_DATA_POLLING_INTERVAL_MS,
    BUCKET_SIGNED_URL_TTL_MS,
} from 'config';
import { min } from 'lodash';

export const REFRESH_AUTOMATON_DATA_MS = min([
    BUCKET_SIGNED_URL_TTL_MS,
    AUTOMATON_DATA_POLLING_INTERVAL_MS,
]);

export type AutomatonStatusProps = {
    recordId: string | null | undefined;
};

const AUTOMATON_PATIENT_RECORD = gql(`
    query patientRecordAutomaton($idPatientRecord: String!) {
        patientRecord(idPatientRecord: $idPatientRecord) {
            _id
            ...TargetingReportFragment
            ...TargetedMriFragment
            ...TargetingStatusFragment
            ...TargetingUpdateFragment
            ...OperatorFeedbackFragment
            ...TargetingCommandsFragment
        }
    }
`);

export function AutomatonSection(props: AutomatonStatusProps) {
    const { t } = useTranslation();
    const { data } = useQuery(AUTOMATON_PATIENT_RECORD, {
        skip: props.recordId == null,
        pollInterval: REFRESH_AUTOMATON_DATA_MS,
        variables: {
            idPatientRecord: props.recordId ?? '',
        },
    });
    return (
        <>
            <Grid item container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                        {t('automaton.title')}
                    </Typography>
                </Grid>
                <TargetingStatus record={data?.patientRecord} />
                <TargetingLastUpdate record={data?.patientRecord} />
                <OperatorFeedback record={data?.patientRecord} />
                <TargetedMri record={data?.patientRecord} />
                <TargetingReport record={data?.patientRecord} />
                <TargetingCommands
                    record={data?.patientRecord}
                    refetchQueries={['patientRecordAutomaton', 'patientRecord']}
                />
            </Grid>
        </>
    );
}
