// action - state management
import * as actionTypes from './actions';
import { DefaultRootStateProps } from '../types/index';
import { Reducer, AnyAction } from 'redux';
import { SnackbarOrigin } from '@mui/material';

export interface SnackBarActionProps extends AnyAction {
    type: unknown;
    open?: true;
    message?: string;
    icon?: JSX.Element;
    anchorOrigin?: SnackbarOrigin;
    variant?: string;
    alertSeverity?: 'error' | 'warning' | 'success';
    transition?: string;
    close?: boolean;
    actionButton?: boolean;
    href?: { label: string; url: string };
    actions?: Array<{ label: string; action: () => void }>;
}

const initialState: DefaultRootStateProps['snackbar'] = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    variant: 'default',
    alertSeverity: 'success',
    transition: 'Grow',
    close: true,
    actionButton: false,
    actions: undefined,
};

//-----------------------|| SNACKBAR REDUCER ||-----------------------//

const snackbarReducer: Reducer<
    DefaultRootStateProps['snackbar'],
    SnackBarActionProps
> = (state = initialState, action: SnackBarActionProps) => {
    switch (action.type) {
        case actionTypes.SNACKBAR_OPEN:
            return {
                ...state,
                action: !state.action,
                open: action.open ? action.open : initialState.open,
                message: action.message ? action.message : initialState.message,
                icon: action.icon ? action.icon : initialState.icon,
                anchorOrigin: action.anchorOrigin
                    ? action.anchorOrigin
                    : initialState.anchorOrigin,
                variant: action.variant ? action.variant : initialState.variant,
                alertSeverity: action.alertSeverity
                    ? action.alertSeverity
                    : initialState.alertSeverity,
                transition: action.transition
                    ? action.transition
                    : initialState.transition,
                close:
                    action.close === false ? action.close : initialState.close,
                actionButton: action.actionButton
                    ? action.actionButton
                    : initialState.actionButton,
                href: action.href ? action.href : initialState.href,
                actions: action.actions ? action.actions : initialState.actions,
            };
        case actionTypes.SNACKBAR_CLOSE:
            return {
                ...state,
                action: !state.action,
                open: false,
            };
        default:
            return state;
    }
};

export default snackbarReducer;
