import {
    Autocomplete,
    AutocompleteChangeReason,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import {
    useCommercials,
    useCommercialsInRegion,
} from 'apollo/CommercialRequests';

import { useFormikContext } from 'formik';

type AutocompleteEmailInputProps<FormType> = TextFieldProps & {
    id: keyof FormType;
    value: string | null | undefined;
    regionId: number;
    onPickedExistingEmail?: () => void;
    onPickedNewEmail?: () => void;
};

export default function AutocompleteEmailInput<FormType>(
    props: AutocompleteEmailInputProps<FormType>
) {
    const {
        id,
        onPickedExistingEmail,
        onPickedNewEmail,
        regionId,
        ...inputProps
    } = props;
    const formik = useFormikContext<FormType>();
    const { data } = useCommercials();
    const { data: regionData } = useCommercialsInRegion(regionId);
    const allCommercials = useMemo(() => data?.commercials, [data]);
    const commercialsInRegion = useMemo(
        () => regionData?.findCommercialsInRegion,
        [regionData]
    );

    const existingEmails = useMemo(
        () => allCommercials?.map((c) => c.email) ?? [],
        [allCommercials]
    );
    const disabledEmails = useMemo(
        () => new Set(commercialsInRegion?.map((c) => c.email) ?? []),
        [commercialsInRegion]
    );
    const isDisabled = useCallback(
        (email: string) => disabledEmails.has(email),
        [disabledEmails]
    );

    const commercialsMap = useMemo(
        () => new Map(allCommercials?.map((c) => [c.email, c]) ?? []),
        [allCommercials]
    );

    function handleEmailChange(
        event: SyntheticEvent<Element, Event>,
        email: string | null
    ) {
        formik.setFieldValue(
            id,
            email !== null ? email : formik.initialValues[id]
        );
        formik.handleChange(event);
        if (email) {
            const commercial = commercialsMap.get(email);
            if (commercial) {
                onPickedExistingEmail?.();
                formik.setFieldValue('firstname', commercial.firstname);
                formik.setFieldValue('lastname', commercial.lastname);
                formik.setStatus({ wasAutocompleted: true });
            } else {
                if (formik.status?.wasAutocompleted) {
                    formik.setFieldValue('firstname', '');
                    formik.setFieldValue('lastname', '');
                }
                formik.setStatus({ wasAutocompleted: false });
                onPickedNewEmail?.();
            }
        }
    }

    function onEmailChange(
        event: SyntheticEvent<Element, Event>,
        email: string | null,
        reason: AutocompleteChangeReason
    ) {
        if (isClearFieldEvent(event, reason)) {
            formik.resetForm();
        }
        handleEmailChange(event, email);
    }

    return (
        <Autocomplete
            id={id}
            freeSolo
            value={props.value}
            onChange={onEmailChange}
            onBlur={formik.handleBlur}
            options={existingEmails}
            getOptionDisabled={isDisabled}
            renderInput={(params) => (
                // @ts-expect-error Autocomplete est mal typé, il attend un type trop spécifique (cet exemple est pourtant présenté dans la doc)
                <TextField
                    variant="outlined"
                    onChange={(e) => {
                        handleEmailChange(e, e.target.value);
                    }}
                    {...params}
                    {...inputProps}
                />
            )}
        />
    );
}

function isClearFieldEvent(
    event: SyntheticEvent<Element, Event>,
    reason: AutocompleteChangeReason
) {
    return reason === 'clear' && event.type === 'click';
}
