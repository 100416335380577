import DescriptionIcon from '@mui/icons-material/Description';
import { useFragment, FragmentType } from '__generated__';
import { Button, Grid, Typography } from '@mui/material';
import { gql } from '__generated__/gql';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileListDialog from 'components/FileListDialog';
import { logReportDownloaded } from 'query/log';

const TargetingReportFragment = gql(`
    fragment TargetingReportFragment on PatientRecordSchema {
        _id
        patientNumber
        automatonReportUrl
        automatonReportFileName
    }
`);

export type TargetingReportProps = {
    record: FragmentType<typeof TargetingReportFragment> | undefined;
};

export default function TargetingReport(props: TargetingReportProps) {
    const { t } = useTranslation();
    const [isDownloadShown, setShowDownload] = useState(false);
    const showDownload = useCallback(() => setShowDownload(true), []);
    const hideDownload = useCallback(() => setShowDownload(false), []);
    const record = useFragment(TargetingReportFragment, props.record);
    const reportUrl = record?.automatonReportUrl;
    const reportName =
        record?.automatonReportFileName ??
        t('automaton.default report filename', record);

    let fileListProps;
    if (reportUrl && isDownloadShown) {
        fileListProps = {
            files: [reportName],
            title: t('automaton.report title'),
            urlToDownload: reportUrl,
        };
    }

    async function onClickDownload() {
        if (record?._id) {
            await logReportDownloaded({
                filename: reportName,
                patientRecordId: record._id,
            });
        }
    }

    return (
        <>
            <FileListDialog
                fileListProps={fileListProps}
                onClose={hideDownload}
                onClickDownload={onClickDownload}
            />
            <Grid item xs={12} container spacing={2}>
                <Grid item sm={5}>
                    <Typography variant="h6">Report file:</Typography>
                </Grid>
                <Grid item>
                    <Button
                        onClick={showDownload}
                        variant="contained"
                        color="secondary"
                        startIcon={<DescriptionIcon />}
                        disabled={record?.automatonReportUrl == null}
                    >
                        <Typography variant="caption">
                            {t('display files')}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
