import { IconButton, useTheme } from '@mui/material';

import DeleteIcon from '@mui/icons-material/DeleteForever';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { MouseEventHandler } from 'react';

type EventHandler = MouseEventHandler<HTMLButtonElement>;

export function DeleteButton(props: { onClick: EventHandler }) {
    const { palette } = useTheme();
    return (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.onClick}
        >
            <DeleteIcon
                fontSize="small"
                sx={{
                    ':hover': {
                        color: palette.warning.main,
                    },
                }}
            />
        </IconButton>
    );
}

export function UnlinkButton(props: { onClick: EventHandler }) {
    const { palette } = useTheme();
    return (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.onClick}
        >
            <LinkOffIcon
                fontSize="small"
                sx={{
                    ':hover': {
                        color: palette.warning.main,
                    },
                }}
            />
        </IconButton>
    );
}
