import {
    HospitalCenterCreateSchema,
    HospitalCenterUpdateSchema,
} from '__generated__/graphql';

import { Client } from '.';
import { FetchPolicy } from '@apollo/react-hoc';
import { QUERY_REGIONS } from './RegionRequests';
import { gql } from '__generated__/gql';

export const MCreateHospitalCenter = gql(`
    mutation createHospitalCenter(
        $createHospital: HospitalCenterCreateSchema!
    ) {
        createHospitalCenter(hospitalCenter: $createHospital) {
            _id
        }
    }
`);

export const QHospitalCenters = gql(`
    query hospitalCenters {
        hospitalCenters {
            _id
            name
            address
            regionId
        }
    }
`);

export const QHospitalCenter = gql(`
    query hospitalCenter($idHospitalCenter: Float!) {
        hospitalCenter(idHospitalCenter: $idHospitalCenter) {
            _id
            name
            address
            regionId
        }
    }
`);

export const MRemoveHospitalCenter = gql(`
    mutation removeHospitalCenter($idHospitalCenter: Float!) {
        removeHospitalCenter(idHospitalCenter: $idHospitalCenter) {
            name
        }
    }
`);

export const MUpdateHospitalCenter = gql(`
    mutation updateHospitalCenter(
        $updateHospital: HospitalCenterUpdateSchema!
    ) {
        updateHospitalCenter(hospitalCenter: $updateHospital) {
            _id
            name
            address
            regionId
        }
    }
`);

export const MHospitalCenterRemoveRegion = gql(`
    mutation removeHospitalCenterRegion(
        $idHospitalCenter: Float!
    ) {
        removeHospitalCenterRegion(idHospitalCenter: $idHospitalCenter) {
            _id
            name
            regionId
        }
    }
`);

export class HospitalCenterRequests {
    public static async createHospitalCenter(
        createHospital: HospitalCenterCreateSchema
    ) {
        return (
            await Client.mutate({
                mutation: MCreateHospitalCenter,
                variables: {
                    createHospital,
                },
            })
        ).data!.createHospitalCenter;
    }

    public static async hospitalCenters(fetchPolicy?: FetchPolicy) {
        return (
            await Client.query({
                query: QHospitalCenters,
                fetchPolicy,
            })
        ).data.hospitalCenters;
    }

    public static async hospitalCenter(
        idHospitalCenter: number,
        fetchPolicy?: FetchPolicy
    ) {
        return (
            await Client.query({
                query: QHospitalCenter,
                fetchPolicy,
                variables: {
                    idHospitalCenter,
                },
            })
        ).data.hospitalCenter;
    }

    public static async removeHospitalCenter(idHospitalCenter: number) {
        return (
            await Client.mutate({
                mutation: MRemoveHospitalCenter,
                variables: {
                    idHospitalCenter,
                },
            })
        ).data!.removeHospitalCenter;
    }

    public static async updateHospitalCenter(
        updateHospital: HospitalCenterUpdateSchema
    ) {
        return (
            await Client.mutate({
                mutation: MUpdateHospitalCenter,
                variables: {
                    updateHospital,
                },
                refetchQueries: [
                    { query: QHospitalCenters },
                    { query: QUERY_REGIONS },
                ],
            })
        ).data!.updateHospitalCenter;
    }

    public static async removeHospitalRegion(idHospitalCenter: number) {
        return await Client.mutate({
            mutation: MHospitalCenterRemoveRegion,
            variables: {
                idHospitalCenter,
            },
            refetchQueries: [
                { query: QHospitalCenters },
                { query: QUERY_REGIONS },
            ],
        });
    }
}
