import {
    Box,
    Button,
    Grid,
    InputAdornment,
    InputBase,
    Paper,
    Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import LockIcon from '@mui/icons-material/Lock';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import banniere from '../../assets/LOGO BLANC-300.png';
import ErrorDialog, { IErrorInformation } from '../../components/errorDialog';

const styles = () =>
    createStyles({
        paper: {
            backgroundColor: '#3e3c3cBB',
            color: '#ffffff',
        },
        input: {
            margin: 10,
            flex: 1,
            width: '95%',
        },
        iconButton: {
            marginRight: 10,
            marginLeft: 10,
            color: '#3e3c3cBB',
        },
        margin: {
            margin: 10,
        },
        connection: {
            textDecorationLine: 'underline',
            color: '#ffffff',
        },
        logo: {
            maxWidth: 200,
        },
    });

interface IState {
    password: string;
    verificationPassword: string;

    error?: IErrorInformation | undefined;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
    onClickConnection?: () => void;
    onClickResetPassword: (password: string) => void;
    onClickForgottenPassword?: () => void;
}

class ResetPasswordForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            verificationPassword: '',
            password: '',
        };
    }
    render = () => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.paper}
                variant="outlined"
                elevation={3}
                component="form"
            >
                <Box pb={5} pt={2}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <Box mx="auto" pb={5}>
                            <img
                                src={banniere}
                                alt="logo"
                                className={classes.logo}
                            />
                            {/* <Typography variant="h1">foodr</Typography> */}
                        </Box>

                        <Box px={5}>
                            <Paper>
                                <InputBase
                                    onChange={(e) =>
                                        this.setState({
                                            password: e.target.value,
                                        })
                                    }
                                    type="password"
                                    color="primary"
                                    className={classes.input}
                                    placeholder={this.props.t(
                                        'user.label.password'
                                    )}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LockIcon
                                                className={classes.iconButton}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </Paper>
                        </Box>

                        <Box px={5} py={1}>
                            <Paper>
                                <InputBase
                                    onChange={(e) =>
                                        this.setState({
                                            verificationPassword:
                                                e.target.value,
                                        })
                                    }
                                    type="password"
                                    className={classes.input}
                                    placeholder={this.props.t(
                                        'user.label.passwordCheck'
                                    )}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LockIcon
                                                className={classes.iconButton}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </Paper>
                        </Box>
                        <Box p={2} mx="auto">
                            <Button
                                onClick={this.__onClickResetPassword}
                                color="secondary"
                                variant="contained"
                                size="large"
                            >
                                {this.props.t('user.msg.changePassword')}
                            </Button>
                        </Box>
                        <Grid item container justifyContent="space-between">
                            <Box px={5}>
                                <Button
                                    onClick={() =>
                                        this.props.onClickConnection &&
                                        this.props.onClickConnection()
                                    }
                                >
                                    <Typography
                                        variant="caption"
                                        className={classes.connection}
                                    >
                                        {this.props.t(
                                            'session.button.connection'
                                        )}
                                    </Typography>
                                </Button>
                            </Box>

                            <Box px={5}>
                                <Button
                                    onClick={() =>
                                        this.props.onClickForgottenPassword &&
                                        this.props.onClickForgottenPassword()
                                    }
                                >
                                    <Typography
                                        variant="caption"
                                        className={classes.connection}
                                    >
                                        {this.props.t(
                                            'session.button.forgotPassword'
                                        )}
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
            </Paper>
        );
    };

    __onClickResetPassword = () => {
        if (this.state.password.length === 0)
            return this.setState({
                error: {
                    title: this.props.t('generic.msg.error'),
                    message: this.props.t('user.msg.checkMail'),
                },
            });
        else if (this.state.password !== this.state.verificationPassword)
            return this.setState({
                error: {
                    title: this.props.t('generic.msg.error'),
                    message: this.props.t('user.msg.passwordMismatch'),
                },
            });

        this.props.onClickResetPassword(this.state.password);
    };
}

export default withTranslation()(withStyles(styles)(ResetPasswordForm));
