/** Send queries to the backend to add database logs */
import { ActionLogType } from '__generated__/graphql';
import { API_ENDPOINT } from 'config';

const LOG_ENDPOINT = `${API_ENDPOINT}/log`;

type LogDownloadReportBody = {
    filename: string;
    patientRecordId: string;
};

export async function logReportDownloaded(data: LogDownloadReportBody) {
    return await fetch(`${LOG_ENDPOINT}/report-downloaded`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
}

type LogBody = {
    description: string | Record<string, unknown>;
    action: ActionLogType;
};

/** Log anything to the database */
export default async function log(data: LogBody) {
    return await fetch(`${LOG_ENDPOINT}/anything`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
}
