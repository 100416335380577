import { Box, Tooltip } from '@mui/material';

import { Circle } from '@mui/icons-material';
import { FormattedDate } from 'react-intl';
import { isToday } from 'toolbox/date';
import { useTranslation } from 'react-i18next';

export default function ModificationDate(props: {
    value: string | null | undefined;
}) {
    if (!props.value) return null;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                gap: 2,
            }}
        >
            <FormattedDate
                value={props.value}
                day="numeric"
                month="long"
                year="numeric"
                hour="2-digit"
                minute="2-digit"
            />
            {isToday(props.value) && <ModifiedTodayMarker />}
        </Box>
    );
}

export function ModifiedTodayMarker() {
    const { t } = useTranslation();
    return (
        <Tooltip title={t('modifiedTodayMarker')}>
            <Circle
                color="primary"
                fontSize="small"
                sx={{ alignSelf: 'center', height: 10, width: 10, mx: 1 }}
            />
        </Tooltip>
    );
}
