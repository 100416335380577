import { useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { LoginRouter } from 'router';
import { UserRequests } from '../apollo';

enum Status {
    LOGGED,
    NOLLOGED,
    WAITING,
}

interface IProps {
    path: string;
    children?: React.ReactNode;
}

const PrivateRoute = ({ path, children }: IProps) => {
    const [logged, setLogged] = useState<Status>(Status.WAITING);

    const location = useLocation();

    const __loadUserData = () => {
        UserRequests.meBasic('no-cache')
            .then(() => setLogged(Status.LOGGED))
            .catch(() => setLogged(Status.NOLLOGED));
    };

    useEffect(() => {
        __loadUserData();
    }, [location.pathname, location.key]);

    return (
        <Route
            path={path}
            render={() => {
                if (logged === Status.WAITING) {
                    return null;
                } else if (logged === Status.LOGGED) {
                    return children;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: LoginRouter.getRoute(),
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export default PrivateRoute;
