import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

const NO_REGION = 'NO_REGION';

export type Region = {
    _id: number;
    name: string;
};

export type RegionSelectorProps = {
    availableRegions: Region[];
    initialRegionId?: number | null | undefined;
    onRegionChange?: (regionId: number) => Promise<void>;
    onRegionRemove?: () => Promise<void>;
};

export default function RegionSelector(props: RegionSelectorProps) {
    const { t } = useTranslation();
    const [selectedRegionId, selectRegionId] = React.useState(
        props.initialRegionId
    );

    useEffect(
        () => selectRegionId(props.initialRegionId),
        [props.initialRegionId]
    );

    const onRegionChange = useCallback(async (regionIdStr: string) => {
        if (regionIdStr == NO_REGION) {
            selectRegionId(undefined);
            props.onRegionRemove?.();
            return;
        }
        const regionId = parseInt(regionIdStr);
        selectRegionId(regionId);
        props.onRegionChange?.(regionId);
    }, []);

    return (
        <Select
            value={selectedRegionId?.toString() ?? NO_REGION}
            onChange={(e: SelectChangeEvent) => onRegionChange(e.target.value)}
        >
            <MenuItem value={NO_REGION}>
                {t('region.label.noLinkedRegion')}
            </MenuItem>
            {props.availableRegions.map((region) => (
                <MenuItem key={region._id} value={region._id}>
                    {region.name}
                </MenuItem>
            ))}
        </Select>
    );
}
