import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Select,
    TextField,
    OutlinedInput,
    MenuItem,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserRequests, HospitalCenterRequests } from '../../../apollo';
import CreateSomethingWrapper from '../../../components/createSomethingWrapper';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import { UsersRouter } from '../../../router/dashboard/users/users.router';
import { isValidRole } from 'types/check';
import {
    HospitalCenterSchema,
    Roles,
    UserUpdateSchema,
} from '__generated__/graphql';
import { getEnumKeys } from 'types/helpers';

const styles = () =>
    createStyles({
        title: {
            fontWeight: 'bolder',
        },
        gradient: {},
    });

interface IState {
    fieldErrors?: { [key: string]: string };

    user?: UserUpdateSchema | undefined;

    error?: IErrorInformation;
    information?: IInformative;

    hospitals: Array<HospitalCenterSchema>;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps<{ id: string }>,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class UpdateUserPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hospitals: [],
        };
    }

    componentDidMount = () => {
        this.__loadHospitals();
        this.__loadOriginUser(this.props.match.params.id);
    };

    render = () => {
        return (
            <CreateSomethingWrapper
                title={this.props.t('user.label.update')}
                onPressCancel={this.__onPressCancel}
                onPressCreate={this.__onPressCreate}
            >
                {this.__renderForm()}
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
            </CreateSomethingWrapper>
        );
    };

    __renderForm = () => {
        return (
            <form>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="firstname"
                            error={
                                this.state.fieldErrors?.firstname !== undefined
                            }
                            helperText={this.state.fieldErrors?.firstname}
                            label={this.props.t('generic.label.firstname')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            value={this.state.user?.firstname}
                            onChange={(e) =>
                                this.__onFirstNameChange(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="lastname"
                            error={
                                this.state.fieldErrors?.lastname !== undefined
                            }
                            helperText={this.state.fieldErrors?.lastname}
                            label={this.props.t('generic.label.lastname')}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.user?.lastname}
                            onChange={(e) =>
                                this.__onLastNameChange(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={
                                this.state.fieldErrors?.idHospitalCenter !==
                                undefined
                            }
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="hospital-native-simple"
                            >
                                {this.props.t('user.label.hospital')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.user?.idHospitalCenter ?? ''}
                                onChange={(e) =>
                                    this.__onIdHospitalCenterChange(
                                        Number(e.target.value)
                                    )
                                }
                                inputProps={{ id: 'hospital-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'user.label.hospital'
                                        )}
                                    />
                                }
                            >
                                {this.state.hospitals.map((hospital) => (
                                    <MenuItem
                                        value={hospital._id}
                                        key={hospital._id}
                                    >
                                        {hospital.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.idHospitalCenter}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={6} />
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            error={this.state.fieldErrors?.role !== undefined}
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="role-native-simple"
                            >
                                {this.props.t('user.label.role')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.user?.role ?? ''}
                                onChange={(e) =>
                                    this.__onRoleChange(e.target.value)
                                }
                                inputProps={{ id: 'role-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('user.label.role')}
                                    />
                                }
                            >
                                {getEnumKeys(Roles).map((enumKey) => (
                                    <MenuItem
                                        key={enumKey}
                                        value={Roles[enumKey]}
                                    >
                                        {enumKey}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.role}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={6} />
                </Grid>
            </form>
        );
    };

    __onFirstNameChange = (firstName: string) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    firstname: firstName,
                },
            });
        }
    };

    __onLastNameChange = (lastName: string) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    lastname: lastName,
                },
            });
        }
    };

    __onIdHospitalCenterChange = (idHospitalCenter: number) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    idHospitalCenter,
                },
            });
        }
    };

    __onRoleChange = (role: string | null) => {
        if (!isValidRole(role)) {
            throw TypeError(
                `No such role ${role} valid roles are ${Object.keys(Roles)}`
            );
        }
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    role,
                },
            });
        }
    };

    __onPressCancel = () => {
        this.props.history.goBack();
    };
    __onPressCreate = () => {
        const user = this.state.user;
        const errors: { [key: string]: string } = {};

        if (!user) return;
        if (!user.role || user.role.length <= 0)
            errors.role = this.props.t('user.msg.checkRole');
        if (!user.firstname || user.firstname.length <= 0)
            errors.firstname = this.props.t('generic.msg.checkFirstname');
        if (!user.lastname || user.lastname.length <= 0)
            errors.lastname = this.props.t('generic.msg.checkLastname');
        // if (user.idHospitalCenter === undefined || user.idHospitalCenter === null)
        // 	errors.idHospitalCenter = this.props.t('user.msg.checkHospital')

        if (
            ![Roles.Admin, Roles.Operator].includes(user.role as Roles) &&
            !user.idHospitalCenter
        )
            errors.idHospitalCenter = this.props.t('user.msg.checkHospital');

        if (Object.keys(errors).length > 0)
            return this.setState({ fieldErrors: errors });

        this.setState({
            information: {
                title: this.props.t('generic.msg.updating'),
                showProgress: true,
                message: `${this.props.t('generic.msg.updating')}...`,
            },
        });

        UserRequests.updateUser(this.state.user!)
            .then((_) => {
                this.setState({
                    information: {
                        title: `${this.props.t('generic.msg.success')} !`,
                        message: `${this.props.t('user.msg.userUpdated')}.`,
                        onClose: () =>
                            this.props.history.push(UsersRouter.getRoute()),
                    },
                });
                setTimeout(
                    () => this.props.history.push(UsersRouter.getRoute()),
                    1500
                );
            })
            .catch((error: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: error.message,
                    },
                });
            });
    };

    __loadOriginUser = async (idUser: string) => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                message: this.props.t('user.msg.loadingInfo'),
                showProgress: true,
            },
        });

        UserRequests.user(idUser, 'no-cache')
            .then((user) => {
                this.setState({
                    information: undefined,
                    user: {
                        _id: user._id,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        role: user.role,
                        idHospitalCenter: user.hospitalCenter
                            ? user.hospitalCenter._id
                            : undefined,
                    },
                });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.loadingError'),
                        message: err.message,
                        onClose: this.props.history.goBack,
                    },
                });
            });
    };
    __loadHospitals = () => {
        HospitalCenterRequests.hospitalCenters()
            .then((hospitals) => this.setState({ hospitals }))
            .catch((error: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: error.message,
                    },
                });
            });
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(UpdateUserPage)))
);
