import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import {
    Alert,
    Button,
    Fade,
    Grow,
    IconButton,
    Slide,
    SlideProps,
    Typography,
} from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';

import { DefaultRootStateProps } from '../../types';
import { FormattedMessage } from 'react-intl';
import useSnackbar from '../../hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const transition = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade: Fade,
} as const;

type TransitionKey = keyof typeof transition;

function isTransition(keyInput: string): keyInput is TransitionKey {
    return (typeof transition).includes(keyInput);
}

//-----------------------|| SNACKBAR ||-----------------------//

const Snackbar = () => {
    const [open, setOpen] = React.useState(false);

    const { closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const snackbarInitial = useSelector(
        (state: DefaultRootStateProps) => state.snackbar
    );

    const handleClose = (reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        closeSnackbar();
        // setOpen(false);
    };

    React.useEffect(() => {
        setOpen(snackbarInitial.open);
    }, [snackbarInitial.action, snackbarInitial.open]);

    const transitionComponent = useMemo(() => {
        if (isTransition(snackbarInitial.transition)) {
            return transition[snackbarInitial.transition];
        }
    }, [snackbarInitial.transition]);

    return (
        <React.Fragment>
            {/* default snackbar */}
            {snackbarInitial.variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={snackbarInitial.anchorOrigin}
                    open={open}
                    autoHideDuration={5000}
                    onClose={(_, v: string) => handleClose(v)}
                    message={snackbarInitial.message}
                    TransitionComponent={transitionComponent}
                    action={
                        <React.Fragment>
                            <Button
                                color="secondary"
                                size="small"
                                onClick={() => handleClose()}
                            >
                                UNDO
                            </Button>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => handleClose()}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            )}

            {/* alert snackbar */}
            {snackbarInitial.variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={transitionComponent}
                    anchorOrigin={snackbarInitial.anchorOrigin}
                    open={open}
                    autoHideDuration={5000}
                    onClose={(_, v: string) => handleClose(v)}
                >
                    <Alert
                        variant="filled"
                        severity={snackbarInitial.alertSeverity}
                        sx={{
                            bgcolor: snackbarInitial.alertSeverity + '.main',
                            color:
                                snackbarInitial.alertSeverity === 'warning'
                                    ? 'grey.800'
                                    : '',
                        }}
                        action={
                            <React.Fragment>
                                {snackbarInitial.actionButton !== false && (
                                    <Button
                                        color="secondary"
                                        size="small"
                                        onClick={() => handleClose()}
                                    >
                                        UNDO
                                    </Button>
                                )}
                                {snackbarInitial.close !== false && (
                                    <IconButton
                                        size="small"
                                        aria-label="close"
                                        color="inherit"
                                        onClick={() => handleClose()}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </React.Fragment>
                        }
                    >
                        {snackbarInitial.message && (
                            <FormattedMessage id={snackbarInitial.message} />
                        )}
                    </Alert>
                </MuiSnackbar>
            )}

            {/* alert snackbar */}
            {snackbarInitial.variant === 'informative' && (
                <MuiSnackbar
                    TransitionComponent={transitionComponent}
                    anchorOrigin={snackbarInitial.anchorOrigin}
                    open={open}
                    autoHideDuration={5000}
                    onClose={(_, v: string) => handleClose(v)}
                >
                    <Alert
                        variant="filled"
                        severity="info"
                        icon={snackbarInitial.icon}
                        action={
                            <React.Fragment>
                                {snackbarInitial.actions &&
                                    snackbarInitial.actions.map(
                                        ({ label, action }) => (
                                            <Button
                                                color="primary"
                                                size="small"
                                                onClick={action}
                                                key={label}
                                            >
                                                <Typography variant="button">
                                                    {' '}
                                                    <FormattedMessage
                                                        id={label}
                                                    />
                                                </Typography>
                                            </Button>
                                        )
                                    )}
                                {snackbarInitial.href && (
                                    <Button
                                        color="primary"
                                        size="small"
                                        href={snackbarInitial.href.url}
                                    >
                                        <Typography variant="button">
                                            {' '}
                                            <FormattedMessage
                                                id={snackbarInitial.href.label}
                                            />
                                        </Typography>
                                    </Button>
                                )}
                                {snackbarInitial.close !== false && (
                                    <IconButton
                                        size="small"
                                        aria-label="close"
                                        color="inherit"
                                        onClick={() => handleClose()}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </React.Fragment>
                        }
                    >
                        {snackbarInitial.message && t(snackbarInitial.message)}
                    </Alert>
                </MuiSnackbar>
            )}
        </React.Fragment>
    );
};

export default Snackbar;
