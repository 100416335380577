import {
    Button,
    ButtonProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    Stack,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';
import {
    ActionLogFiltersInput as ActionLogFilters,
    ActionLogType,
} from '__generated__/graphql';

const ButtonEquipments = styled(
    ({ ...props }: ButtonProps & { isSelected?: boolean }) => (
        <Button {...props} />
    )
)(({ theme: { palette }, isSelected }) => ({
    border: 'solid 1px',
    borderRadius: '24px',
    margin: '5px !important',
    variant: 'outlined',

    ...(isSelected && {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        ':hover': {
            backgroundColor: palette.primary.dark,
        },
    }),
    ...(!isSelected && {
        ':hover': {
            backgroundColor: palette.primary.light,
        },
    }),
}));

interface LogsFiltersDialogProps extends DialogProps {
    filters?: ActionLogFilters;

    onSaveFilters?: (filters: ActionLogFilters | undefined) => void;
    onCancel?: () => void;
}
export const LogsFiltersDialog = ({
    filters,
    onSaveFilters,
    onCancel,
    ...props
}: LogsFiltersDialogProps) => {
    // States
    const [form, setForm] = useState<ActionLogFilters>();

    // Effects
    useEffect(() => {
        setForm(filters);
    }, [filters, setForm]);

    // Callbacks
    const onClickSave = () => {
        if (onSaveFilters) onSaveFilters(form);
    };

    const onClickAction = (action: ActionLogType) => () => {
        const isSelected = form && form.actions?.includes(action);

        if (isSelected && form && form.actions) {
            setForm({
                ...form,
                actions: form.actions.filter((item) => item !== action),
            });
        } else if (!isSelected) {
            if (form && form.actions) {
                setForm({ ...form, actions: [...form.actions, action] });
            } else {
                setForm({ ...form, actions: [action] });
            }
        }
    };
    return (
        <Dialog {...props}>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant="h4">
                        <FormattedMessage id="Log types" />
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        {Object.values(ActionLogType).map((item, index) => {
                            const isSelected =
                                form && form.actions?.includes(item);
                            return (
                                <ButtonEquipments
                                    key={index}
                                    isSelected={isSelected}
                                    onClick={onClickAction(
                                        item as ActionLogType
                                    )}
                                >
                                    {item}
                                </ButtonEquipments>
                            );
                        })}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Button
                        size="small"
                        variant="text"
                        onClick={() =>
                            onSaveFilters && onSaveFilters(undefined)
                        }
                    >
                        <FormattedMessage id="Clear filters" />
                    </Button>
                    <Stack direction="row" spacing={2}>
                        <Button
                            color="error"
                            variant="contained"
                            onClick={onCancel}
                        >
                            <FormattedMessage id="Cancel" />
                        </Button>
                        <Button variant="contained" onClick={onClickSave}>
                            <FormattedMessage id="Save" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
