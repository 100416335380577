import { FetchPolicy, withApollo, WithApolloClient } from '@apollo/react-hoc';
import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserRequests } from '../../../apollo';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import { UpdateUserRouter } from '../../../router/dashboard/updateUser/updateUsers.router';
import UserRow from './userRow';
import { UsersQuery, UserStatus } from '__generated__/graphql';
import { Unpacked } from 'types/helpers';

type User = Unpacked<UsersQuery['users']>;

const styles = (theme: Theme) =>
    createStyles({
        table: {
            [theme.breakpoints.up('md')]: {
                maxHeight: '90vh',
            },
        },
    });

interface IState {
    users: Array<User>;

    error?: IErrorInformation;
    information?: IInformative;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class UsersPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            users: [],
        };
    }

    componentDidMount = () => {
        this.__loadUser('no-cache');
    };

    render = () => {
        const classes = this.props.classes;

        return (
            <Container component={Box} mt={2}>
                <TableContainer component={Paper} className={classes.table}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    {this.props.t('user.label.status')}
                                </TableCell>
                                <TableCell>
                                    {this.props.t('user.label.email')}
                                </TableCell>
                                {/* <TableCell align="right">{this.props.t('generic.label.firstname')}</TableCell> */}
                                <TableCell align="right">
                                    {this.props.t('generic.label.name')}
                                </TableCell>
                                <TableCell align="right">
                                    {this.props.t('user.label.role')}
                                </TableCell>
                                <TableCell align="right">
                                    {this.props.t('user.label.hospital')}
                                </TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.users.map((user: User, index) => {
                                return (
                                    <UserRow
                                        key={index}
                                        onClickUpdateUser={
                                            this.__onClickUpdateUser
                                        }
                                        onClickSendResetPasswordMail={
                                            this.__onClickSendResetPasswordMail
                                        }
                                        onActivateUser={this.__onActivateUser}
                                        onDisableUser={this.__onDisableUser}
                                        onClickDeleteUser={
                                            this.__onClickDeleteUser
                                        }
                                        user={user}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
            </Container>
        );
    };

    __loadUser = (fetchPolicy?: FetchPolicy) => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                showProgress: true,
            },
        });

        UserRequests.users(fetchPolicy)
            .then((users: Array<User>) => {
                this.setState({ information: undefined, users });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            });
    };

    __onClickUpdateUser = (user: User) => {
        this.props.history.push(UpdateUserRouter.getRoute(user._id));
    };

    __onClickSendResetPasswordMail = (user: User) => {
        this.setState({
            information: {
                showProgress: true,
                title: this.props.t('generic.msg.loading'),
            },
        });

        UserRequests.forgottenPassword(user.email)
            .then(() => {
                this.setState({
                    information: {
                        showProgress: false,
                        message: this.props.t('user.msg.pwdResetEmailSent'),
                        onClose: () =>
                            this.setState({ information: undefined }),
                    },
                });
            })
            .catch((e: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: e.message,
                    },
                });
            });
    };

    __onClickDeleteUser = (user: User) => {
        this.setState({
            information: {
                showProgress: true,
                title: this.props.t('generic.msg.deleting'),
            },
        });

        UserRequests.deleteUser(user._id)
            .then(() => {
                this.setState({
                    information: {
                        title: this.props.t('user.msg.userDeleted'),
                        message: `User ${user.email} is deleted.`,
                        showProgress: false,
                        onClose: () =>
                            this.setState({ information: undefined }, () =>
                                this.__loadUser('no-cache')
                            ),
                    },
                });
            })
            .catch((e: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: e.message,
                    },
                });
            });
    };

    __onActivateUser = (user: User) => {
        this.__setUserStatus(user, UserStatus.Enable);
    };
    __onDisableUser = (user: User) => {
        this.__setUserStatus(user, UserStatus.Disable);
    };

    __setUserStatus = (user: User, status: UserStatus) => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.updating'),
                showProgress: true,
                message: `${this.props.t('generic.msg.updating')}...`,
            },
        });
        UserRequests.updateUser({ _id: user._id, status })
            .then(() => {
                this.__loadUser('no-cache');
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            });
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(UsersPage)))
);
