import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import { CircularProgress, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as pkg from '../../../package.json';
import { UserRequests } from '../../apollo';
import Background from '../../assets/login-background.jpg';
import ErrorDialog, { IErrorInformation } from '../../components/errorDialog';
import InformativeDialog, {
    IInformative,
} from '../../components/informativeDialog';
import { LoginRouter } from '../../router';
import { ForgottenPasswordRouter } from '../../router/forgottenPassword/forgottenPassword.router';
import ResetPasswordForm from './ResetPasswordForm';

const styles = () =>
    createStyles({
        div: {
            backgroundImage: `url(${Background})`,
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            flexDirection: 'column-reverse',
        },
        grid: {
            height: '100%',
        },
        paper: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        form: {},
        progress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        version: {
            color: '#ffffff',
            fontWeight: 'bold',
            padding: 10,
        },
    });

interface IState {
    error?: IErrorInformation;
    information?: IInformative;

    progress: boolean;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps<{ token: string }>,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class ResetPasswordPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            progress: false,
        };
    }

    render = () => {
        const classes = this.props.classes;

        return (
            <div className={classes.div}>
                <Grid className={classes.version}>v {pkg.version}</Grid>
                <Grid
                    container
                    justifyContent="center"
                    className={classes.grid}
                >
                    <Grid
                        item
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        container
                        xs={10}
                        md={3}
                    >
                        <ResetPasswordForm
                            onClickResetPassword={this.__onClickResetPassword}
                            onClickConnection={this.__onClickConnection}
                            onClickForgottenPassword={
                                this.__onClickForgottenPassword
                            }
                        />
                    </Grid>
                    <ErrorDialog
                        error={this.state.error}
                        onClose={() => this.setState({ error: undefined })}
                    />
                    <InformativeDialog
                        information={this.state.information}
                        onClose={() => undefined}
                    />
                    {this.state.progress && (
                        <CircularProgress className={classes.progress} />
                    )}
                </Grid>
            </div>
        );
    };

    __onClickConnection = async () => {
        this.props.history.push(LoginRouter.getRoute());
    };

    __onClickForgottenPassword = async () => {
        this.props.history.push(ForgottenPasswordRouter.getRoute());
    };

    __onClickResetPassword = async (password: string) => {
        this.setState({ progress: true });

        UserRequests.resetPassword(this.props.match.params.token, password)
            .then(() => {
                this.setState({
                    progress: false,
                    information: {
                        title: this.props.t('user.label.password'),
                        message: `${this.props.t('user.msg.passwordSaved')}.`,
                        onClose: () =>
                            this.props.history.push(LoginRouter.getRoute()),
                    },
                });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            })
            .finally(() => this.setState({ progress: false }));
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(ResetPasswordPage)))
);
