import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { HEADER } from 'config';
import { GraphQLError } from 'graphql';
import lodash from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PatientRecordRequests, UserRequests } from '../../../apollo';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import { PatientRecordRouter } from '../../../router/dashboard/patientRecord/patientRecord.router';
import { UpdatePatientRecordRouter } from '../../../router/dashboard/updatePatientRecord/updatePatientRecord.router';
import PatientRecordFiltersForm from './patientRecordFiltersForm';
import PatientRecordRow from './patientRecordRow';
import { PatientRecordsQuery, MeQuery, Roles } from '__generated__/graphql';
import { Unpacked } from 'types/helpers';

type User = MeQuery['me'];
type PatientRecord = Unpacked<PatientRecordsQuery['patientRecords']>;

const styles = (theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.up('sm')]: {
                height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
                overflow: 'hidden',
            },
        },
    });

interface IState {
    user?: User;

    records: Array<PatientRecord>;
    filteredRecords: Array<PatientRecord>;

    error?: IErrorInformation;
    information?: IInformative;

    openFilters: boolean;

    orderBy: Array<keyof PatientRecord>;
    order: 'asc' | 'desc';
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

interface HeadCell {
    id: Array<keyof PatientRecord>;
    label: string;
    numeric: boolean;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

class PatientRecordsPage extends React.Component<IProps, IState> {
    headCells: HeadCell[];

    constructor(props: IProps) {
        super(props);
        this.state = {
            records: [],
            filteredRecords: [],
            openFilters: false,
            orderBy: ['creationDate'],
            order: 'desc',
        };

        this.headCells = [];
    }

    componentDidMount = () => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                showProgress: true,
            },
        });
        setTimeout(() => {
            this.__loadPatientRecord();
            this.__loadUserData();
        }, 1000);
    };

    render = () => {
        const classes = this.props.classes;

        return (
            <Stack p={2} spacing={2} className={classes.container}>
                <PatientRecordFiltersForm
                    records={this.state.records}
                    onApplyFilter={(records) =>
                        this.setState({ filteredRecords: records })
                    }
                />
                {this.__renderTable()}

                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
            </Stack>
        );
    };

    __renderTable = () => {
        const headCells = this.headCells;
        const order = this.state.order;

        return (
            <TableContainer component={Paper} sx={{ height: '100%' }}>
                <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headcell: HeadCell) => {
                                const isActive = lodash.isEqual(
                                    headcell.id,
                                    this.state.orderBy
                                );
                                return (
                                    <TableCell
                                        align={headcell.align}
                                        key={headcell.label}
                                        sortDirection={isActive ? order : false}
                                    >
                                        <TableSortLabel
                                            active={isActive}
                                            direction={isActive ? order : 'asc'}
                                            onClick={() =>
                                                this.__onClickSortByCell(
                                                    headcell
                                                )
                                            }
                                        >
                                            {headcell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {this.__renderTableBody()}
                </Table>
            </TableContainer>
        );
    };

    __renderTableBody = () => {
        const orderBy = this.state.orderBy;
        const order = this.state.order;
        const filteredRecords: Array<PatientRecord> = lodash.orderBy(
            this.state.filteredRecords,
            orderBy,
            order
        ) as Array<PatientRecord>;

        return (
            <TableBody>
                {filteredRecords.map((record: PatientRecord, index) => {
                    if (!this.state.user) return null;
                    return (
                        <PatientRecordRow
                            user={this.state.user}
                            key={index}
                            onClickRow={this.__onClickRow}
                            // onClickRemoveRecord={this.__onClickRemoveRecord}
                            onClickUpdatePatientRecord={
                                this.__onClickUpdatePatientRecord
                            }
                            record={record}
                        />
                    );
                })}
            </TableBody>
        );
    };

    __loadPatientRecord = () => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                showProgress: true,
            },
        });
        PatientRecordRequests.patientRecords('no-cache')
            .then((records) => {
                this.setState({ records: records, filteredRecords: records });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            })
            .finally(() => this.setState({ information: undefined }));
    };

    __onClickUpdatePatientRecord = (record: PatientRecord) => {
        this.props.history.push(UpdatePatientRecordRouter.getRoute(record._id));
    };

    __onClickRemoveRecord = (_: PatientRecord) => undefined;
    __onClickRow = (record: PatientRecord) => {
        this.props.history.push(PatientRecordRouter.getRoute(record._id));
    };

    __loadUserData = () => {
        UserRequests.meBasic()
            .then((user: User) => {
                this.setState({ user });
                if (user?.role === Roles.Clinician)
                    this.headCells = [
                        {
                            id: ['status'],
                            numeric: false,
                            label: this.props.t('pr.label.status'),
                            align: 'left',
                        },
                        {
                            id: ['patientNumber'],
                            numeric: false,
                            label: this.props.t('pr.label.patientNumber'),
                            align: 'left',
                        },
                        {
                            id: ['firstname', 'lastname'],
                            numeric: false,
                            label: this.props.t('generic.label.name'),
                        },
                        {
                            id: ['pathologie'],
                            numeric: false,
                            label: this.props.t('pr.label.pathology'),
                            align: 'left',
                        },
                        {
                            id: ['target'],
                            numeric: false,
                            label: this.props.t('pr.label.target'),
                            align: 'left',
                        },
                        {
                            id: ['side'],
                            numeric: false,
                            label: this.props.t('pr.label.side'),
                            align: 'left',
                        },
                        {
                            id: ['typeOfSurgery'],
                            numeric: false,
                            label: this.props.t('pr.label.surgeryType'),
                            align: 'left',
                        },
                        {
                            id: ['creationDate'],
                            numeric: false,
                            label: this.props.t('pr.label.creationDate'),
                            align: 'left',
                        },
                        {
                            id: ['treatmentDate'],
                            numeric: false,
                            label: this.props.t('pr.label.processingDate'),
                            align: 'left',
                        },
                    ];
                else
                    this.headCells = [
                        {
                            id: ['status'],
                            numeric: false,
                            label: this.props.t('pr.label.status'),
                            align: 'left',
                        },
                        {
                            id: ['patientNumber'],
                            numeric: false,
                            label: this.props.t('pr.label.patientNumber'),
                            align: 'left',
                        },
                        {
                            id: ['clinician'],
                            numeric: false,
                            label: this.props.t('pr.label.ownerName'),
                        },
                        {
                            id: ['pathologie'],
                            numeric: false,
                            label: this.props.t('pr.label.pathology'),
                            align: 'left',
                        },
                        {
                            id: ['target'],
                            numeric: false,
                            label: this.props.t('pr.label.target'),
                            align: 'left',
                        },
                        {
                            id: ['side'],
                            numeric: false,
                            label: this.props.t('pr.label.side'),
                            align: 'left',
                        },
                        {
                            id: ['typeOfSurgery'],
                            numeric: false,
                            label: this.props.t('pr.label.surgeryType'),
                            align: 'left',
                        },
                        {
                            id: ['creationDate'],
                            numeric: false,
                            label: this.props.t('pr.label.creationDate'),
                            align: 'left',
                        },
                        {
                            id: ['treatmentDate'],
                            numeric: false,
                            label: this.props.t('pr.label.processingDate'),
                            align: 'left',
                        },
                        {
                            id: ['clinicianDowloadedDate'],
                            numeric: false,
                            label: this.props.t('pr.label.downloadDate'),
                            align: 'left',
                        },
                    ];
            })
            .catch((e) => console.log('error', e));
    };

    __onClickSortByCell = (headcell: HeadCell) => {
        const isActive = lodash.isEqual(headcell.id, this.state.orderBy);

        if (isActive) {
            this.setState({
                order: this.state.order === 'asc' ? 'desc' : 'asc',
            });
        } else {
            this.setState({ orderBy: headcell.id });
        }
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(PatientRecordsPage)))
);
