import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import { Grid, TextField, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ServerRequests } from '../../../apollo';
import CreateSomethingWrapper from '../../../components/createSomethingWrapper';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import { UsersRouter } from '../../../router/dashboard/users/users.router';
import { MailSettingsQuery } from '__generated__/graphql';

type MailSettings = MailSettingsQuery['mailSettings'];
const styles = () => createStyles({});

interface IState {
    error?: IErrorInformation;
    information?: IInformative;

    mailSettings: MailSettings;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class SettingsPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            mailSettings: {
                mail: null,
                password: null,
                service: null,
                smtp: null,
                port: null,
            },
        };
    }

    componentDidMount = () => {
        this.__loadSettings();
    };

    render = () => {
        return (
            <CreateSomethingWrapper
                title={this.props.t('settings.title')}
                onPressCancel={this.__onPressCancel}
                onPressCreate={this.__onPressCreate}
            >
                {this.__renderForm()}
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
            </CreateSomethingWrapper>
        );
    };

    __renderForm = () => {
        return (
            <Grid
                component="form"
                item
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {this.props.t('settings.senderEmail')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="email"
                        label={this.props.t('user.label.email')}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={this.state.mailSettings?.mail}
                        onChange={(e) => this.__onChangeEmail(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="password"
                        type="password"
                        label={this.props.t('user.label.password')}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={this.state.mailSettings?.password}
                        onChange={(e) =>
                            this.__onChangePassword(e.target.value)
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="service"
                        type="service"
                        label={this.props.t('settings.service')}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={this.state.mailSettings?.service}
                        onChange={(e) => this.__onChangeService(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="smtp"
                        type="smtp"
                        label={this.props.t('settings.smtp')}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={this.state.mailSettings?.smtp}
                        onChange={(e) => this.__onChangeServer(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="port"
                        type="port"
                        label={this.props.t('settings.port')}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        value={this.state.mailSettings?.port}
                        onChange={(e) => this.__onChangePort(e.target.value)}
                    />
                </Grid>
            </Grid>
        );
    };

    __onChangeEmail = (mail: string) => {
        if (this.state.mailSettings) {
            this.setState({
                mailSettings: {
                    ...this.state.mailSettings,
                    mail,
                },
            });
        }
    };

    __onChangePassword = (password: string) => {
        if (this.state.mailSettings) {
            this.setState({
                mailSettings: {
                    ...this.state.mailSettings,
                    password,
                },
            });
        }
    };

    __onChangeService = (service: string) => {
        if (this.state.mailSettings) {
            this.setState({
                mailSettings: {
                    ...this.state.mailSettings,
                    service,
                },
            });
        }
    };

    __onChangeServer = (smtp: string) => {
        if (this.state.mailSettings) {
            this.setState({
                mailSettings: {
                    ...this.state.mailSettings,
                    smtp,
                },
            });
        }
    };

    __onChangePort = (port: string) => {
        if (this.state.mailSettings) {
            this.setState({
                mailSettings: {
                    ...this.state.mailSettings,
                    port,
                },
            });
        }
    };

    __onPressCancel = () => {
        // this.props.history.goBack()
        this.props.history.push(UsersRouter.getRoute());
    };
    __onPressCreate = () => {
        if (!this.state.mailSettings) return;
        this.setState({
            information: {
                title: this.props.t('generic.msg.creating'),
                showProgress: true,
                message: `${this.props.t('generic.msg.updating')}...`,
            },
        });
        ServerRequests.setMailSettings(this.state.mailSettings)
            .then((settings) => {
                this.setState({
                    mailSettings: settings,
                    information: {
                        title: this.props.t('generic.msg.success'),
                        message: this.props.t('settings.updated'),
                        // onClose: () => this.setState({information: undefined})
                        onClose: () =>
                            this.props.history.push(UsersRouter.getRoute()),
                    },
                });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            });
    };

    __loadSettings = () => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                message: this.props.t('generic.msg.wait'),
                showProgress: true,
            },
        });

        ServerRequests.mailSettings('no-cache')
            .then((settings: MailSettings) => {
                this.setState({
                    information: undefined,
                    mailSettings: {
                        mail: settings.mail,
                        service: settings.service,
                        password: settings.password,
                        smtp: settings.smtp,
                        port: settings.port,
                    },
                });
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            });
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(SettingsPage)))
);
