import { ApolloProvider } from '@apollo/react-hoc';
import {
    CssBaseline,
    StyledEngineProvider,
    Theme,
    ThemeProvider,
} from '@mui/material';
import { AppLayout } from 'layouts/AppLayout';
import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { Client } from './apollo';
import './App.css';
import LocalizationProvider from './components/LocalizationProvider';
import { SnackbarProvider } from './contexts/SnackbarContext';
import DashboardPage from './pages/dashboard/dashboardPage';
import ForgottenPasswordPage from './pages/forgottenPassword/ForgottenPasswordPage';
import LoginPage from './pages/login/LoginPage';
import ResetPasswordPage from './pages/resetPassword/ResetPasswordPage';
import NoLoggedRoute from './route/NoLoggedRoute';
import PrivateRoute from './route/PrivateRoute';
import { LoginRouter } from './router';
import { DashboardRouter } from './router/dashboard/dashboard.router';
import { ForgottenPasswordRouter } from './router/forgottenPassword/forgottenPassword.router';
import { ResetPasswordRouter } from './router/resetPassword/resetPassword.router';
import theme from './theme';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

function App() {
    return (
        <Suspense fallback={true}>
            <ApolloProvider client={Client}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme()}>
                        <LocalizationProvider>
                            <SnackbarProvider>
                                <CssBaseline />
                                <AppLayout>
                                    <Router>
                                        <Switch>
                                            <NoLoggedRoute
                                                path={LoginRouter.getRoute()}
                                            >
                                                <Route
                                                    exact
                                                    component={LoginPage}
                                                />
                                            </NoLoggedRoute>
                                            <NoLoggedRoute
                                                path={ForgottenPasswordRouter.getRoute()}
                                            >
                                                <Route
                                                    exact
                                                    component={
                                                        ForgottenPasswordPage
                                                    }
                                                />
                                            </NoLoggedRoute>
                                            <NoLoggedRoute
                                                path={ResetPasswordRouter.getRoute()}
                                            >
                                                <Route
                                                    exact
                                                    component={
                                                        ResetPasswordPage
                                                    }
                                                />
                                            </NoLoggedRoute>
                                            <PrivateRoute
                                                path={DashboardRouter.getRoute()}
                                            >
                                                <Route
                                                    component={DashboardPage}
                                                />
                                            </PrivateRoute>
                                            <Route exact path="/">
                                                <Redirect
                                                    to={LoginRouter.getRoute()}
                                                />
                                            </Route>
                                        </Switch>
                                    </Router>
                                </AppLayout>
                            </SnackbarProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </ApolloProvider>
        </Suspense>
    );
}

export default App;
