import {
    Box,
    Button,
    Grid,
    InputAdornment,
    InputBase,
    Paper,
    Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
// import banniere from '../../assets/LOGO BLANC-300.png';
import banniere from '../../assets/LOGO BLANC-300.png';

const styles = () =>
    createStyles({
        paper: {
            backgroundColor: '#3e3c3cBB',
            color: '#ffffff',
        },
        input: {
            margin: 10,
            flex: 1,
            width: '95%',
        },
        iconButton: {
            marginRight: 10,
            marginLeft: 10,
            color: '#3e3c3cBB',
        },
        margin: {
            margin: 10,
        },
        forgottenPassword: {
            textDecorationLine: 'underline',
            color: '#ffffff',
        },
        logo: {
            maxWidth: 200,
        },
    });

interface IState {
    mail: string;

    password: string;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
    onClickConnection?: (mail: string, password: string) => void;
    onClickForgottenPassword?: (mail: string) => void;

    errorMsg?: string;
}

class LoginForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            mail: '',
            password: '',
        };
    }
    render = () => {
        const classes = this.props.classes;
        return (
            <Paper
                className={classes.paper}
                elevation={3}
                component="form"
                onSubmitCapture={(e) => {
                    e.preventDefault();
                    this.__onClickConnection();
                }}
            >
                <Box pb={5} pt={2}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <Box mx="auto" pb={5}>
                            <img
                                src={banniere}
                                alt="logo"
                                className={classes.logo}
                            />
                            {/* <Typography variant="h1">foodr</Typography> */}
                        </Box>
                        <Box px={5}>
                            <Paper>
                                <InputBase
                                    onChange={(e) =>
                                        this.setState({
                                            mail: e.target.value
                                                .trim()
                                                .replace(' ', ''),
                                        })
                                    }
                                    type="mail"
                                    color="primary"
                                    value={this.state.mail}
                                    className={classes.input}
                                    placeholder={this.props.i18n.t(
                                        'user.label.email'
                                    )}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <AccountCircleIcon
                                                className={classes.iconButton}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </Paper>
                        </Box>

                        <Box px={5} py={1}>
                            <Paper>
                                <InputBase
                                    onChange={(e) =>
                                        this.setState({
                                            password: e.target.value,
                                        })
                                    }
                                    type="password"
                                    className={classes.input}
                                    placeholder={this.props.i18n.t(
                                        'user.label.password'
                                    )}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LockIcon
                                                className={classes.iconButton}
                                            />
                                        </InputAdornment>
                                    }
                                />
                            </Paper>
                        </Box>
                        <Box p={2} mx="auto">
                            <Button
                                type="submit"
                                // onClick={this.__onClickConnection}
                                color="secondary"
                                variant="contained"
                                size="large"
                            >
                                {`${this.props.i18n.t('session.button.login')}`}
                            </Button>
                        </Box>
                        <Grid item>
                            <Box px={5}>
                                <Button
                                    onClick={() =>
                                        this.props.onClickForgottenPassword &&
                                        this.props.onClickForgottenPassword(
                                            this.state.mail
                                        )
                                    }
                                >
                                    <Typography
                                        variant="caption"
                                        className={classes.forgottenPassword}
                                    >
                                        {`${this.props.i18n.t(
                                            'session.button.forgotPassword'
                                        )}`}
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                        {this.props.errorMsg ? (
                            <Box mx="auto">
                                <Typography color="error">
                                    {this.props.errorMsg}
                                </Typography>
                            </Box>
                        ) : null}
                    </Grid>
                </Box>
            </Paper>
        );
    };

    __onClickConnection = () => {
        if (this.props.onClickConnection)
            this.props.onClickConnection(this.state.mail, this.state.password);
    };
}

export default withTranslation()(withStyles(styles)(LoginForm));
