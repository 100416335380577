import { FetchPolicy } from 'apollo-boost';
import { gql } from '__generated__/gql';
import { Client } from '.';
import {
    ActionLogFiltersInput,
    FetchingActionLogsOptionInput,
    InputMaybe,
} from '__generated__/graphql';

const QLogs = gql(`
    query actionLogs(
        $options: FetchingActionLogsOptionInput
        $conditions: ActionLogFiltersInput
    ) {
        actionLogs(options: $options, conditions: $conditions) {
            totalCount
            hasMore
            items {
                _id
                action
                description
                user {
                    _id
                    firstname
                    lastname
                    email
                }
                creationDate
            }
        }
    }
`);

export class ActionLogRequest {
    /**
     * Get action logs
     */
    public static async logs(
        conditions?: InputMaybe<ActionLogFiltersInput> | undefined,
        options?: InputMaybe<FetchingActionLogsOptionInput> | undefined,
        fetchPolicy?: FetchPolicy
    ) {
        return (
            await Client.query({
                query: QLogs,
                fetchPolicy,
                variables: {
                    options,
                    conditions,
                },
            })
        ).data.actionLogs;
    }
}
