import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material';
import ConfirmationDialog, {
    ConfirmationInformation,
} from '../../../components/confirmationDialog';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import { HospitalCentersQuery, RegionsQuery } from '__generated__/graphql';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/react-hoc';
import { WithTranslation, withTranslation } from 'react-i18next';

import { GraphQLError } from 'graphql';
import { HospitalCenterRequests } from '../../../apollo';
import HospitalRow from './hospitalRow';
import React from 'react';
import { RegionRequests } from 'apollo/RegionRequests';
import { UpdateHospitalCenterRouter } from '../../../router/dashboard/updateHospitalCenter/updateHospitalCenter.router';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) =>
    createStyles({
        table: {
            [theme.breakpoints.up('md')]: {
                maxHeight: '90vh',
            },
        },
    });

type HospitalCenters = HospitalCentersQuery['hospitalCenters'];
type HospitalCenter = HospitalCenters[0];
type Regions = RegionsQuery['regions'];
type Region = Regions[0];

interface IState {
    hospitals: Array<HospitalCenter>;
    regions: Array<Region>;
    error?: IErrorInformation;
    information?: IInformative;
    confirmation?: ConfirmationInformation;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class HospitalCentersPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hospitals: [],
            regions: [],
        };
    }

    componentDidMount = async () => {
        await this.__loadHospitalCenters();
        await this.__loadRegions();
    };

    render = () => {
        const classes = this.props.classes;

        return (
            <Container component={Box} mt={2}>
                <TableContainer component={Paper} className={classes.table}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    {this.props.t('hospital.label.name')}
                                </TableCell>
                                <TableCell>
                                    {this.props.t('hospital.label.address')}
                                </TableCell>
                                <TableCell>
                                    {this.props.t('hospital.label.region')}
                                </TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.hospitals.map(
                                (hospital: HospitalCenter, index) => {
                                    return (
                                        <HospitalRow
                                            key={index}
                                            onClickRemoveHospital={
                                                this.__onClickRemoveHospital
                                            }
                                            onClickUpdateHospital={
                                                this.__onClickUpdateHospital
                                            }
                                            hospital={hospital}
                                            availableRegions={
                                                this.state.regions
                                            }
                                        />
                                    );
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
                <ConfirmationDialog confirmation={this.state.confirmation} />
            </Container>
        );
    };

    __loadHospitalCenters = async () => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                showProgress: true,
            },
        });
        try {
            const hospitals =
                await HospitalCenterRequests.hospitalCenters('no-cache');
            this.setState((prevState) => ({ ...prevState, hospitals }));
        } catch (err: unknown) {
            if (err instanceof GraphQLError) {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            } else {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: `Unknown Error: ${err}`,
                    },
                });
            }
        } finally {
            this.setState({ information: undefined });
        }
    };

    __loadRegions = async () => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.loading'),
                showProgress: true,
            },
        });
        try {
            const { data: regions } =
                await RegionRequests.regions('cache-first');
            if (regions.regions) {
                this.setState((prevState) => ({
                    ...prevState,
                    regions: regions.regions,
                }));
            }
        } catch (err: unknown) {
            if (err instanceof GraphQLError) {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            } else {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: `Unknown Error: ${err}`,
                    },
                });
            }
        } finally {
            this.setState({ information: undefined });
        }
    };

    __onClickUpdateHospital = (hospital: HospitalCenter) => {
        this.props.history.push(
            UpdateHospitalCenterRouter.getRoute(hospital._id)
        );
    };

    __onClickRemoveHospital = (hospital: HospitalCenter) => {
        this.setState({
            confirmation: {
                title: this.props.t('generic.button.delete'),
                message: this.props.t('hospital.msg.confirmDelete'),
                onClickAccept: () =>
                    this.setState({ confirmation: undefined }, () =>
                        this.__onAccepToRemoveHospital(hospital)
                    ),
                onClickCancel: () => this.setState({ confirmation: undefined }),
            },
        });
    };

    __onAccepToRemoveHospital = (hospital: HospitalCenter) => {
        this.setState({
            information: {
                title: this.props.t('generic.msg.deleting'),
                showProgress: true,
            },
        });
        HospitalCenterRequests.removeHospitalCenter(hospital._id)
            .then(() => {
                this.setState(
                    { information: undefined },
                    this.__loadHospitalCenters
                );
            })
            .catch((err: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            });
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(HospitalCentersPage)))
);
