import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import { UserRequests } from '../apollo';
import { DashboardRouter } from '../router/dashboard/dashboard.router';

enum Status {
    LOGGED,
    NOLLOGED,
    WAITING,
}
interface IState {
    logged: Status;
}

interface Props {
    path: string;
    children?: React.ReactNode;
}

type IProps = WithApolloClient<Props>;

class NoLoggedRoute extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            logged: Status.WAITING,
        };
    }

    componentDidMount() {
        this.__loadUserData();
    }

    render = () => {
        return (
            <Route
                path={this.props.path}
                render={() => {
                    if (this.state.logged === Status.WAITING) {
                        return null;
                    } else if (this.state.logged === Status.NOLLOGED) {
                        return this.props.children;
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: DashboardRouter.getRoute(),
                                }}
                            />
                        );
                    }
                }}
            />
        );
    };

    __loadUserData = () => {
        UserRequests.meBasic('no-cache')
            .then(() => this.setState({ logged: Status.LOGGED }))
            .catch(() => this.setState({ logged: Status.NOLLOGED }));
    };
}

export default withApollo<IProps>(NoLoggedRoute);
