import { Box, Dialog, Grid, LinearProgress, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

const styles = () =>
    createStyles({
        logo: {
            height: 15,
        },
        placeLogo: {
            width: 100,
            height: 100,
        },
    });

export interface IInformative {
    title?: string /** Title of the modal */;
    message?: string | JSX.Element /** Message of the modal */;

    showProgress?:
        | boolean
        | undefined /** Boolean to set to show progress bar */;

    timeoutAutoclose?:
        | number
        | undefined
        | null /** if undefined 3000 ms. Set null to don't auto close */;

    onClose?: () => void;
}

interface IProps extends WithStyles<typeof styles> {
    information?: IInformative;

    onClose: () => void /** Function called when user whant close the dialog */;
}

type IState = Record<string, unknown>;

/**
 * Component used to render informative data
 */
class InformativeDialog extends React.Component<IProps, IState> {
    componentDidUpdate(prevProps: IProps, _: IState) {
        if (
            (!prevProps.information &&
                this.props.information &&
                this.props.information.timeoutAutoclose !== null &&
                this.props.information.showProgress !== true) ||
            (prevProps.information &&
                this.props.information &&
                prevProps.information.showProgress &&
                prevProps.information.showProgress !==
                    this.props.information.showProgress)
        ) {
            const timeout = this.props.information.timeoutAutoclose
                ? this.props.information.timeoutAutoclose
                : 3000;

            setTimeout(() => {
                if (this.props.information) {
                    if (this.props.information.onClose)
                        this.props.information.onClose();
                    this.props.onClose();
                }
            }, timeout);
        }
    }

    render = () => {
        return (
            <Dialog
                open={this.props.information !== undefined}
                onClose={() => this.__onClose()}
            >
                <Box margin={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="h5">
                                {this.props.information?.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center">
                                {this.props.information?.message}
                            </Typography>
                        </Grid>
                        {this.props.information?.showProgress && (
                            <Grid item xs={12}>
                                <LinearProgress color="secondary" />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Dialog>
        );
    };

    __onClose = () => {
        if (this.props.information?.onClose) this.props.information.onClose();

        if (this.props.onClose) this.props.onClose();
    };
}

export default withStyles(styles)(InformativeDialog);
