import { FetchPolicy } from 'apollo-boost';
import { Client } from '.';
import { gql } from '__generated__/gql';
import { MailSettingsUpdateSchema } from '__generated__/graphql';

const QMailSettings = gql(`
    query mailSettings {
        mailSettings {
            mail
            password
            service
            smtp
            port
        }
    }
`);

const MSetMailSettings = gql(`
    mutation setMailSettings($settings: MailSettingsUpdateSchema!) {
        setMailSettings(settings: $settings) {
            mail
            password
            service
            smtp
            port
        }
    }
`);

const MContactUs = gql(`
                    mutation contactUs($content: String!, $subject: String!) {
                        contactUs(content: $content, subject: $subject)
                    }
                `);

export class ServerRequests {
    public static async setMailSettings(
        mailSettings: MailSettingsUpdateSchema
    ) {
        return (
            await Client.mutate({
                mutation: MSetMailSettings,
                variables: {
                    settings: mailSettings,
                },
            })
        ).data!.setMailSettings;
    }

    /**
     * Get my basic information
     */
    public static async mailSettings(fetchPolicy?: FetchPolicy) {
        return (
            await Client.query({
                query: QMailSettings,
                fetchPolicy,
            })
        ).data.mailSettings;
    }

    /**
     * Contact administrators
     * @param subject Subject of the contact
     * @param content Content of the contact
     * @returns True if message is sent
     */
    public static async contactUs(subject: string, content: string) {
        return (
            await Client.mutate({
                mutation: MContactUs,
                variables: {
                    subject,
                    content,
                },
            })
        ).data!.contactUs;
    }
}
