import React, { Fragment } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { ActionLogsRouter } from '../../router/dashboard/logs/action-logs.router';
import AppBar from './appBar';
import CreateHospitalCenterPage from './createHospitalCenter/createHospitalCenterPage';
import { CreateHospitalCenterRouter } from '../../router/dashboard/createHospitalCenter/createHospitalCenter.router';
import CreatePatientRecordPage from './createPatientRecord/createPatientRecordPage';
import { CreatePatientRecordRouter } from '../../router/dashboard/createPatientRecord/createPatientRecord.router';
import CreateUserPage from './createUser/createUserPage';
import { CreateUserRouter } from '../../router/dashboard/createUser/createUser.router';
import { GuideRouter } from '../../router/dashboard/guide/guide.router';
import HospitalCentersPage from './hospitalCenters/hospitalCentersPage';
import { HospitalCentersRouter } from '../../router/dashboard/hospitalCenters/hospitalCenters.router';
import LogsPage from './logs-page/logsPage';
import PatientRecordPage from './patientRecord/patientRecordPage';
import { PatientRecordRouter } from '../../router/dashboard/patientRecord/patientRecord.router';
import PatientRecordsPage from './patientRecords/patientRecordsPage';
import { PatientRecordsRouter } from '../../router/dashboard/patientRecords/patientRecords.router';
import RegionsPage from './regions';
import { RegionsRouter } from 'router/dashboard/regions/regions.router';
import SettingsPage from './settings/settingsPage';
import { SettingsRouter } from '../../router/dashboard/settings/settings.router';
import SideBar from './sideBar';
import UpdateHospitalCenterPage from './updateHospitalCenter/updateHospitalCenterPage';
import { UpdateHospitalCenterRouter } from '../../router/dashboard/updateHospitalCenter/updateHospitalCenter.router';
import UpdatePatientRecordPage from './updatePatientRecord/updatePatientRecordPage';
import { UpdatePatientRecordRouter } from '../../router/dashboard/updatePatientRecord/updatePatientRecord.router';
import UpdateUserPage from './updateUser/updateUserPage';
import { UpdateUserRouter } from '../../router/dashboard/updateUser/updateUsers.router';
import UserGuidePage from './user-guide-page';
import UsersPage from './users/usersPage';
import { UsersRouter } from '../../router/dashboard/users/users.router';

interface IProps extends RouteComponentProps, RouteComponentProps {}

interface IState {
    drawerOpen: boolean;
}

class DashboardPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            drawerOpen: false,
        };
    }

    componentDidMount = () => undefined;

    render = () => {
        return (
            <Fragment>
                <AppBar
                    onClickMenu={() =>
                        this.setState({ drawerOpen: !this.state.drawerOpen })
                    }
                    onClickBack={this.__onClickBack}
                />
                <Switch>
                    <Route
                        path={CreateHospitalCenterRouter.getRoute()}
                        component={CreateHospitalCenterPage}
                    />
                    <Route
                        exact
                        path={CreateUserRouter.getRoute()}
                        component={CreateUserPage}
                    />
                    <Route
                        exact
                        path={CreatePatientRecordRouter.getRoute()}
                        component={CreatePatientRecordPage}
                    />
                    <Route
                        exact
                        path={HospitalCentersRouter.getRoute()}
                        component={HospitalCentersPage}
                    />
                    <Route
                        exact
                        path={PatientRecordRouter.getRoute()}
                        component={PatientRecordPage}
                    />
                    <Route
                        exact
                        path={PatientRecordsRouter.getRoute()}
                        component={PatientRecordsPage}
                    />
                    <Route
                        exact
                        path={UpdateHospitalCenterRouter.getRoute()}
                        component={UpdateHospitalCenterPage}
                    />
                    <Route
                        exact
                        path={UpdateUserRouter.getRoute()}
                        component={UpdateUserPage}
                    />
                    <Route
                        exact
                        path={UpdatePatientRecordRouter.getRoute()}
                        component={UpdatePatientRecordPage}
                    />
                    <Route
                        exact
                        path={UsersRouter.getRoute()}
                        component={UsersPage}
                    />
                    <Route
                        exact
                        path={HospitalCentersRouter.getRoute()}
                        component={HospitalCentersPage}
                    />
                    <Route
                        exact
                        path={RegionsRouter.getRoute()}
                        component={RegionsPage}
                    />
                    <Route
                        exact
                        path={SettingsRouter.getRoute()}
                        component={SettingsPage}
                    />
                    <Route
                        exact
                        path={GuideRouter.getRoute()}
                        component={UserGuidePage}
                    />
                    <Route
                        exact
                        path={ActionLogsRouter.getRoute()}
                        component={LogsPage}
                    />
                    <Route>
                        <Redirect to={PatientRecordsRouter.getRoute()} />
                    </Route>
                </Switch>
                <SideBar
                    open={this.state.drawerOpen}
                    onClose={() => this.setState({ drawerOpen: false })}
                />
            </Fragment>
        );
    };

    __onClickBack = () => {
        this.props.history.goBack();
    };
}

export default withRouter(DashboardPage);
