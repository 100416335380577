// import { LocalizationProvider as LP } from '@mui/lab';
import i18n from 'i18n';
import React, { useState } from 'react';
import { I18nextProvider } from 'react-i18next';
// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';

//-----------------------|| LOCALIZATION ||-----------------------//
export interface LocalsProps {
    children: React.ReactNode;
}

// load locales files
// const loadLocaleData = (locale?: string) => {
// 	switch (locale) {
// 		default:
// 			return import('../assets/locales/en/translation.json');
// 	}
// };

const LocalizationProvider = ({ children }: LocalsProps) => {
    const [messages] = useState<
        | Record<string, string>
        | Record<string, MessageFormatElement[]>
        | undefined
    >();

    // useEffect(() => {
    // 	loadLocaleData().then(
    // 		(d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
    // 			setMessages(d.default);
    // 		}
    // 	);

    // }, [])
    return (
        <React.Fragment>
            <I18nextProvider i18n={i18n}>
                {/* <LP locale={locale} dateAdapter={AdapterDateFns}> */}
                <IntlProvider
                    locale="en"
                    defaultLocale="en"
                    messages={messages}
                >
                    {children}
                </IntlProvider>
                {/* </LP> */}
            </I18nextProvider>
        </React.Fragment>
    );
};

export default LocalizationProvider;
