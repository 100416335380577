import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as pkg from '../../../package.json';
import { UserRequests } from '../../apollo';
import Background from '../../assets/login-background.jpg';
import ErrorDialog, { IErrorInformation } from '../../components/errorDialog';
import InformativeDialog, {
    IInformative,
} from '../../components/informativeDialog';
import { LoginRouter } from '../../router';
import LoginForm from './ForgottenPasswordForm';

const styles = () =>
    createStyles({
        div: {
            backgroundImage: `url(${Background})`,
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            flexDirection: 'column-reverse',
        },
        grid: {
            height: '100%',
        },
        paper: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        form: {},
        progress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        version: {
            color: '#ffffff',
            fontWeight: 'bold',
            padding: 10,
        },
    });

interface IState {
    information?: IInformative;
    error?: IErrorInformation;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithApolloClient<Record<string, unknown>>,
        WithTranslation {}

class ForgottenPasswordPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        this.__loadUserData();
    };

    render = () => {
        const classes = this.props.classes;

        return (
            <div className={classes.div}>
                <Grid className={classes.version}>v {pkg.version}</Grid>
                <Grid
                    container
                    justifyContent="center"
                    className={classes.grid}
                >
                    <Grid
                        item
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        container
                        xs={10}
                        md={4}
                    >
                        <LoginForm
                            onClickSignin={this.__onClickSignin}
                            onClickResetPassword={this.__onClickResetPassword}
                        />
                    </Grid>
                    <InformativeDialog
                        onClose={() => undefined}
                        information={this.state.information}
                    />
                    <ErrorDialog
                        onClose={() => this.setState({ error: undefined })}
                        error={this.state.error}
                    />
                </Grid>
            </div>
        );
    };

    __onClickResetPassword = async (mail: string) => {
        this.setState({
            information: {
                showProgress: true,
                title: this.props.t('generic.msg.loading'),
            },
        });

        UserRequests.forgottenPassword(mail)
            .then(() => {
                this.setState({
                    information: {
                        showProgress: false,
                        message: this.props.t('user.msg.pwdResetEmailSent'),
                        onClose: () =>
                            this.props.history.push(LoginRouter.getRoute()),
                    },
                });
            })
            .catch((e: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: e.message,
                    },
                });
            });
    };

    __onClickSignin = async () => {
        this.props.history.push(LoginRouter.getRoute());
    };

    __loadUserData = () => {
        // UserRequests.meBasic().then(() => this.props.history.push("/dashboard")).catch(() => undefined);
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(ForgottenPasswordPage)))
);
