import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import {
    Cancel,
    CheckCircle,
    PrecisionManufacturing,
} from '@mui/icons-material';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { FragmentType, useFragment } from '__generated__';
import { gql } from '__generated__/gql';
import { TargetingCommandsFragmentFragment } from '__generated__/graphql';
import { PatientRecordRequests } from 'apollo';
import ConfirmationDialog, {
    ConfirmationInformation,
} from 'components/confirmationDialog';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TargetingCommandsFragment = gql(`
    fragment TargetingCommandsFragment on PatientRecordSchema {
        _id
        targetingWasApproved
        targetingWasRejected
        targetingReportFinalized
        hasPostOpIrm
        hasTargetedPostOpIrm
    }
`);

const Approve = gql(`
    mutation approveAutomatedTargeting($idRecord: String!) {
        approveAutomatedTargeting(idRecord: $idRecord)
    }
`);

const Reject = gql(`
    mutation rejectAutomatedTargeting($idRecord: String!) {
        rejectAutomatedTargeting(idRecord: $idRecord)
    }
`);

const ForceAutomatedTargeting = gql(`
    mutation forceAutomatedTargeting($idRecord: String!) {
        forceAutomatedTargeting(idRecord: $idRecord)
    }
`);

export type TargetingReportProps = {
    record: FragmentType<typeof TargetingCommandsFragment> | undefined;
    refetchQueries?: InternalRefetchQueriesInclude;
};

export default function TargetingCommands(props: TargetingReportProps) {
    const { t } = useTranslation();
    const record = useFragment(TargetingCommandsFragment, props.record);
    const commands = useCommands({
        record,
        refetchQueries: props.refetchQueries,
    });
    const [confirmation, setConfirmation] = useState<ConfirmationInformation>();

    const onApprove = useCallback(() => {
        setConfirmation({
            title: t('automaton.commands.approve MRI confirmation title'),
            message: t('automaton.commands.approve MRI confirmation message'),
            onClickAccept: () => {
                setConfirmation(undefined);
                commands.approve();
            },
            onClickCancel: () => setConfirmation(undefined),
        });
    }, [commands.approve]);

    const onReject = useCallback(() => {
        setConfirmation({
            title: t('automaton.commands.reject MRI confirmation title'),
            message: t('automaton.commands.reject MRI confirmation message'),
            onClickAccept: () => {
                setConfirmation(undefined);
                commands.reject();
            },
            onClickCancel: () => setConfirmation(undefined),
        });
    }, [commands.reject]);

    const onForceAutomatedTargeting = useCallback(() => {
        setConfirmation({
            title: t(
                'automaton.commands.force computing MRI confirmation title'
            ),
            message: t(
                'automaton.commands.force computing MRI confirmation message'
            ),
            onClickAccept: () => {
                commands.forceAutomatedTargeting();
                setConfirmation(undefined);
            },
            onClickCancel: () => setConfirmation(undefined),
        });
    }, [commands.forceAutomatedTargeting]);

    return (
        <>
            <ConfirmationDialog
                confirmation={confirmation}
                onClose={() => setConfirmation(undefined)}
            />
            <Grid item xs={12} container spacing={2}>
                <Grid item sm={5}>
                    <Typography variant="h6">
                        {t('automaton.commands.title')}:
                    </Typography>
                </Grid>
                <Grid item>
                    <ButtonGroup
                        variant="contained"
                        color="secondary"
                        disabled={
                            record?.hasPostOpIrm || record?.hasTargetedPostOpIrm
                        }
                    >
                        <Button
                            onClick={onApprove}
                            startIcon={<CheckCircle />}
                            disabled={
                                !record?.targetingReportFinalized ||
                                record?.targetingWasApproved ||
                                record == null
                            }
                        >
                            <Typography variant="caption">Approve</Typography>
                        </Button>
                        <Button
                            onClick={onReject}
                            startIcon={<Cancel />}
                            disabled={
                                !record?.targetingReportFinalized ||
                                record?.targetingWasRejected ||
                                record == null
                            }
                        >
                            <Typography variant="caption">Reject</Typography>
                        </Button>
                        <Button
                            onClick={onForceAutomatedTargeting}
                            startIcon={<PrecisionManufacturing />}
                            disabled={record == null}
                        >
                            <Typography variant="caption">
                                Force targeting
                            </Typography>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </>
    );
}

type useCommandParams = {
    record: TargetingCommandsFragmentFragment | null | undefined;
    refetchQueries?: InternalRefetchQueriesInclude;
};

function useCommands(params: useCommandParams) {
    const [approveMutation] = useMutation(Approve, {
        refetchQueries: params.refetchQueries,
    });
    const [rejectMutation] = useMutation(Reject, {
        refetchQueries: params.refetchQueries,
    });
    const [forceAutomatedTargetingMutation] = useMutation(
        ForceAutomatedTargeting,
        {
            refetchQueries: params.refetchQueries,
        }
    );

    const approve = useCallback(async () => {
        if (params.record == null) {
            return;
        }
        await approveMutation({
            variables: {
                idRecord: params.record._id,
            },
        });
        await PatientRecordRequests.sendEndOfProcessingMail(params.record!._id);
    }, [params.record?._id]);

    const reject = useCallback(async () => {
        if (params.record == null) {
            return;
        }
        await rejectMutation({
            variables: {
                idRecord: params.record._id,
            },
        });
    }, [params.record?._id]);

    const forceAutomatedTargeting = useCallback(async () => {
        if (params.record == null) {
            return;
        }
        await forceAutomatedTargetingMutation({
            variables: {
                idRecord: params.record._id,
            },
        });
    }, [params.record?._id]);

    return { approve, reject, forceAutomatedTargeting };
}
