import { FetchPolicy, useMutation, useQuery } from '@apollo/client';

import { Client } from '.';
import { gql } from '__generated__/gql';

export const QUERY_REGIONS = gql(`
    query regions {
        regions {
            _id
            name
            nbHospitals
            hospitals {
                name
            }
            nbCommercials
            creationDate
            lastUpdate
        }
    }
`);

export const QUERY_FIND_REGION = gql(`
    query findRegion($regionId: Float!) {
        findRegion(regionId: $regionId) {
            _id
            name
        }
    }
`);

export const MUTATION_CREATE_REGION = gql(`
    mutation createRegion(
        $regionData: RegionCreateSchema!
    ) {
        createRegion(regionData: $regionData) {
            _id
            name
        }
    }
`);

export const MUTATION_REMOVE_REGION = gql(`
    mutation removeRegion($regionId: Float!) {
        removeRegion(regionId: $regionId)
    }
`);

/** @deprecated Only exists for compatibility with the old code
 * (prefer using the `useRegions` hook instead) */
export class RegionRequests {
    public static async regions(fetchPolicy?: FetchPolicy) {
        return await Client.query({
            query: QUERY_REGIONS,
            fetchPolicy,
        });
    }
}

export const useRegions = () =>
    useQuery(QUERY_REGIONS, {
        fetchPolicy: 'cache-first',
    });

export const useCreateRegion = () =>
    useMutation(MUTATION_CREATE_REGION, {
        refetchQueries: [{ query: QUERY_REGIONS }],
    });

export const useRemoveRegion = () =>
    useMutation(MUTATION_REMOVE_REGION, {
        refetchQueries: [{ query: QUERY_REGIONS }],
    });
