export function isToday(date: string | null | undefined) {
    if (!date) return false;
    const today = new Date();
    const otherDate = new Date(date);
    return (
        today.getDate() === otherDate.getDate() &&
        today.getMonth() === otherDate.getMonth() &&
        today.getFullYear() === otherDate.getFullYear()
    );
}
