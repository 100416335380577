import DescriptionIcon from '@mui/icons-material/Description';
import { useFragment, FragmentType } from '__generated__';
import { Button, Grid, Typography } from '@mui/material';
import { gql } from '__generated__/gql';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileListDialog from 'components/FileListDialog';
import { PatientRecordTargetingStatus } from '__generated__/graphql';

const TargetedMriFragment = gql(`
    fragment TargetedMriFragment on PatientRecordSchema {
        patientNumber
        targetingStatus
        originalMriTargetedUrl
        originalMRITargetedFileName
    }
`);

export type TargetedMriProps = {
    record: FragmentType<typeof TargetedMriFragment> | undefined;
};

export default function TargetedMri(props: TargetedMriProps) {
    const { t } = useTranslation();
    const [isDownloadShown, setShowDownload] = useState(false);
    const showDownload = useCallback(() => setShowDownload(true), []);
    const hideDownload = useCallback(() => setShowDownload(false), []);
    const record = useFragment(TargetedMriFragment, props.record);
    const fileUrl = record?.originalMriTargetedUrl;
    const mriName =
        record?.originalMRITargetedFileName ??
        t('automaton.default targeted MRI filename', record);

    let fileListProps;
    if (fileUrl && isDownloadShown) {
        fileListProps = {
            files: [mriName],
            title: t('automaton.targeted MRI title'),
            urlToDownload: fileUrl,
        };
    }

    return (
        <>
            <FileListDialog
                fileListProps={fileListProps}
                onClose={hideDownload}
            />
            <Grid item xs={12} container spacing={2}>
                <Grid item sm={5}>
                    <Typography variant="h6">
                        {t('pr.label.targMRI')}:
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        onClick={showDownload}
                        variant="contained"
                        color="secondary"
                        startIcon={<DescriptionIcon />}
                        disabled={
                            record?.originalMriTargetedUrl == null ||
                            record.targetingStatus ==
                                PatientRecordTargetingStatus.OperatedManually
                        }
                    >
                        <Typography variant="caption">
                            {t('display files')}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
