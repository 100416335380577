import * as MU from '@mui/material';
import React from 'react';
import Header from 'components/header';
interface IProps {
    onClickMenu?: () => void;

    onClickBack?: () => void;

    initialcurrentRoute?: string;
}

const AppBar = (props: IProps) => {
    return (
        <MU.AppBar position="static">
            <Header {...props} />
        </MU.AppBar>
    );
};

export default AppBar;
