import { Grid, Typography } from '@mui/material';
import { gql } from '__generated__/gql';
import { PatientRecordQuery } from '__generated__/graphql';
import { useTranslation } from 'react-i18next';
import { Unpacked } from 'types/helpers';
import { FragmentType, useFragment } from '__generated__';

const OperatorFeedbackFragment = gql(`
    fragment OperatorFeedbackFragment on PatientRecordSchema {
        targetingOperatorFeedback {
            title
            body
            color
        }
    }
`);

export type OperatorFeedbackProps = {
    record: FragmentType<typeof OperatorFeedbackFragment> | undefined;
};

export default function OperatorFeedback(props: OperatorFeedbackProps) {
    const { t } = useTranslation();
    const record = useFragment(OperatorFeedbackFragment, props.record);
    const nbFeedbacks = record?.targetingOperatorFeedback.length ?? 0;
    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item sm={5}>
                <Typography variant="h6">
                    {t('automaton.feedback.title')}:
                </Typography>
            </Grid>
            <Grid item>
                {nbFeedbacks == 0 && <NoFeedback />}
                {record?.targetingOperatorFeedback?.map((feedback) => (
                    <AutomatonFeedbackEntry
                        key={feedback.title}
                        feedback={feedback}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

type AutomatonFeedbacks =
    PatientRecordQuery['patientRecord']['targetingOperatorFeedback'];
type AutomatonFeedback = Unpacked<AutomatonFeedbacks>;

type AutomatonFeedbackEntryProps = {
    feedback: AutomatonFeedback;
};

function AutomatonFeedbackEntry(props: AutomatonFeedbackEntryProps) {
    return (
        <>
            <Typography
                variant="body1"
                sx={{ color: props.feedback.color, fontWeight: 'bold' }}
            >
                {props.feedback.title}
            </Typography>
            <Typography variant="body1" sx={{ color: props.feedback.color }}>
                {props.feedback.body}
            </Typography>
        </>
    );
}

function NoFeedback() {
    const { t } = useTranslation();
    return (
        <Typography variant="body1">{t('automaton.feedback.empty')}</Typography>
    );
}
