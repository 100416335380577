import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import {
    Button,
    ButtonGroup,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import ConfirmationDialog, {
    ConfirmationInformation,
} from 'components/confirmationDialog';
import JSZip from 'jszip';
import path from 'path';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PatientRecordRequests } from '../../../apollo';
import CreateSomethingWrapper from '../../../components/createSomethingWrapper';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import FileListDialog, {
    FileListDialogProps,
} from '../../../components/FileListDialog';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import { PatientRecordsRouter } from '../../../router/dashboard/patientRecords/patientRecords.router';
import {
    isValidPathologie,
    isValidSide,
    isValidSurgeryType,
    isValidTarget,
} from 'types/check';
import {
    PatientRecordCreateSchema,
    PatientRecordPathology,
    PatientRecordSide,
    PatientRecordTarget,
    PatientRecordTypeOfSurgery,
} from '__generated__/graphql';
import { IS_DEV } from 'config';
import { getEnumKeys } from 'types/helpers';

const styles = (theme: Theme) =>
    createStyles({
        circle: {
            display: 'inline-block',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            width: 5,
            height: 5,
        },
    });

interface IState {
    patientRecord: PatientRecordCreateSchema;
    newNoTargetedIrm?: Array<File> | undefined;

    fieldErrors?: { [key: string]: string };
    error?: IErrorInformation;
    information?: IInformative;
    fileList?: FileListDialogProps;

    confirmation?: ConfirmationInformation;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class CreatePatientRecordPage extends React.Component<IProps, IState> {
    _folderInput: HTMLInputElement | null = null;
    _fileInput: HTMLInputElement | null = null;

    constructor(props: IProps) {
        super(props);
        this.state = {
            patientRecord: {},
        };
    }

    componentDidMount = () => undefined;

    setFolderInput = (ref: HTMLInputElement | null) => {
        this._folderInput = ref;
        if (this._folderInput) {
            // Allow folder uploads
            this._folderInput.webkitdirectory = true;
            this._folderInput.multiple = true;
            this._folderInput.type = 'file';
        }
    };
    setFileInput = (ref: HTMLInputElement | null) => {
        this._fileInput = ref;
        if (this._fileInput) {
            this._fileInput.multiple = true;
            this._fileInput.type = 'file';
        }
    };

    render = () => {
        return (
            <div>
                {IS_DEV && (
                    <Alert severity="info">
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Typography>
                                {this.props.t('generic.debug.devEnv')}.
                                {this.props.t('generic.debug.autofillMsg')}
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.__onClickFillForm}
                            >
                                {this.props.t('generic.debug.autofillButton')}
                            </Button>
                        </Stack>
                    </Alert>
                )}
                <CreateSomethingWrapper
                    title={this.props.t('pr.edit.create')}
                    onPressCancel={this.__onPressCancel}
                    onPressCreate={this.__onPressCreate}
                >
                    {this.__renderForm()}
                    <ErrorDialog
                        error={this.state.error}
                        onClose={() => this.setState({ error: undefined })}
                    />
                    <InformativeDialog
                        information={this.state.information}
                        onClose={() => undefined}
                    />
                    <FileListDialog
                        fileListProps={this.state.fileList}
                        onClose={() => this.setState({ fileList: undefined })}
                    />
                </CreateSomethingWrapper>
                <ConfirmationDialog
                    confirmation={this.state.confirmation}
                    onClose={() => this.setState({ confirmation: undefined })}
                />
            </div>
        );
    };

    __renderForm = () => {
        return (
            <form>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={
                                this.state.fieldErrors?.firstname !== undefined
                            }
                            helperText={this.state.fieldErrors?.firstname}
                            id="firstname"
                            label={this.props.t('generic.label.firstname')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            value={this.state.patientRecord.firstname ?? ''}
                            onChange={(e) =>
                                this.__onChangeFirstName(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={
                                this.state.fieldErrors?.lastname !== undefined
                            }
                            helperText={this.state.fieldErrors?.lastname}
                            id="lastname"
                            label={this.props.t('generic.label.lastname')}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.patientRecord.lastname ?? ''}
                            onChange={(e) =>
                                this.__onChangeLastName(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={
                                this.state.fieldErrors?.pathologie !== undefined
                            }
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="pathologie-native-simple"
                            >
                                {this.props.t('pr.label.pathology')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={
                                    this.state.patientRecord.pathologie ?? ''
                                }
                                onChange={(e) =>
                                    this.__onChangePathologie(e.target.value)
                                }
                                inputProps={{ id: 'pathologie-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'pr.label.pathology'
                                        )}
                                    />
                                }
                            >
                                {getEnumKeys(PatientRecordPathology).map(
                                    (enumKey) => (
                                        <MenuItem
                                            key={enumKey}
                                            value={
                                                PatientRecordPathology[enumKey]
                                            }
                                        >
                                            {this.props.t(
                                                PatientRecordPathology[enumKey]
                                            )}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.pathologie}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={this.state.fieldErrors?.target !== undefined}
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="target-native-simple"
                            >
                                {this.props.t('pr.label.target')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.patientRecord.target ?? ''}
                                onChange={(e) =>
                                    this.__onChangeTarget(e.target.value)
                                }
                                inputProps={{ id: 'target-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('pr.label.target')}
                                    />
                                }
                            >
                                {this.state.patientRecord.pathologie ===
                                    PatientRecordPathology.Parkinson && (
                                    <MenuItem value={PatientRecordTarget.Stn}>
                                        {this.props.t(PatientRecordTarget.Stn)}
                                    </MenuItem>
                                )}
                                <MenuItem value={PatientRecordTarget.Vim}>
                                    {this.props.t(PatientRecordTarget.Vim)}
                                </MenuItem>
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.target}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={this.state.fieldErrors?.side !== undefined}
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="side-native-simple"
                            >
                                {this.props.t('pr.label.side')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.patientRecord.side ?? ''}
                                onChange={(e) =>
                                    this.__onChangeSide(e.target.value)
                                }
                                inputProps={{ id: 'side-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('pr.label.side')}
                                    />
                                }
                            >
                                {getEnumKeys(PatientRecordSide).map(
                                    (enumKey) => (
                                        <MenuItem
                                            key={enumKey}
                                            value={PatientRecordSide[enumKey]}
                                        >
                                            {this.props.t(
                                                PatientRecordSide[enumKey]
                                            )}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.side}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={
                                this.state.fieldErrors?.typeOfSurgery !==
                                undefined
                            }
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="typeOfSurgery-native-simple"
                            >
                                {this.props.t('pr.label.surgeryType')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={
                                    this.state.patientRecord.typeOfSurgery ?? ''
                                }
                                onChange={(e) =>
                                    this.__onChangeTypeOfSurgery(e.target.value)
                                }
                                inputProps={{
                                    id: 'typeOfSurgery-native-simple',
                                }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'pr.label.surgeryType'
                                        )}
                                    />
                                }
                            >
                                {getEnumKeys(PatientRecordTypeOfSurgery).map(
                                    (enumKey) => (
                                        <MenuItem
                                            key={enumKey}
                                            value={
                                                PatientRecordTypeOfSurgery[
                                                    enumKey
                                                ]
                                            }
                                        >
                                            {this.props.t(
                                                PatientRecordTypeOfSurgery[
                                                    enumKey
                                                ]
                                            )}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors?.typeOfSurgery}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} container spacing={2}>
                        <Grid item sm={3}>
                            <Typography variant="h6">
                                {this.props.t('pr.label.origMRI')}:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonGroup variant="contained" color="secondary">
                                <Button
                                    startIcon={<AddCircleIcon />}
                                    // component="span"
                                    onClick={() =>
                                        this._fileInput &&
                                        this._fileInput.click()
                                    }
                                >
                                    <input
                                        ref={this.setFileInput}
                                        onChange={(e) =>
                                            this.__onUploadNoTargetedIrm(
                                                Array.prototype.slice.call(
                                                    e.target.files
                                                )
                                            )
                                        }
                                        multiple
                                        type="file"
                                        hidden
                                    />
                                    <Typography variant="caption">
                                        {this.props.t('pr.button.newFile')}
                                    </Typography>
                                </Button>
                                <Button
                                    onClick={() =>
                                        this._folderInput &&
                                        this._folderInput.click()
                                    }
                                >
                                    <input
                                        ref={this.setFolderInput}
                                        onChange={(e) =>
                                            this.__onUploadNoTargetedIrm(
                                                Array.prototype.slice.call(
                                                    e.target.files
                                                )
                                            )
                                        }
                                        multiple
                                        type="file"
                                        hidden
                                    />
                                    <Typography variant="caption">
                                        {this.props.t('pr.button.folder')}
                                    </Typography>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<DescriptionIcon />}
                                // href={`${process.env.REACT_APP_SERVER}/patient-record/targeted-irm/${record._id}`}
                                variant="contained"
                                color="secondary"
                                onClick={this.__onClickShowIrm}
                                disabled={!this.state.newNoTargetedIrm}
                            >
                                <Typography variant="caption">
                                    {this.props.t('pr.showFiles.button')}
                                </Typography>
                            </Button>
                        </Grid>
                        {this.state.newNoTargetedIrm &&
                        this.state.newNoTargetedIrm
                            .map((a) => a.size)
                            .reduce((a, b) => a + b, 0) >
                            Number(process.env.REACT_APP_MAX_UPLOAD_SIZE) ? (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {this.props.t('pr.msg.sizeError')}.
                                </Alert>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    {this.props.t('pr.msg.sizeLimit')}.
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </form>
        );
    };

    __onUploadNoTargetedIrm = (acceptedFiles: File[]) => {
        this.setState({
            confirmation: {
                // title: this.props.t('pr.edit.filesToSave')
                message: `${this.props.t('pr.edit.checkAnon')}.`,
                onClickAccept: () => {
                    this.setState({ confirmation: undefined });
                    if (acceptedFiles.length >= 1) {
                        this.setState({ newNoTargetedIrm: acceptedFiles });
                    }
                },
                onClickCancel: () => this.setState({ confirmation: undefined }),
            },
        });
    };

    __onChangeFirstName = (firstName: string) => {
        if (this.state.patientRecord) {
            this.setState({
                patientRecord: {
                    ...this.state.patientRecord,
                    firstname: firstName,
                },
            });
        }
    };

    __onChangeLastName = (lastName: string) => {
        if (this.state.patientRecord) {
            this.setState({
                patientRecord: {
                    ...this.state.patientRecord,
                    lastname: lastName,
                },
            });
        }
    };

    __onChangePathologie = (pathologie: string | null) => {
        if (!isValidPathologie(pathologie)) {
            throw TypeError(
                `Invalid pathologie ${pathologie}, accepted values ${Object.entries(
                    PatientRecordPathology
                )}`
            );
        }
        if (this.state.patientRecord) {
            const target = specialCaseTarget(
                this.state.patientRecord?.target,
                pathologie
            );
            this.setState({
                patientRecord: {
                    ...this.state.patientRecord,
                    pathologie,
                    target,
                },
            });
        }
    };

    __onChangeTarget = (target: string | null) => {
        if (!isValidTarget(target)) {
            throw TypeError(
                `Invalid target ${target}, accepted values ${Object.entries(
                    PatientRecordPathology
                )}`
            );
        }
        if (this.state.patientRecord) {
            const specialTarget = specialCaseTarget(
                target,
                this.state.patientRecord.pathologie
            );
            this.setState({
                patientRecord: {
                    ...this.state.patientRecord,
                    target: specialTarget,
                },
            });
        }
    };

    __onChangeSide = (side: string | null) => {
        if (!isValidSide(side)) {
            throw TypeError(
                `Invalid pathologie ${side}, accepted values ${Object.entries(
                    PatientRecordSide
                )}`
            );
        }
        if (this.state.patientRecord) {
            this.setState({
                patientRecord: {
                    ...this.state.patientRecord,
                    side,
                },
            });
        }
    };

    __onChangeTypeOfSurgery = (typeOfSurgery: string | null) => {
        if (!isValidSurgeryType(typeOfSurgery)) {
            throw TypeError(
                `Invalid surgery type ${typeOfSurgery}, accepted values ${Object.entries(
                    PatientRecordTypeOfSurgery
                )}`
            );
        }
        if (this.state.patientRecord) {
            this.setState({
                patientRecord: {
                    ...this.state.patientRecord,
                    typeOfSurgery,
                },
            });
        }
    };

    __onClickFillForm = () => {
        this.setState({
            patientRecord: {
                firstname: 'John',
                lastname: 'Doe',
                pathologie: PatientRecordPathology.Parkinson,
                target: PatientRecordTarget.Stn,
                side: PatientRecordSide.Left,
                typeOfSurgery: PatientRecordTypeOfSurgery.Dbs,
            },
        });
    };

    __onClickShowIrm = () => {
        this.setState({
            fileList: {
                title: this.props.t('pr.edit.filesToSave'),
                files: this.state.newNoTargetedIrm?.map((f) => f.name),
            },
        });
    };
    __onPressCancel = () => {
        // this.props.history.goBack()
        this.props.history.push(PatientRecordsRouter.getRoute());
    };
    __onPressCreate = () => {
        const patientRecord = this.state.patientRecord;
        const zip = new JSZip();
        const files = this.state.newNoTargetedIrm;
        const errors: { [key: string]: string } = {};

        //Check fields
        if (!patientRecord.firstname || patientRecord.firstname.length <= 0)
            errors.firstname = this.props.t('generic.msg.checkFirstname');
        if (!patientRecord.lastname || patientRecord.lastname.length <= 0)
            errors.lastname = this.props.t('generic.msg.checkLastname');

        if (!patientRecord.target || patientRecord.target.trim().length === 0)
            errors.target = this.props.t('pr.edit.checkTarget');
        if (!patientRecord.side || patientRecord.side.trim().length === 0)
            errors.side = this.props.t('pr.edit.checkSide');
        if (
            !patientRecord.typeOfSurgery ||
            patientRecord.typeOfSurgery.trim().length === 0
        )
            errors.typeOfSurgery = this.props.t('pr.edit.checkSurgery');
        if (
            !patientRecord.pathologie ||
            patientRecord.pathologie.trim().length === 0
        )
            errors.pathologie = this.props.t('pr.edit.checkPathology');

        if (Object.keys(errors).length > 0)
            return this.setState({ fieldErrors: errors });

        if (!files || files.length === 0)
            return this.setState({
                fieldErrors: undefined,
                error: {
                    title: this.props.t('generic.msg.error'),
                    message: `${this.props.t('pr.edit.checkMRI')} !`,
                },
            });

        if (
            files.map((a) => a.size).reduce((a, b) => a + b, 0) >
            Number(process.env.REACT_APP_MAX_UPLOAD_SIZE)
        )
            return this.setState({
                fieldErrors: undefined,
                error: {
                    title: this.props.t('generic.msg.error'),
                    message: `${this.props.t('pr.msg.sizeError')} !`,
                },
            });

        // Check file list contains one zip only
        this.setState({
            fieldErrors: undefined,
            information: {
                title: this.props.t('generic.msg.creating'),
                showProgress: true,
                message: `${this.props.t('pr.msg.creating')}...`,
            },
        });

        const push = async () => {
            const isZip =
                files.length === 1 &&
                files
                    .map((f) => path.extname(f.name) === '.zip')
                    .reduce((a, b) => a && b, true);
            let file;

            if (isZip) {
                file = files[0];
            } else {
                files.forEach((file) => {
                    zip.file(file.name, file);
                });
                file = await zip.generateAsync({ type: 'blob' });
            }

            await PatientRecordRequests.createPatientRecord(
                this.state.patientRecord,
                file
            );
        };

        push()
            .then(() => {
                this.setState({
                    information: {
                        title: `${this.props.t('generic.msg.success')} !`,
                        message: `${this.props.t('pr.edit.saved')}.`,
                        onClose: () =>
                            this.props.history.push(
                                PatientRecordsRouter.getRoute()
                            ),
                    },
                });
                setTimeout(
                    () =>
                        this.props.history.push(
                            PatientRecordsRouter.getRoute()
                        ),
                    1500
                );
            })
            .catch((err) => {
                this.setState({
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: err.message,
                    },
                });
            })
            .finally(() => {
                this.setState({ information: undefined });
            });
    };
}

// TODO? We have no idea why this was in the code, but we kept it even though it looks suspicious
// TODO The exact same code lies in `updatePatientRecordPage` it could be shared
function specialCaseTarget(
    target: PatientRecordTarget | undefined | null,
    pathologie: PatientRecordPathology | undefined | null
) {
    let specialTarget = target;
    if (
        pathologie === PatientRecordPathology.EssentialTremor &&
        target !== PatientRecordTarget.Vim
    ) {
        specialTarget = '' as PatientRecordTarget;
    }
    return specialTarget;
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(CreatePatientRecordPage)))
);
