import { PatientRecordStatus } from '__generated__/graphql';
import i18n from '../i18n';

export default class FormatToolbox {
    static patientRecordEnumToString(
        status: PatientRecordStatus
    ): string | undefined {
        switch (status) {
            case PatientRecordStatus.ToDo:
                return i18n.t('status.todoPreOp');
            case PatientRecordStatus.InProgress:
                return i18n.t('status.inProgress');
            case PatientRecordStatus.PreopMriMarked:
                return i18n.t('status.targetOK');
            case PatientRecordStatus.PostopToProcess:
                return i18n.t('status.todoPostOp');
            case PatientRecordStatus.Downloaded:
                return i18n.t('status.downloaded');
            case PatientRecordStatus.GiveUp:
                return i18n.t('status.rejected');
            case PatientRecordStatus.Done:
                return i18n.t('status.checkingOK');
            default:
                return i18n.t('status.unknown');
        }
    }
}
