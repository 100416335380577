import { Grid, Typography } from '@mui/material';
import { gql } from '__generated__/gql';
import { useTranslation } from 'react-i18next';
import { FragmentType, useFragment } from '__generated__';
import { IS_DEV } from 'config';

const TargetingStatusFragment = gql(`
    fragment TargetingStatusFragment on PatientRecordSchema {
        targetingStatus
    }
`);

export type TargetingStatusProps = {
    record: FragmentType<typeof TargetingStatusFragment> | undefined;
};

export default function TargetingStatus(props: TargetingStatusProps) {
    const record = useFragment(TargetingStatusFragment, props.record);
    const { t } = useTranslation();
    return (
        <>
            {IS_DEV && <DebugCode record={props.record} />}
            <Grid item xs={12} container spacing={2}>
                <Grid item sm={5}>
                    <Typography variant="h6">
                        {t('automaton.status.title')}:
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        {t([
                            `automaton.status.status.${record?.targetingStatus}`,
                            'automaton.status.status.DEFAULT',
                        ])}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

function DebugCode(props: TargetingStatusProps) {
    const record = useFragment(TargetingStatusFragment, props.record);
    const { t } = useTranslation();
    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item sm={5}>
                <Typography variant="h6">
                    [DEBUG] {t('automaton.status.code')}:
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">
                    <Typography display="inline">
                        {record?.targetingStatus}
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    );
}
