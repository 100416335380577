import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Select,
    TextField,
    OutlinedInput,
    MenuItem,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GraphQLError } from 'graphql';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { HospitalCenterRequests, UserRequests } from '../../../apollo';
import CreateSomethingWrapper from '../../../components/createSomethingWrapper';
import ErrorDialog, {
    IErrorInformation,
} from '../../../components/errorDialog';
import InformativeDialog, {
    IInformative,
} from '../../../components/informativeDialog';
import ConfirmationDialog, {
    ConfirmationInformation,
} from '../../../components/confirmationDialog';
import { UsersRouter } from '../../../router/dashboard/users/users.router';
import { isValidRole } from 'types/check';
import {
    CreateUserMutation,
    HospitalCenterSchema,
    Roles,
    UserCreateSchema,
} from '__generated__/graphql';
import { getEnumKeys } from 'types/helpers';

const DEFAULT_FORM_ROLE = Roles.Clinician;

const styles = () =>
    createStyles({
        title: {
            fontWeight: 'bolder',
        },
        gradient: {},
    });

interface IState {
    user: UserCreateSchema;
    fieldErrors: { [key: string]: string };

    error?: IErrorInformation;
    information?: IInformative;
    confirmation?: ConfirmationInformation;

    hospitals: Array<HospitalCenterSchema>;
}

interface IProps
    extends WithStyles<typeof styles>,
        RouteComponentProps,
        WithTranslation,
        WithApolloClient<Record<string, unknown>> {}

class CreateUserPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            // errorMsg: undefined,
            // progress: false,
            fieldErrors: {},
            user: {
                email: '',
                password: '',
                role: DEFAULT_FORM_ROLE,
            },
            hospitals: [],
        };
    }

    componentDidMount = () => {
        this.__loadHospitals();
    };

    render = () => {
        return (
            <CreateSomethingWrapper
                title={this.props.t('user.label.create')}
                onPressCancel={this.__onPressCancel}
                onPressCreate={this.__onPressCreate}
            >
                {this.__renderForm()}
                <ErrorDialog
                    error={this.state.error}
                    onClose={() => this.setState({ error: undefined })}
                />
                <InformativeDialog
                    information={this.state.information}
                    onClose={() => undefined}
                />
                <ConfirmationDialog
                    confirmation={this.state.confirmation}
                    onClose={() => undefined}
                />
            </CreateSomethingWrapper>
        );
    };

    __renderForm = () => {
        return (
            <form>
                <Grid
                    item
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={
                                this.state.fieldErrors.firstname !== undefined
                            }
                            helperText={this.state.fieldErrors.firstname}
                            id="firstname"
                            label={this.props.t('generic.label.firstname')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            variant="outlined"
                            value={this.state.user.firstname}
                            onChange={(e) =>
                                this.__onFirstNameChange(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={
                                this.state.fieldErrors.lastname !== undefined
                            }
                            helperText={this.state.fieldErrors.lastname}
                            id="lastname"
                            label={this.props.t('generic.label.lastname')}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.user.lastname}
                            onChange={(e) =>
                                this.__onLastNameChange(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            error={this.state.fieldErrors.email !== undefined}
                            helperText={this.state.fieldErrors.email}
                            id="email"
                            label={this.props.t('user.label.email')}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.user.email}
                            onChange={(e) =>
                                this.__onEmailChange(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={1} sm={6} />
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={
                                this.state.fieldErrors.password !== undefined
                            }
                            helperText={this.state.fieldErrors.password}
                            id="password"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={this.props.t('user.label.password')}
                            variant="outlined"
                            value={this.state.user.password}
                            onChange={(e) =>
                                this.__onPasswordChange(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={1} sm={6} />
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            error={
                                this.state.fieldErrors.idHospitalCenter !==
                                undefined
                            }
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="hospital-native-simple"
                            >
                                {this.props.t('user.label.hospital')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.user.idHospitalCenter ?? ''}
                                onChange={(e) =>
                                    this.__onIdHospitalCenterChange(
                                        Number(e.target.value)
                                    )
                                }
                                inputProps={{ id: 'hospital-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t(
                                            'user.label.hospital'
                                        )}
                                    />
                                }
                            >
                                {this.state.hospitals.map((hospital) => (
                                    <MenuItem
                                        value={hospital._id}
                                        key={hospital._id}
                                    >
                                        {hospital.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors.idHospitalCenter}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={6} />
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            variant="outlined"
                            error={this.state.fieldErrors.role !== undefined}
                        >
                            <InputLabel
                                shrink={true}
                                htmlFor="role-native-simple"
                            >
                                {this.props.t('user.label.role')}
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={this.state.user.role ?? ''}
                                onChange={(e) =>
                                    this.__onRoleChange(e.target.value)
                                }
                                inputProps={{ id: 'role-native-simple' }}
                                input={
                                    <OutlinedInput
                                        notched
                                        label={this.props.t('user.label.role')}
                                    />
                                }
                            >
                                {getEnumKeys(Roles).map((enumKey) => (
                                    <MenuItem
                                        key={enumKey}
                                        value={Roles[enumKey]}
                                    >
                                        {enumKey}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {this.state.fieldErrors.role}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={6} />
                </Grid>
            </form>
        );
    };

    __onFirstNameChange = (firstName: string) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    firstname: firstName,
                },
            });
        }
    };

    __onLastNameChange = (lastName: string) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    lastname: lastName,
                },
            });
        }
    };

    __onEmailChange = (email: string) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    email,
                },
            });
        }
    };

    __onPasswordChange = (password: string) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    password,
                },
            });
        }
    };

    __onIdHospitalCenterChange = (idHospitalCenter: number) => {
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    idHospitalCenter,
                },
            });
        }
    };

    __onRoleChange = (role: string) => {
        if (!isValidRole(role)) {
            throw TypeError(
                `No such role ${role} valid roles are ${Object.keys(Roles)}`
            );
        }
        if (this.state.user) {
            this.setState({
                user: {
                    ...this.state.user,
                    role,
                },
            });
        }
    };

    __onPressCancel = () => {
        this.__goBackToUserList();
    };
    __onPressCreate = () => {
        const user = this.state.user;
        const errors: { [key: string]: string } = {};
        /** CHECK SOME FIELDS */
        if (user.email.length <= 0)
            errors.email = this.props.t('user.msg.checkMail');
        const pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(user.email))
            errors.email = this.props.t('user.msg.checkMail');
        if (user.password.length <= 0)
            errors.password = this.props.t('user.msg.checkPassword');
        if (user.role.length <= 0)
            errors.role = this.props.t('user.msg.checkRole');
        if (!user.firstname || user.firstname.length <= 0)
            errors.firstname = this.props.t('generic.msg.checkFirstname');
        if (!user.lastname || user.lastname.length <= 0)
            errors.lastname = this.props.t('generic.msg.checkLastname');
        // if (user.idHospitalCenter === undefined || user.idHospitalCenter === null)
        // errors.idHospitalCenter = this.props.t('user.msg.checkHospital')
        if (
            ![Roles.Admin, Roles.Operator].includes(user.role as Roles) &&
            !user.idHospitalCenter
        )
            errors.idHospitalCenter = this.props.t('user.msg.checkHospital');

        if (Object.keys(errors).length > 0)
            return this.setState({ fieldErrors: errors });

        this.setState({
            fieldErrors: {},
            information: {
                title: this.props.t('generic.msg.creating'),
                showProgress: true,
                message: `${this.props.t('generic.msg.updating')}...`,
            },
        });

        UserRequests.createUser(user)
            .then((user) => {
                this.__showPopupAskingSendMailAndClose(user);
            })
            .catch((error: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: error.message,
                    },
                });
            });
    };

    /**
     * Method used to show a confirmation popup to send mail to "reset password". And close the page
     */
    __showPopupAskingSendMailAndClose = (
        user: CreateUserMutation['createUser']
    ) => {
        this.setState({
            information: undefined,
            confirmation: {
                title: `${this.props.t('generic.msg.success')} !`,
                message: `${this.props.t(
                    'user.msg.userCreated'
                )}. ${this.props.t('user.msg.sendPwdResetEmail')}`,
                onClickAccept: () => {
                    this.setState({
                        information: {
                            title: this.props.t('generic.msg.loading'),
                            showProgress: true,
                        },
                    });

                    if (!user) {
                        return;
                    }

                    UserRequests.forgottenPassword(user.email)
                        .then(() => {
                            this.setState({
                                information: {
                                    showProgress: false,
                                    message: this.props.t(
                                        'user.msg.pwdResetEmailSent'
                                    ),
                                    onClose: this.__goBackToUserList,
                                },
                            });
                        })
                        .catch((err: GraphQLError) => {
                            this.setState({
                                information: undefined,
                                error: {
                                    title: this.props.t('generic.msg.error'),
                                    message: err.message,
                                    onClose: this.__goBackToUserList,
                                },
                            });
                        });
                },
                onClickCancel: this.__goBackToUserList,
            },
        });
    };

    __loadHospitals = () => {
        HospitalCenterRequests.hospitalCenters()
            .then((hospitals) => this.setState({ hospitals }))
            .catch((error: GraphQLError) => {
                this.setState({
                    information: undefined,
                    error: {
                        title: this.props.t('generic.msg.error'),
                        message: error.message,
                    },
                });
            });
    };

    __goBackToUserList = () => {
        this.props.history.push(UsersRouter.getRoute());
    };
}

export default withTranslation()(
    withStyles(styles)(withApollo(withRouter(CreateUserPage)))
);
