import { Components, Theme } from '@mui/material/styles';
import {
    PaletteGreen,
    PaletteGreenDark,
    PaletteGreenLight,
    PaletteGreenLightLight,
    PalettePrimary,
    PalettePrimaryDark,
    PalettePrimaryLightLight,
    TestColor,
} from './global.theme';

// project imports

export const componentStyleOverrides = ({ palette }: Theme): Components => {
    return {
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: PaletteGreenDark,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {},
            },
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: 5,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                containedPrimary: {
                    backgroundColor: PaletteGreen,
                    ':hover': {
                        backgroundColor: PaletteGreenDark,
                    },
                },
                containedSecondary: {
                    backgroundColor: PaletteGreen,
                    ':hover': {
                        backgroundColor: PaletteGreenDark,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: PaletteGreenLightLight,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: PaletteGreenLightLight,
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {},
        },
        MuiAlert: {
            styleOverrides: {
                filledInfo: {
                    backgroundColor: PalettePrimary,
                },
                standardInfo: {
                    backgroundColor: '#F8F2DC',
                    color: '#000000',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root:not(:last-child)': {
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                    },
                },
                head: {
                    '& .MuiTableCell-root': {
                        backgroundColor: palette.grey?.[100],
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: PaletteGreenLight,
                },
            },
        },
    };
};
