import {
    Box,
    Button,
    Dialog,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = () =>
    createStyles({
        underline: {
            textDecorationLine: 'underline',
        },
    });

export interface ConfirmationInformation {
    title?: string /** Title of the modal */;
    message?: string /** Message of the modal */;

    askReason?: boolean /** Ask reason */;
    placeholderReason?: string | undefined | null;

    onClickAccept: (
        reason?: string | undefined
    ) => void /** Function called when user confirm */;
    onClickCancel: () => void /** Function called when user cancel */;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
    confirmation?: ConfirmationInformation;

    onClose?: () => void /** Function called when user whant close the dialog */;
}

interface IState {
    reason?: string | undefined;
}

/**
 * Component used to render informative data
 */
class ConfirmationDialog extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: IProps, _: IState) {
        if (
            !prevProps.confirmation &&
            this.props.confirmation &&
            this.props.confirmation.placeholderReason
        ) {
            this.setState({
                reason: this.props.confirmation.placeholderReason,
            });
        }
    }
    render = () => {
        return (
            <Dialog
                open={this.props.confirmation !== undefined}
                onClose={() => this.props.onClose && this.props.onClose()}
            >
                <Box margin={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="h5">
                                {this.props.confirmation?.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ my: 3 }}>
                            <Typography align="center">
                                {this.props.confirmation?.message}
                            </Typography>
                        </Grid>
                        {this.props.confirmation?.askReason === true && (
                            <Grid item xs={12}>
                                <TextField
                                    placeholder={
                                        this.props.confirmation
                                            ?.placeholderReason
                                            ? this.props.confirmation
                                                  ?.placeholderReason
                                            : undefined
                                    }
                                    multiline
                                    rows={3}
                                    label={this.props.t('pr.reject.reason')}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={this.state.reason}
                                    onChange={(e) =>
                                        this.setState({
                                            reason: e.target.value,
                                        })
                                    }
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="space-around"
                        >
                            <Button
                                onClick={() =>
                                    this.props.confirmation?.onClickAccept(
                                        this.state.reason
                                    )
                                }
                                variant="contained"
                                color="secondary"
                            >
                                <Typography>
                                    {this.props.t('generic.button.continue')}
                                </Typography>
                            </Button>
                            <Button
                                onClick={this.props.confirmation?.onClickCancel}
                                variant="contained"
                                color="error"
                            >
                                <Typography>
                                    {this.props.t('generic.button.cancel')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        );
    };
}

export default withTranslation()(withStyles(styles)(ConfirmationDialog));
