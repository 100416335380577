/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */
import { PaletteOptions } from '@mui/material';
import {
    PaletteErrorMain,
    PaletteGreen,
    PaletteGreenDark,
    PaletteGreenLight,
    PalettePrimary,
    PalettePrimaryLight,
} from './global.theme';

export const themePalette = (): PaletteOptions => {
    return {
        primary: {
            main: PalettePrimary,
            light: PalettePrimaryLight,
            contrastText: '#ffffff',
        },
        secondary: {
            main: PaletteGreen,
            light: PaletteGreenLight,
            dark: PaletteGreenDark,
            contrastText: '#ffffff',
        },
        background: {
            // default: PalettePrimaryLight
        },
        error: {
            main: PaletteErrorMain,
        },
    };
};
